import { useEffect, useRef } from "react";

function ConfirmZone({children, className}) {
    const wrapper = useRef(null);
    
    useEffect(() => {
        setTimeout(() => {
        wrapper.current.classList.add('fade-in');
    }, 200);
    }, [])
    return (
        <div ref={wrapper} className={`fade ${className ? className : ''}`}>
            {children}
        </div>
    )
}
export default ConfirmZone;