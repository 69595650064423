import moment from "moment";
import { Line, ResponsiveContainer, LineChart } from "recharts";
import { useEffect, useState } from "react";
import baseClient from "../../api/Base.js";

function ProfileViews({ dateFilter, dateFilterText, creatorId }) {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortAsc, setSortAsc] = useState(false);
  const [masterData, setMasterData] = useState();
  const [fadeIn, setFadeIn] = useState("");

  useEffect(() => {
    try {
      const getProfileViews = async () => {
        const model = {
          sort: sortBy,
          sort_asc: sortAsc || false,
          creatorId: creatorId,
        };
        const results = await baseClient.APIPost({
          model: model,
          api: "profileviews",
        });
        let resultsCopy = results?.data;
        setMasterData(results);
        setData(results.data);
        if (resultsCopy) {
          results.data
            .map((item) => item.count)
            .reduce((prev, next) => prev + next);
        }
        setFadeIn("fade-in");
      };
      getProfileViews();
    } catch (error) {
      console.error(error.message);
    }
  }, [sortAsc, sortBy, creatorId]);

  useEffect(() => {
    // load chart data
    if (masterData && !chartData) {
      const tempChartData = [];
      masterData.data.forEach((item) => {
        tempChartData.push({
          time: item._id,
          views: item.count,
        });
      });
      setChartData(tempChartData);
    }
  }, [masterData, chartData]);

  return (
    <div className={`fade ${fadeIn}`}>
      <div className="p-right-1 p-left-1 ">
        <div style={{ width: "100%", height: "100px" }}>
          {data ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={"100%"}
                height={100}
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 5,
                }}
              >
                <Line
                  type="monotone"
                  dot={false}
                  dataKey="views"
                  stroke="#00753d"
                  strokeWidth={2}
                  animationEasing="ease-out"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : null}
        </div>
      </div>
      {/*       <div className={`align-left p-left-05 small-font`}>{`${count} ${
        count === 1 ? "view" : "views"
      } ${dateFilterText}`}</div> */}
      <div className={`views-scroller`}>
        <table className={`content-views`}>
          <thead>
            <tr>
              <th className={`align-center no-wrap`}>
                <button
                  type="button"
                  className="anchor-button small-font wide"
                  onClick={() => {
                    setSortBy("createdAt");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Day
                  {sortBy === "createdAt" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className="anchor-button small-font wide align-right"
                  onClick={() => {
                    setSortBy("count");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Profile Views
                  {sortBy === "count" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, key) => {
              return (
                <tr key={`v${key}`}>
                  <td className={`align-center no-wrap`}>
                    {moment(item._id).format("MMM D YYYY")}
                  </td>
                  <td className={`align-right`}>{item.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ProfileViews;
