import { useEffect, useState } from "react";
import "../../Css/Utils/Slideover.css";

function Slideover({ children, title, fnClose, scrollSnap = false, className }) {
  const [showslideover, setShowslideover] = useState();

  useEffect(() => {
    setShowslideover(true);
  }, []);

  return (
    <div className={`slideover ${className ? className : ''} ${showslideover ? "visible" : ""}`}>
       <div className="slideover-header">
        <button
          onClick={() => {
            setShowslideover(null);
            setTimeout(() => {
              fnClose();
            }, 100);
          }}
          className="anchor-button back-button"
        >
          <i className="fa-light fa-arrow-left" />
        </button>

        <span className="slideover-header-label">{title}</span>
      </div>
      
      <div className={`slideover-content ${scrollSnap ? 'scrollSnap' : ''}`}>{children}</div>
    </div>
  );
}
export default Slideover;
