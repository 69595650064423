import { useEffect, useRef, useState } from "react";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";

function SportForm({ id, fnClose }) {
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [sport, setSport] = useState();
  const [fadeIn, setFadeIn] = useState("");
  const sportForm = useRef(null);
  const [sportId, setSportId] = useState(id && id !== "new" ? id : null);

  const upsertSport = async () => {
    try {
      setLoading(true);
      let model;
      model = await toolsClient.getFormModel(
        sportForm.current,
        model,
        setLoading
      );
      if (!model) {
        setLoading(false);
        return;
      }
      model.name = toolsClient.initialCaps(model.name);
      model.sportId = sportId;
      const result = await baseClient.APIPost({
        model: model,
        api: "upsertsport",
      });    

      if (!result || result.code !== 200) {
        setLoading(false);
        setErrorMsg({
          message: `Error saving sport. ${result?.message} Code: ${result?.code}`,
          title: "Oops",
        });
        return;
      }
      setSportId(result.data._id);
      const sportData = result.data[0];
      setSport(sportData);
      setTimeout(() => {
        setLoading(false);
        fnClose();
      }, toolsClient.LOADING_DELAY);
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong saving sport.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    const getSport = async () => {
      try {
        setLoading(true);
        const model = {
          sportId: id,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "sports",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting sport. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        const sportData = result.data[0];
        setSport(sportData);
        setTimeout(() => {
          setLoading(false);
          setFadeIn("fade-in");
        }, toolsClient.LOADING_DELAY);
      } catch (err) {
        setErrorMsg({
          message: `Something went wrong getting sports list. ${err.mesage}`,
          title: "Oops",
        });
        console.error(err.message);
      }
    };
    if (id && id !== "new") {
      getSport();
    } else {
      setFadeIn("fade-in");
    }
  }, [id]);

  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`p-1 fade ${fadeIn}`}>
        <div className={`align-center`}>
          <h2>Sport Form</h2>
        </div>
        <form ref={sportForm} className={`m-top-1`}>
          <label htmlFor="name">
            <div>Sport Name</div>
            <div>
              <input
                id="name"
                name="name"
                type="text"
                defaultValue={sport?.sport}
                required
                placeholder="sport Name"
              />
            </div>
          </label>
        </form>
        <div className={`m-top-1 p-1 align-center`}>
          <button
            type="button"
            className={`button-cancel m-025`}
            onClick={() => {
              fnClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`button-save m-025`}
            onClick={() => {
              upsertSport();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default SportForm;
