import { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "../../Css/Utils/Bottompop.css";
import logo from "../../Graphics/AxcessIconNew.png";

function BottomPop({
  children,
  title,
  fnClose,
  iconClass,
  headerClass = "",
  closeIcon,
  fnLoaded,
  height,
}) {
  const [showPopover, setShowPopover] = useState();
  const [showSheen, setShowSheen] = useState();

  const wrapper = useRef(null);

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      if (eventData.dir === "Down") {
        const el = wrapper.current;
        el.style.bottom = `${eventData.absY / -1}px`;
      }
    },
    onSwiped: (eventData) => {
      if (eventData.absY > 30) {
        const el = wrapper.current;
        el.classList.add("hide");
        setTimeout(() => {
          setShowPopover(false);
          setShowSheen(false);
          fnClose();
        }, 300);
      }
    },

    // ...config,
  });

  useEffect(() => {
    const showAll = () => {
      setShowPopover(true);

      setTimeout(() => {
        setShowSheen(true);
      }, 10);
    };
    if (fnLoaded) {
      showAll();
    }
  }, [fnLoaded]);

  return (
    <div className={`bottom-pop-wrapper`}>
      <div
        className={`hint-sheen ${showSheen ? "visible" : ""}`}
        role="button"
        onClick={() => {
          setShowPopover(false);
          setShowSheen(false);
          setTimeout(() => {
            fnClose();
          }, 100);
        }}
      ></div>
      <div
        ref={wrapper}
        className={`bottom-pop ${showPopover ? "visible" : ""}`}
        style={{ height: `${height ? height : "unset"}` }}
      >
        <div {...handlers} className={`bottom-pop-header ${headerClass}`}>
          {iconClass ? (
            <div className="p-right-1 large-font">
              <i className={iconClass} />
            </div>
          ) : (
            <div className={`p-right-025`}>
              <img src={logo} alt="" />
            </div>
          )}

          <div className="flex-1 align-left">{title}</div>
          {fnClose ? (
            <div className={`close-button`}>
              <button
                onClick={() => {
                  setShowPopover(false);
                  setShowSheen(false);
                  setTimeout(() => {
                    fnClose();
                  }, 100);
                }}
                className="anchor-button large-font"
              >
                <i
                  className={`${
                    closeIcon ? closeIcon : "fas fa-times close-icon"
                  } `}
                />
              </button>
            </div>
          ) : null}
        </div>
        <div className={`bottom-pop-content`}>{children}</div>
      </div>
    </div>
  );
}
export default BottomPop;
