import { useCallback, useEffect, useRef, useState } from "react";
import "../../Css/Feed/FeedSlides.css";
import { useSwipeable } from "react-swipeable";
import baseClient from "../../api/Base";
import moment from "moment";
import toolsClient from "../../Javascript/Tools";
import loadingGif from "../../Graphics/loadingVid.gif";
function FeedSlides({
  myFeed,
  globalMute,
  setGlobalMute,
  toggleAthlete,
  showingLikes = false,
  filterId,
  setFilterId,
  toggleDescription,
  userId,
  setShowEditForm,
  deleteItemRow,
  toggleReportContent,
  setPageNow,
  togglePublishMessage,
}) {
  /* const [vidLoading, setVidLoading] = useState();
  const [imageLoading, setImageLoading] = useState(); */
  const [showPause, setShowPause] = useState();
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [swipePause, setSwipePause] = useState();
  const showLen = 3; // nbr of slides to load ahead of first
  const swipeRange = 60; // if swipe less than 60px bounce back on stopsliding

  const viewedImageTimer = useRef();
  const viewedVideoTimer = useRef();
  const videoViewTestInterval = useRef();

  const registerContentView = async (item) => {
    try {
      const model = {
        post_id: item._id,
        author_id: item.author._id,
        type: item.type,
      };
      await baseClient.APIPost({
        model: model,
        api: "contentview",
      });

      console.log(`content viewed: ${item._id}`);
    } catch (error) {
      console.error(error.message);
    }
  };

  const toggleFavorite = async (itemId) => {
    try {
      let tempLiked;
      const item = toolsClient.searchArray(itemId, "_id", myFeed, true);

      if (item.liked) {
        tempLiked = false;
      } else {
        tempLiked = true;
      }
      const model = {
        id: itemId,
        liked: tempLiked.toString(),
      };
      await baseClient.APIPost({
        model: model,
        api: "likepost",
      });
      for (let i = 0; i < myFeed.length; i++) {
        if (myFeed[i]._id === itemId) {
          myFeed[i].liked = tempLiked;
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const toggleMuted = (muteOnly = false, itemId) => {
    const mute = muteOnly ? true : !globalMute;
    setGlobalMute(mute);
    const myElement = document.getElementById(`video_${itemId}`);
    if (myElement) {
      myElement.muted = mute;
    }
  };

  const wrapper = useRef(null);
  const nextElBottom = useRef(null);
  const swipingVideo = useRef(null);

  const handleVswipe = (eventData, dir) => {
    try {
      const getTargetNode = (elem, dir = "up") => {
        // works with showLen to determine target node to add or remove src
        let nextElem = elem;
        let testIndex = showLen - 1;
        for (let i = 0; i < testIndex; i++) {
          nextElem =
            dir === "up" ? nextElem?.nextSibling : nextElem?.previousSibling;
        }
        return nextElem;
      };

      const swipingElIndex = eventData.event.target.getAttribute("data-id");
      const swipedEl = document.getElementById(swipingElIndex);
      const nextEl =
        dir === "up" ? swipedEl.nextSibling : swipedEl.previousSibling;
      swipedEl.classList.add("ease-in");
      nextEl?.classList.add("ease-in");

      if (eventData.absY > swipeRange && nextEl && !swipePause) {
        // we swiped far enough (swipeRange) to do things...
        let topElement;
        const bottomElement =
          dir === "up"
            ? getTargetNode(nextEl)?.firstChild
            : getTargetNode(swipedEl, "up")?.firstChild;
        let counter;

        if (swipingVideo.current) {
          swipingVideo.current.pause();
          swipingVideo.current = null;
        }

        if (dir === "up") {
          // swipeUp
          topElement = swipedEl.previousSibling?.firstChild;
          if (bottomElement) {
            counter = parseInt(bottomElement.getAttribute("data-id"));
          } else {
            counter = myFeed.length;
          }

          if (
            bottomElement &&
            (!bottomElement.src || bottomElement.src.length === 0)
          ) {
            bottomElement.src = myFeed[counter].contentUrl;
          }

          if (
            topElement &&
            counter < myFeed.length &&
            counter !== showLen + 1
          ) {
            topElement.removeAttribute("src");
          }
          // pageFeed (only on swipeUp)
          if (counter === myFeed.length - showLen && myFeed.length > showLen) {
            setPageNow(true);
          }
        } else {
          // swipeDown
          topElement = getTargetNode(swipedEl, "down")?.firstChild;
          counter = parseInt(topElement?.getAttribute("data-id"));

          if (topElement && !topElement.getAttribute("src")) {
            topElement.src = myFeed[counter].contentUrl;
          }

          if (bottomElement && counter < myFeed.length) {
            bottomElement.removeAttribute("src");
          }
        }

        swipedEl.style.bottom = dir === "up" ? "100vh" : "calc(-100vh)";
        nextEl.style.bottom = "0px";

        pauseAllVideos();
      } else {
        if (swipedEl) swipedEl.style.bottom = "0px";
        if (nextEl) nextEl.style.bottom = "-100vh";
      }
      setTimeout(() => {
        if (nextEl) nextEl.classList.remove("ease-in");
        swipedEl.classList.remove("ease-in");
      }, 200);
      nextElBottom.current = null;
    } catch (err) {
      console.log(err.message);
    }
  };

  const swElIndex = useRef(null);
  const swMaster = useRef(null);
  const tIndex = useRef(null);
  const isSwipeable = useRef(null);

  const handlers = useSwipeable({
    preventScrollOnSwipe: true,
    trackTouch: true,
    onSwipeStart: (eventData) => {
      let startY = eventData.event.changedTouches[0].clientY;
      if (window.innerHeight - startY > 60) {        
        // we're at least 60px from bottom of screen
        isSwipeable.current = true;
        swElIndex.current = eventData.event.target.getAttribute("data-id");
        swMaster.current = document.getElementById(swElIndex.current);
        tIndex.current = parseInt(swMaster.current.getAttribute("id")) || 0;
        const video = document.getElementById(swElIndex.current).firstChild;
        if (video && video instanceof HTMLVideoElement) {
          video.pause();
          swipingVideo.current = video;
        }
      } else {
        isSwipeable.current = null;
      }
    },
    onSwiping: (eventData) => {
      if (isSwipeable.current) {
        if (eventData.dir === "Up" && tIndex.current !== myFeed.length - 1) {
          const nextEl = swMaster.current.nextSibling;

          if (!nextElBottom.current) {
            nextElBottom.current = window.innerHeight;
          }
          swMaster.current.style.bottom = `${eventData.absY}px`;
          if (nextEl)
            nextEl.style.bottom = `${
              (nextElBottom.current - eventData.absY) / -1
            }px`;
        }
        if (eventData.dir === "Down" && tIndex.current !== 0) {
          const nextEl = swMaster.current.previousSibling;

          if (!nextElBottom.current) {
            nextElBottom.current = window.innerHeight;
          }
          swMaster.current.style.bottom = `${eventData.absY / -1}px`;
          nextEl.style.bottom = `${nextElBottom.current - eventData.absY}px`;
        }
      }
    },
    onSwiped(eventData) {
      if (isSwipeable.current) {
        setSwipePause(true);
        setTimeout(() => {
          setSwipePause(null);
        }, 500);
        //setImageLoading(null);
        clearTimeout(viewedImageTimer.current);
        clearTimeout(viewedVideoTimer.current);
        window.clearInterval(videoViewTestInterval.current);
        //const swipingElIndex = swElIndex.current; //eventData.event.target.getAttribute("data-id");
        const swipedEl = document.getElementById(tIndex.current);

        let nextEl;
        if (eventData.dir === "Up") {
          nextEl = swipedEl.nextSibling;
        }
        if (eventData.dir === "Down") {
          nextEl = swipedEl.previousSibling;
        }
        if (eventData.absY > swipeRange) {
          setShowPause(null);

          //const swipedEl = eventData.event.target.parentNode;

          if (swipedEl) {
            // handle stoping and playing videos
            if (nextEl && nextEl.firstChild instanceof HTMLVideoElement) {
              setTimeout(() => {
                nextEl.firstChild.play();
                nextEl.firstChild.muted = globalMute;                
              }, 200);
            }
          }
        } else {
          if (swipingVideo.current) {
            // handle stoping and playing videos
            if (!showPause) {
              console.log(`playing swipingVideo: ${swipingVideo.current.id}`);
              swipingVideo.current.play();
              swipingVideo.current.muted = globalMute;
            }
            swipingVideo.current = null;
          }
        }

        // handle register content view and vid spinner
        const nextElIndex = nextEl?.getAttribute("data-id");
        const swipingMaster = document.getElementById(nextElIndex);
        const testIndex = parseInt(swipingMaster?.getAttribute("id")) || 0;
        const feedItem = myFeed[testIndex];
        testViewed(nextEl, feedItem);
        handleVswipe(eventData, eventData.dir.toLocaleLowerCase());
      }
    },
  });

  const pauseAllVideos = () => {
    const videos =
      Array.from(document.getElementsByClassName("feed-video")) || [];
    videos.forEach((vid) => {
      if (vid instanceof HTMLVideoElement) {
        vid.pause();
      }
    });
  };

  const testViewed = useCallback((el, feedItem) => {
    clearTimeout(viewedImageTimer.current);
    clearTimeout(viewedVideoTimer.current);
    window.clearInterval(videoViewTestInterval.current);
    if (el) {
      if (feedItem.type === "video") {
        videoViewTestInterval.current = window.setInterval(() => {
          if (toolsClient.isVideoPlaying(el.firstChild)) {
            window.clearInterval(videoViewTestInterval.current);
            //setVidLoading(null);
            viewedVideoTimer.current = setTimeout(() => {
              registerContentView(feedItem);
            }, 3000);
          }
        }, 1000);
      } else {
        viewedImageTimer.current = setTimeout(() => {
          registerContentView(feedItem);
        }, 3000);
      }
    }
  }, []);

  useEffect(() => {
    if (isFirstSlide && myFeed && myFeed.length > 0) {
      setIsFirstSlide(null);
      const el = document.getElementById("0");
      const feedItem = myFeed[0];
      testViewed(el, feedItem);
    }
  }, [isFirstSlide, myFeed, testViewed]);

  return (
    <ul
      ref={wrapper}
      {...handlers}
      className={`feed-slides relative`}
      style={{}}
      id="wrapper"
    >
      {myFeed?.map((item, index) => {
        return (
          <li
            key={`feedItem_${index}`}
            data-id={index}
            data-guid={item._id}
            id={index}
            style={{ backgroundImage: `url(${loadingGif})` }}
            className={`relative ${index === 0 ? "viewport" : ""} ${
              item.approved ? "" : "not-published"
            }`}
          >
            {item.type === "image" ? (
              <img
                data-id={index}
                className={`feed-image ${item.portrait ? "portrait" : ""}`}
                alt=""
                src={index < showLen ? item.contentUrl : null}
                onLoad={() => {
                  // setImageLoading(null);
                }}
              />
            ) : null}
            {item.type === "video" ? (
              <video
                id={`video_${item._id}`}
                data-id={index}
                className={`feed-video ${item.portrait ? "portrait" : ""}`}
                src={index < showLen ? item.contentUrl : null}
                controls={false}
                muted={globalMute}
                autoPlay={true}
                loop={true}
                playsInline={true}
                alt=""
                style={{}}
                //poster={loadingGif}
                onClick={(e) => {
                  const myElement = e.target;
                  if (!myElement.paused) {
                    myElement.pause();
                    setShowPause(true);
                  } else {
                    myElement.play();
                    setShowPause(false);
                  }
                }}
                onError={(e) => {
                  baseClient.serverLogger(`video error: ${e}`);
                }}
                /* onPlaying={() => {
                  console.log(`playing ${item._id}`)
                }} */
               /*  onLoadStart={() => {
                  //setVidLoading(item._id);
                }} */
                onCanPlay={(e) => {
                  //setVidLoading(null);
                  if (index !== 0) {
                    e.target.pause();
                  } else {
                    e.target.play();
                  }
                }}
              />
            ) : null}
            {item.author.publish_status !== "published" ? (
              <button
                type="button"
                data-id={index}
                className={`anchor-button publish-lock`}
                onClick={(e) => {
                  e.stopPropagation();
                  togglePublishMessage(true);
                }}
              >
                <span data-id={index} className={`small-font`}>
                  <i
                    data-id={index}
                    className={`fa-solid fa-lock larger-font m-right-05`}
                  />
                  {item.author.publish_status === "none" ? 'Not Published' : 'Publish Pending'}
                </span>
              </button>
            ) : null}
            {/*  {vidLoading === item._id ? (
              <>
                <i
                  data-id={index}
                  className={`fa fa-loader fa-spin item-loading`}
                />
              </>
            ) : null}
            {imageLoading ? (
              <>
                <i
                  data-id={index}
                  className={`fa fa-loader fa-spin item-loading`}
                />
              </>
            ) : null} */}
            {item.type === "video" ? (
              <button
                data-id={index}
                type="button"
                className={`anchor-button video-pause fa fa-pause larger-font text-shadow ${
                  !showPause ? "hidden" : ""
                }`}
                onClick={(e) => {
                  setShowPause(null);
                  const el = e.target.parentNode.firstChild;
                  el.play();
                }}
              ></button>
            ) : null}
            {/* FOOTER */}
            <div
              role="button"
              data-id={index}
              className="feed-footer"
              onClick={(e) => {
                const myElement = e.parentNode?.firstChild;
                if (myElement instanceof HTMLVideoElement) {
                  if (!myElement.paused) {
                    myElement.pause();
                    setShowPause(true);
                  } else {
                    myElement.play();
                    setShowPause(false);
                  }
                }
              }}
            >
              <div data-id={index} className="feed-description">
                <div data-id={index} className={`creator-name`}>
                  <button
                    type="button"
                    data-id={index}
                    className={`anchor-button text-shadow`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMuted(true, item._id);
                      toggleAthlete(
                        item.author._id,
                        `${item.author.first_name} ${item.author.last_name}`
                      );
                    }}
                  >
                    {item.author.first_name} {item.author.last_name}
                  </button>
                  {!showingLikes ? (
                    <button
                      data-id={index}
                      type="button"
                      className={`anchor-button feed-filter ${
                        filterId && filterId !== "none" ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!filterId || filterId === "none") {
                          setFilterId(item.author._id);
                        } else {
                          setFilterId("none");
                        }
                      }}
                    >
                      <i
                        data-id={index}
                        className={`text-shadow  ${
                          filterId && filterId !== "none"
                            ? "fa-solid fa-filter-circle-xmark "
                            : "fa-regular fa-filter"
                        }`}
                      />
                    </button>
                  ) : null}
                </div>
                <div data-id={index}>{item.headline}</div>
                <div data-id={index} className="feed-date">
                  {moment(new Date(item.createdAt)).fromNow()}
                </div>
                <div data-id={index} className={`show-more`}>
                  {item.description ? (
                    <button
                      data-id={index}
                      type="button"
                      className="anchor-button small-font"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDescription(item);
                      }}
                    >
                      Show more...
                    </button>
                  ) : null}
                </div>
              </div>

              <div data-id={index} className={`feed-actions`}>
                {["video", "link_video"].includes(item.type) && (
                  <>
                    <div data-id={index} className="mx-1">
                      <button
                        data-id={index}
                        type="button"
                        className="anchor-button mute-toggle-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleMuted(false, item._id);
                        }}
                      >
                        <i
                          data-id={index}
                          className={`text-shadow m-bottom-1 fas fa-volume${
                            globalMute ? "-mute" : ""
                          }`}
                        ></i>
                      </button>
                    </div>
                  </>
                )}
                <div data-id={index}>
                  <button
                    data-id={index}
                    type="button"
                    className={`anchor-button`}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (item.liked) {
                        e.target.classList.remove("brand-pink");
                      } else {
                        e.target.classList.add("brand-pink");
                      }
                      toggleFavorite(item._id);
                    }}
                  >
                    <i
                      data-id={index}
                      className={`fa-solid fa-heart text-shadow ${
                        item.liked ? "brand-pink" : ""
                      }`}
                    />
                  </button>
                </div>
                <div data-id={index} className="m-top-1">
                  {item.author._id === userId ? (
                    <>
                      <button
                        data-id={index}
                        type="button"
                        className={`anchor-button block `}
                        onClick={(e) => {
                          e.stopPropagation();
                          //if (player) {
                          toggleMuted(true, item._id);
                          //}
                          setShowEditForm({
                            item,
                            showForm: true,
                          });
                        }}
                      >
                        <i
                          data-id={index}
                          className={`text-shadow fa-light fa-square-pen m-bottom-1`}
                        />
                      </button>
                      <button
                        data-id={index}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          //if (player) {
                          toggleMuted(true, item._id);
                          //}
                          deleteItemRow(item);
                        }}
                        className="anchor-button"
                      >
                        <i
                          data-id={index}
                          className={`text-shadow fa-regular fa-trash-can`}
                        />
                      </button>
                    </>
                  ) : (
                    <button
                      data-id={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        // if (player) {
                        toggleMuted(true, item._id);
                        //}
                        toggleReportContent(item);
                      }}
                      className="anchor-button"
                    >
                      <i
                        data-id={index}
                        className={`text-shadow fa-regular fa-circle-exclamation`}
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
export default FeedSlides;
