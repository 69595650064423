import moment from "moment/moment";
import { useEffect, useState } from "react";
import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";

import DialogPop from "../Utils/dialogpop";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";

function Subscriptions({ fnRefresh }) {
  const [athletes, setAthletes] = useState([]);
  const [showConfirm, setShowConfirm] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState();
  const [reload, setReload] = useState();

  const unsubscribe = async (id) => {
    try {
      const model = {
        id: id,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "unsubscribe",
      });
      if (response && response.code === 200) {
        setShowConfirm(null);
        setReload(true);
        fnRefresh();
      } else {
        setErrorMsg({
          message: "Something went wrong unsubscribing.",
          title: "Oops",
        });
      }
    } catch (err) {
      setErrorMsg({
        message: "Something went wrong unsubscribing.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    try {
      const getSubscriptions = async () => {
        try {
          setLoading(true);
          let model = [];
          const response = await baseClient.APIPost({
            model: model,
            api: "mysubscriptions",
          });
          if (response && response.data) {
            setAthletes(response.data);
            setReload(null);
            setTimeout(() => {
              setLoading(false);
              setFadeIn("fade-in");
              //setShowNotice(true);
            }, toolsClient.LOADING_DELAY);
          } else {
            setLoading(null);
            console.error("No data retured from server.");
          }
        } catch (err) {
          setErrorMsg({
            message: "Something went wrong getting subscriptions.",
            title: "Oops",
          });
          console.error(err.message);
        }
      };
      getSubscriptions();
    } catch (err) {
      setErrorMsg({
        message: "Something went wrong getting subscriptions.",
        title: "Oops",
      });
    }
  }, [reload]);

  return (
    <div className={`settings-block`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}

      <div className={`fade ${fadeIn}`}>
        {!athletes || athletes.length === 0 ? (
          <div className={`p-1 brand-gold`}>
            <div className={`p-1`}>
              <i className="fa-light fa-face-sad-tear larger-font" />
            </div>
            You have no current subscriptions.
          </div>
        ) : null}
        {showConfirm ? (
          <DialogPop
            title={`Change Subscription`}
            fnClose={() => {
              setShowConfirm(null);
            }}
            headerIcon={`question`}
          >
            <div>
              {showConfirm.creator.promotional ? (
                <div>
                  <div>
                    <div className={`large-font`}>UN-SUBSCRIBE</div>
                    Are you sure you want to unsubscribe from{" "}
                    {showConfirm.creator.first_name}{" "}
                    {showConfirm.creator.last_name}?
                  </div>
                  <div className={`m-1 p-top-1`}>
                    <button
                      type="button"
                      className={`button-cancel m-05`}
                      onClick={() => {
                        setShowConfirm(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`button-save m-05`}
                      onClick={() => {
                        unsubscribe(showConfirm._id);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={`large-font`}>UN-SUBSCRIBE</div>
                  <p>
                    You can not unsubscribe from paid subscriptions here. You
                    must unsubscribe from your app store subscriptions module.
                  </p>
                  <p>
                    <a
                      href={`https://support.apple.com/en-us/HT202039`}
                      target="_blank"
                      className={`brand-green`}
                      rel="noreferrer"
                    >
                      How to cancel a subscription from Apple.
                    </a>
                  </p>
                  <div className={`m-1 p-top-1`}>
                    <button
                      type="button"
                      className={`button-cancel m-05`}
                      onClick={() => {
                        setShowConfirm(null);
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              )}
            </div>
          </DialogPop>
        ) : null}
        <ul className={`block`}>
          {athletes?.map((item, index) => {
            return (
              <li key={`tr${index}`}>
                <div className={`flex-1`}>
                  <div>
                    {item.creator.first_name} {item.creator.last_name}
                  </div>
                  <div className={`small-font`}>
                    Subscribed on:{" "}
                    {moment(item.subscribedAt).format("DD-MMM-YYYY")}
                  </div>
                  <div className={`small-font`}>{`Price: ${
                    item.creator.promotional
                      ? "FREE"
                      : `$${item.subscribe_price?.price} / month`
                  }`}</div>
                </div>
                <div className={`align-center`}>
                  <button
                    className="button-red small-font"
                    type="button"
                    onClick={() => {
                      setShowConfirm(item);
                    }}
                  >
                    Unsubscribe
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
export default Subscriptions;
