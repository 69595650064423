import moment from "moment";
import { Line, ResponsiveContainer, LineChart } from "recharts";
import { useCallback, useEffect, useState } from "react";
import baseClient from "../../api/Base.js";
import EditFeed from "../Feed/EditFeed";
import BottomPop from "../Utils/bottompop";

function PostedContent({ dateFilter, dateFilterText, creatorId }) {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const [sortBy, setSortBy] = useState();
  const [sortAsc, setSortAsc] = useState();
  const [masterData, setMasterData] = useState();
  const [fadeIn, setFadeIn] = useState("");
  const [showConfirmZone, setShowConfirmZone] = useState(false);
  const [refreshData, setRefreshData] = useState(true);

  const [showEditForm, setShowEditForm] = useState({
    item: {},
    showForm: false,
  });

  const getMyPosts = useCallback(async () => {
    const model = {
      sort: sortBy || "createdAt",
      sort_asc: sortAsc || false,
      creatorId: creatorId,
      //date_filter: dateFilter.length > 0 ? dateFilter.split(",") : [],
      includeAuthorViews: true,
    };
    const results = await baseClient.APIPost({
      model: model,
      api: "myposts",
    });
    setMasterData(results);
    setData(results.data);
    if (results.data.viewCount) {
      results.data
        .map((item) => item.viewCount)
        .reduce((prev, next) => prev + next);
    }
    setFadeIn("fade-in");
    setRefreshData(null);
  }, [sortBy, sortAsc, creatorId]);

  useEffect(() => {
    try {
      if (refreshData) {
        getMyPosts();
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [getMyPosts, refreshData]);

  useEffect(() => {
    // load chart data
    if (masterData) {
      const tempChartData = [];
      let dateString = "";
      masterData.chartData.forEach((item) => {
        dateString = `${item._id.month}/1/${item._id.year}`;
        tempChartData.push({
          time: moment(dateString).format("MMM"),
          views: item.viewCount,
        });
      });
      setChartData(tempChartData);
    }
  }, [masterData]);

  return (
    <div className={`feed fade ${fadeIn}`}>
      <div className="p-right-1 p-left-1 ">
        <div style={{ width: "100%", height: "100px" }}>
          {chartData ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={"100%"}
                height={100}
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 5,
                }}
              >
                <Line
                  type="monotone"
                  dot={false}
                  dataKey="views"
                  stroke="#00753d"
                  strokeWidth={2}
                  animationEasing="ease-out"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : null}
        </div>
      </div>
      {/*       <div className={`align-left p-left-05 small-font`}>{`${count} ${
        count === 1 ? "view" : "views"
      } ${dateFilterText}`}</div> */}
      {showEditForm.showForm && (
        <BottomPop
          fnClose={() => {
            setShowEditForm({
              item: {},
              showForm: false,
            });
          }}
          title="Edit Post"
          fnLoaded={true}
        >
          <EditFeed
            feed={showEditForm.item}
            setShowEditForm={setShowEditForm}
            setShowConfirmZone={setShowConfirmZone}
          />
        </BottomPop>
      )}
      {showConfirmZone ? (
        <BottomPop
          fnClose={() => {
            setShowEditForm({
              item: {},
              showForm: false,
            });
            setShowConfirmZone(true);
            setRefreshData(true);
          }}
          fnLoaded={true}
        >
          <div style={{ minHeight: "25vh" }}>
            <div className={"larger-font brand-dark-green"}>Hooya!</div>
            <div className={`p-1`}>Your post has been updated.</div>
            <div className={`m-top-05`}>
              <button
                type="button"
                className={`button-save`}
                onClick={() => {
                  setShowConfirmZone(false);
                  setRefreshData(true);
                }}
              >
                Finish
              </button>
            </div>
          </div>
        </BottomPop>
      ) : null}
      <div className={`views-scroller`}>
        <table className={`content-views`}>
          <thead>
            <tr>
              <th className={`align-center no-wrap`}>
                <button
                  type="button"
                  className="anchor-button small-font wide"
                  onClick={() => {
                    setSortBy("type");
                    setSortAsc((prev) => !prev);     
                    setRefreshData(true);               
                  }}
                >
                  Type
                  {sortBy === "type" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className="anchor-button small-font wide align-center"
                  onClick={() => {
                    setSortBy("headline");
                    setSortAsc((prev) => !prev);
                    setRefreshData(true);     
                  }}
                >
                  Headline
                  {sortBy === "headline" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className="anchor-button small-font wide align-center"
                  onClick={() => {
                    setSortBy("createdAt");
                    setSortAsc((prev) => !prev);
                    setRefreshData(true);     
                  }}
                >
                  Date
                  {sortBy === "createdAt" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, key) => {
              return (
                <tr key={`v${key}`}>
                  <td
                    className={`align-center`}
                    onClick={() => {
                      setShowEditForm({
                        item,
                        showForm: true,
                      });
                    }}
                  >
                    <i
                      className={`fa-solid ${
                        item.type === "image" ? "fa-image" : "fa-video"
                      }`}
                    />
                  </td>
                  <td
                    className={`align-center`}
                    onClick={() => {
                      setShowEditForm({
                        item,
                        showForm: true,
                      });
                    }}
                  >
                    {item.headline || "No Caption"}
                  </td>
                  <td
                    className={`align-center no-wrap`}
                    onClick={() => {
                      setShowEditForm({
                        item,
                        showForm: true,
                      });
                    }}
                  >
                    {moment(item.createdAt).format("MMM-D-YYYY")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default PostedContent;
