import { useState } from "react";
import baseClient from "../../api/Base";
import "../../Css/Feed/ReportContent.css";

function ReportContent({ item, fnClose }) {
  const [showConfirm, setShowConfirm] = useState();
  const [reportType, setReportType] = useState("inappropriate");
  const [comment, setComment] = useState();

  const reportPost = async () => {
    try {
      
      const model = {
        postId: item,
        type: reportType,
        comment: comment,
      };
      await baseClient.APIPost({
        model: model,
        api: "reportpost",
      });      
      setShowConfirm(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className={`report-content`}>
      {!showConfirm ? (
        <div className="report-details">
          <h3>Please select an option.</h3>
          <div>
            After you submit, the Axcess team will review the content and take
            appropriate action.
          </div>
          <ul className="content-selector">
            <li>
              <label htmlFor="inappropriate">
                <input 
                  id="inappropriate" 
                  name="type" 
                  type="radio" 
                  defaultChecked={true}
                  onChange={() => {
                    setReportType("inappropriate")
                  }}
                />
                <div className={`flex-1 m-left-05`}>
                  Content is inappropriate or offensive.
                </div>
              </label>
            </li>
            <li>
              <label htmlFor="intellectual">
                <input 
                  id="intellectual" 
                  name="type" 
                  type="radio" 
                  onChange={() => {
                    setReportType("IP violation")
                  }}
                />
                <div className={`flex-1 m-left-05`}>
                  Content violates intellectual property.
                </div>
              </label>
            </li>
            <li>
              <label htmlFor="other">
                <input 
                  id="other" 
                  name="type" 
                  type="radio" 
                  onChange={() => {
                    setReportType("other")
                  }}
                />
                <div className={`flex-1 m-left-05`}>Other (explain)</div>
              </label>
            </li>
            <li>
              <textarea
                rows="5"
                className="report-comment form-control"
                placeholder="Comment / Explain"
                onChange={(e) => {
                  const val = e.target.value;
                  setComment(val)
                }}
              ></textarea>
            </li>
          </ul>
          <div className="report-buttons">          
            <button
              className="button-save"
              type="button"
              onClick={() => {
                reportPost();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        
          <div className="report-confirmation">
            <div className={`larger-font brand-dark-green p-bottom-1`}>
              You're all set.
            </div>
            <div className="m-bottom-1 p-bottom-1">
              Your report has been submitted.
            </div>
            <div className="m-top-1 ">
              <button
                onClick={() => {
                  fnClose(true);
                }}
              >
                Okay
              </button>
            </div>
          </div>
       
      )}
    </div>
  );
}
export default ReportContent;
