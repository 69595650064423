import moment from "moment";

import { useEffect, useRef } from "react";
import { useState } from "react";
import baseClient from "../../api/Base";
import "../../Css/Admin/UsersTable.css";
import AlertDot from "../Utils/alertdot";
import Popover from "../Utils/popover";
import Searchbar from "../Utils/searchbar";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import UserDetails from "./userdetails";
import toolsClient from "../../Javascript/Tools";
import { useCallback } from "react";

function UsersTable({ userId }) {
  const [rec, setRec] = useState();
  const [data, setData] = useState();
  const [filter, setFilter] = useState("");
  const [totalCount, setTotalCount] = useState();
  const [totalCreator, setTotalCreator] = useState();
  const [totalFan, setTotalFan] = useState();
  const [totalApproved, setTotalApproved] = useState();
  const [totalPublished, setTotalPublished] = useState();
  const [totalPending, setTotalPending] = useState();
  const [totalUnpublished, setTotalUnpublished] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [fadeIn, setFadeIn] = useState();
  const [search, setSearch] = useState();
  const [lastId, setLastId] = useState();
  const [endOfFeed, setEndOfFeed] = useState(false);
  const [downloadBlob, setDownloadBlob] = useState();

  const scroller = useRef(null);

  const getUsers = useCallback(
    async (newData = []) => {
      setLoading(true);
      const model = {
        filter: filter,
        page_size: 50,
        search: search,
        last_id: lastId,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "users",
      });
      let feedCopy = response?.data;
      if (lastId && newData.length > 0) {
        // Paging. just append data to existing feed data
        for (let i = 0; i < feedCopy.length; i++) {
          newData.push(feedCopy[i]);
        }
        setData(newData);
      } else {
        // reset feed data entirely
        setData(feedCopy);
      }

      if (feedCopy?.length > 0) {
        setLastId(feedCopy[feedCopy.length - 1]._id);
        setEndOfFeed(false);
      } else {
        setEndOfFeed(true);
      }
      setTotalCount(response.totalCount);
      setTotalPending(response.requestedCount);
      setTotalApproved(response.approvedCount);
      setTotalPublished(response.publishedCount);
      setTotalFan(response.fanCount);
      setTotalCreator(response.creatorCount);
      setTotalUnpublished(response.unpublishedCount);

      // make CSV export
      const ourData = response.csv || [];
      if (ourData.length > 0) {
        const refinedData = [];
        const titleKeys = Object.keys(ourData[0]);
        refinedData.push(titleKeys);
        ourData.forEach((item) => {
          refinedData.push(Object.values(item));
        });
        let csvContent = "";
        refinedData.forEach((row) => {
          csvContent += row.join(",") + "\n";
        });
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8,",
        });
        const objUrl = URL.createObjectURL(blob);
        setDownloadBlob(objUrl);
      } else {
        setDownloadBlob(null);
      }

      setTimeout(() => {
        setLoading(false);
        setFadeIn("fade-in");
      }, toolsClient.LOADING_DELAY);
    },
    [filter, search, lastId]
  );

  useEffect(() => {
    try {
      if (!lastId) {
        setData(null);
        getUsers([]);
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong publishing your channel.",
        title: "Oops",
      });
      console.log(err);
    }
  }, [getUsers, filter, lastId]);

  useEffect(() => {
    // paging

    let scrollRef = scroller.current;
    if (scrollRef) {
      scrollRef.onscroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef;
        //console.log(`${scrollTop}, ${scrollHeight}, ${clientHeight}`);
        if (!endOfFeed && scrollTop + clientHeight === scrollHeight) {
          // This will be triggered after hitting the last element.
          getUsers(data);
        }
      };
    }
    return () => {
      scrollRef.onscroll = null;
    };
  }, [scroller, endOfFeed, getUsers, data]);

  return (
    <div className={`users-table-wrapper`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`fade ${fadeIn}`}>
        {rec ? (
          <Popover
            title={`User Detail`}
            iconClass={`fa-light fa-user-gear`}
            fnClose={() => {
              setRec(null);
            }}
          >
            <UserDetails userRec={rec} userId={userId} />
          </Popover>
        ) : null}

        <div>
          <div className={`flex p-05 `}>
            <div className={`flex-1 relative`}>
              Total
              <span className={`small-font m-left-025`}>
                ({totalCount || 0})
              </span>{" "}
            </div>
            <a
              download={`Users_${filter ? filter : "all"}.csv`}
              href={downloadBlob}
              className={`anchor-button download ${downloadBlob ? 'brand-green' : 'brand-grey'}`}
              disabled={downloadBlob ? false : true}
            >
              <i className="fa fa-file-spreadsheet" />
            </a>
            <div className={`align-right drop-down`}>
              <select
                onChange={(e) => {
                  setFilter(e.target.value);
                  setLastId(null);
                }}
                defaultValue={"All"}
              >
                <option value={`all`}>All ({totalCount || 0})</option>
                <option value={`fan`}>Fan ({totalFan || 0})</option>
                <option value={`creator`}>Creator ({totalCreator || 0})</option>
                <option value={`none`}>
                  Not Published ({totalUnpublished || 0})
                </option>
                <option value={`requested`}>
                  Requested ({totalPending || 0})
                </option>
                <option value={`approved`}>
                  Approved ({totalApproved || 0})
                </option>
                <option value={`published`}>
                  Published ({totalPublished || 0})
                </option>
              </select>
            </div>
           
          </div>
          <div>
            <Searchbar
              fnSearch={(filter) => {
                setSearch(filter);
                setLastId(null);
              }}
            />
          </div>
        </div>
        <div ref={scroller} className={`users-table-scroller`}>
          <table className={`small-font users-table`}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>
                  Join Date <i className={`fa fa-caret-down`} />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                let colorClass = "";
                switch (item.publish_status) {
                  case "requested":
                    colorClass = "brand-orange";
                    break;
                  case "approved":
                    colorClass = "brand-gold";
                    break;
                  case "published":
                    colorClass = "brand-green";
                    break;
                  default:
                    colorClass = "";
                    break;
                }
                return (
                  <tr key={`s-${index}`}>
                    <td className={`${colorClass}`}>
                      <button
                        type={`button`}
                        className={`anchor-button relative align-left`}
                        onClick={() => {
                          setRec(item);
                        }}
                      >
                        {item.publish_status === "requested" ||
                        item.publish_status === "approved" ? (
                          <AlertDot
                            top={"-7px"}
                            right={"unset"}
                            left={"-8px"}
                            color={`gold`}
                          />
                        ) : null}

                        {`${item.last_name}, ${item.first_name}`}
                        {item.deletedAt ? (
                          <div>
                            <span className={`tag deleted-tag`}>deleted</span>
                          </div>
                        ) : null}
                      </button>
                    </td>
                    <td className={`${colorClass}`}>
                      {item.app_role}
                      {item.publish_status !== "none" ? (
                        <div>{item.publish_status}</div>
                      ) : null}
                    </td>
                    <td className={`${colorClass}`}>
                      {moment(item.createdAt).format("MMM-DD `YY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default UsersTable;
