import React, { useCallback, useEffect, useRef, useState } from "react";
import useUpload from "../../hooks/useUpload";
import axios from "axios";
import "../../Css/Feed/AddContent.css";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";

const chunkSize = 10 * 256 * 1024;

const VideoUpload = ({
  fadeIn,
  fnClose,
  setFadeIn,
  setShowCapture,
  setConfirm,
}) => {
  const inputDom = useRef(null);
  const { loading, upload, thumbUrl, uploadUrl } = useUpload(true);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [progress, setProgress] = useState();
  const [durationError, setDurationError] = useState();
  const [isCompressed, setIsCompressed] = useState();
  const [isLandscape, setIsLandscape] = useState();
  const [processing, setProcessing] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const videoPlayer = useRef(null);
  const finalVideo = useRef(null);
  const cprogress = useRef(0);
  const fprogress = useRef(0);

  const [insert, setinsert] = useState({
    headline: "",
    description: "",
    type: "video",
    author: JSON.parse(localStorage.getItem("user"))._id,
    portrait: false,
    width: 0,
    height: 0,
  });

  const postVideo = async (e) => {
    try {
      const { data } = await axios.post(
        "/addfeed/addvideopost",
        { ...insert, content: uploadUrl.split("/").at("-1") },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data.success) {
        setFadeIn(null);
        setShowCapture(null);
        setConfirm(true);
        setTimeout(() => {
          setFadeIn(true);
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompressionProgress = (val) => {
    if (cprogress.current) {
      cprogress.current.innerText = val;
    }
  };

  const handleFinishUp = () => {
    setIsCompressed(true);

    let iterator = 0;
    let myInterval = setInterval(() => {
      if (fprogress.current) {
        fprogress.current.innerText = iterator;
      } else {
        clearInterval(myInterval);
        return;
      }
      iterator += 10;
      if (iterator === 110) {
        clearInterval(myInterval);
      }
    }, 1500);
  };

  const uploadChunk = useCallback(
    (readerEvent) => {
      try {
        const file = inputDom.current.files[0];
        const data = readerEvent.target.result;
        const user_id = JSON.parse(localStorage.getItem("user"))._id;
        const params = new URLSearchParams();
        params.set("name", file.name);
        params.set("size", file.size);
        params.set("landscape", isLandscape || false);
        params.set("currentChunkIndex", currentChunkIndex);
        params.set("totalChunks", Math.ceil(file.size / chunkSize));
        params.set("userId", user_id);
        const headers = { "Content-Type": "application/octet-stream" };
        const urlBase = "https://axcesscron.herokuapp.com/";
        /* const urlBase =
        serverMode === "development"
          ? "http://localhost:3002/"
          : "https://axcesscron.herokuapp.com/"; */
        const url = urlBase + "upload?" + params.toString();
        axios
          .post(url, data, { headers })
          .then((response) => {
            const filesize = file.size;
            const chunks = Math.ceil(filesize / chunkSize) - 1;
            const isLastChunk = currentChunkIndex === chunks;
            if (isLastChunk) {
              const loopInterval = 2000; // 2 SECONDS PER INTERVAL LOOP
              const maxLoops = 60; // IF maxLoops LOOPS AND NO COMPRESSED FILE, BAIL OUT ERROR
              let loopCount = 0;
              const vidKey = response.data;
              let finalname = file.name.split(".");
              finalname = `${finalname[0]}.mp4`;
              let checkInterval = setInterval(() => {
                // TEST EVERY X-SECONDS TO SEE IF COMPRESSION IS DONE THEN FETCH FINAL FILE VIA XHR(GET)
                // UNLESS ERROR OR MAX LOOPS REACHED
                loopCount += 1;

                if (loopCount === maxLoops) {
                  // ERROR OUT. TOOK TOO LONG OR SOME OTHER COMPRESSION ERROR ON SERVER
                  clearInterval(checkInterval);
                  setCurrentChunkIndex(null);
                  setErrorMsg({
                    message: "Something went wrong compressing file.",
                    title: "Oops!",
                  });
                  baseClient.serverLogger(
                    `VideoUpload.jsx/uploadChunk: compressing error or took too long to compress`
                  );
                }

                axios
                  .post(`${urlBase}testfile`, {
                    fileName: finalname,
                    key: vidKey,
                    userId: user_id,
                  })
                  .then((testresponse) => {
                    const {
                      status,
                      progress: compressProgress,
                      message,
                    } = testresponse.data;
                    console.log(`${status}, ${compressProgress}, ${message}`);
                    updateCompressionProgress(compressProgress);
                    if (status === "error") {
                      // SOMETHING WENT WRONG ON SERVER. BAIL OUT. MESSAGE USER.
                      clearInterval(checkInterval);
                      setCurrentChunkIndex(null);
                      setErrorMsg({
                        message: "Something went wrong processing file.",
                        title: "Oops!",
                      });
                      baseClient.serverLogger(
                        `VideoUpload.jsx/uploadChunk: compressing error or some other server error`
                      );
                    }

                    if (status === "done") {
                      clearInterval(checkInterval);
                      setCurrentChunkIndex(null);
                      setProgress(100);
                      handleFinishUp();
                      // console.log(response.data);

                      const toDataUrl = (url, callback) => {
                        var xhr = new XMLHttpRequest();
                        xhr.onload = function () {
                          callback(xhr.response);
                        };
                        xhr.open("GET", url);
                        xhr.responseType = "blob";
                        xhr.send();
                      };

                      let newVideo;
                      toDataUrl(`${urlBase}temp/${finalname}`, function (x) {
                        newVideo = x;
                        const myFile = new File([newVideo], finalname, {
                          type: "video/mp4",
                        });

                        // Now let's create a FileList
                        const dataTransfer = new DataTransfer();
                        dataTransfer.items.add(myFile);
                        inputDom.current.files = dataTransfer.files;
                        //console.log(inputDom.current.files[0]);
                        upload(myFile);
                        const deleteParams = new URLSearchParams();
                        deleteParams.set("name", finalname);
                        const deleteHeaders = {
                          "Content-Type": "application/json",
                        };
                        axios
                          .post(
                            `${urlBase}delete?${deleteParams.toString()}`,
                            {},
                            { deleteHeaders }
                          )
                          .then((response) => {
                            console.log(
                              `deleteResponse: ${response?.data?.message}`
                            );
                          });
                      });
                    }
                  });
              }, loopInterval);

              // Help Safari out
              /* if (inputDom.current.files.webkitEntries.length) {
            inputDom.current.files.dataset.file = `${dataTransfer.files[0].name}`;
          } */
            } else {
              setCurrentChunkIndex(currentChunkIndex + 1);
              setProgress(Math.round(((currentChunkIndex + 1) / chunks) * 100));
            }
          })
          .catch((error) => {
            setCurrentChunkIndex(null);
            setErrorMsg({
              message: "Something went wrong uploading chunck.",
              title: "Oops!",
            });
            baseClient.serverLogger(
              `VideoUpload.jsx/uploadChunk: ${error.message}`
            );
          });
      } catch (err) {
        setCurrentChunkIndex(null);
        setErrorMsg({
          message: "Something went wrong uploading chunck.",
          title: "Oops!",
        });
        baseClient.serverLogger(`VideoUpload.jsx/uploadChunk: ${err.message}`);
        console.log(err.message);
      }
    },
    [currentChunkIndex, upload, isLandscape]
  );

  const readAndUploadCurrentChunk = useCallback(
    (e) => {
      try {
        const file = inputDom.current.files[0];
        const reader = new FileReader();
        if (!file) {
          return;
        }
        const from = currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(from, to);
        reader.onload = (e) => uploadChunk(e);
        reader.readAsDataURL(blob);
      } catch (err) {
        setCurrentChunkIndex(null);
        setErrorMsg({
          message: "Something went wrong uploading current chunck.",
          title: "Oops!",
        });
        baseClient.serverLogger(
          `VideoUpload.jsx/readAndUploadCurrentChunk: ${err.message}`
        );
        console.log(err.message);
      }
    },
    [currentChunkIndex, uploadChunk]
  );

  const startUploadProcess = (e) => {
    try {
      setProcessing(true);
      const currentFile = e.target.files[0];
      const videoElement = videoPlayer.current;
      videoElement.src = currentFile;
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = function (data) {
        window.URL.revokeObjectURL(videoElement.src);
        const vw = data.srcElement.videoWidth;
        const vh = data.srcElement.videoHeight;
        console.log(`${vw}x${vh}`);
        if (vw > vh) {
          setIsLandscape(true);
        }
        const duration = parseInt(videoElement.duration / 60, 10);
        if (duration > 2) {
          setDurationError(
            `Your video is ${duration} minutes long. There is a 2-minute max limit on Axcess feed videos.`
          );
          setProcessing(null);
          return;
        } else {
          //setChunks(Math.ceil(currentFile.size / chunkSize));
          setCurrentChunkIndex(0);
          setProgress(0);
        }
      };
      videoElement.src = URL.createObjectURL(currentFile);
    } catch (err) {
      setCurrentChunkIndex(null);
      setErrorMsg({
        message: "Something went wrong starting the upload process.",
        title: "Oops!",
      });
      baseClient.serverLogger(
        `VideoUpload.jsx/startUploadProcess: ${err.message}`
      );
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex, readAndUploadCurrentChunk]);

  return (
    <div className={`add-content fade ${fadeIn ? "fade-in" : ""}`}>
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`p-bottom-05 p-top-05`}>
        {thumbUrl === "" && uploadUrl === "" ? (
          <>
            {processing ? (
              <div
                className={`p-1 bg-brand-background m-left-05 m-right-05 small-font`}
              >
                <div>PROCESSING...</div>
                Don't let your device sleep or screen-lock.
              </div>
            ) : null}
            <input
              type="file"
              ref={inputDom}
              onChange={(e) => {
                startUploadProcess(e);
              }}
              style={{ display: "none" }}
              accept="video/mp4,video/x-m4v,video/*"
            />
            <button
              className={`btn btn-success ${processing ? "hidden" : ""}`}
              onClick={(e) => {
                setDurationError(null);
                inputDom.current.click();
              }}
              disabled={loading}
            >
              {loading && <i className="fad fa-spinner fa-spin me-2"></i>}
              Upload Video
            </button>
            <div className={`${processing ? "hidden" : ""}`}>
              2-min max video length
            </div>
            <video ref={videoPlayer} className={`hidden`}></video>
            {durationError ? (
              <div className={`p-1 m-1 bg-white round-10`}>
                <div className={`larger-font brand-red m-bottom-05`}>Snap!</div>
                <div>{durationError}</div>
              </div>
            ) : null}
          </>
        ) : (
          <div>
            <div className={`chooser-button-bar`}>
              {thumbUrl !== "" && uploadUrl !== "" && (
                <button
                  type="button"
                  className={`button-save m-05`}
                  onClick={postVideo}
                >
                  Post
                </button>
              )}
            </div>
            <div className={`image-wrapper`}>
              <video
                ref={finalVideo}
                id="player"
                playsInline={true}
                muted={true}
                autoPlay={true}
                controls={true}
                data-poster={thumbUrl}
                style={{ width: "100%" }}
                onLoadedData={(e) => {
                  setProcessing(null);
                  const vid = e.target;
                  insert.width = vid.videoWidth;
                  insert.height = vid.videoHeight;
                  const vidDim = vid.videoWidth / vid.videoHeight;
                  if (vidDim < 0.8) {
                    insert.portrait = true;
                  }
                }}
              >
                <source src={uploadUrl} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        {processing ? (
          <div className={`processing`}>
            {progress > 0 && progress < 100 ? (
              <div className={`p-05 border-box`}>
                <div
                  className={`bg-brand-pink border-box round-10 p-025`}
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </div>
              </div>
            ) : progress === 0 ? (
              <div className={`p-05 border-box`}>
                <div className={`border-box round-10 p-025`}>
                  Fetching video from device
                  <i className={`fa fa-spinner fa-spin m-left-05`} />
                </div>
              </div>
            ) : null}

            {progress === 100 && !isCompressed ? (
              <div className={`p-05 border-box`}>
                <div
                  className={`bg-brand-gold border-box round-10 p-025`}
                  style={{ width: `${progress}%` }}
                >
                  Compressing <span ref={cprogress}></span>%
                  <i className={`fa fa-spinner fa-spin m-left-05`} />
                </div>
              </div>
            ) : null}

            {isCompressed && loading ? (
              <div className={`p-05 border-box`}>
                <div
                  className={`bg-brand-dark-green border-box round-10 p-025`}
                  style={{ width: `${progress}%`, color: "white" }}
                >
                  Finishing Up <span ref={fprogress}></span>%
                  <i className={`fa fa-spinner fa-spin m-left-05`} />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={`chooser-form video-form`}>
        <form>
          <label htmlFor="caption">
            <div>
              <textarea
                id="caption"
                rows="2"
                placeholder="Caption (optional)"
                onChange={(e) =>
                  setinsert((prev) => ({ ...prev, headline: e.target.value }))
                }
              ></textarea>
            </div>
          </label>
          <label htmlFor="description">
            <div>
              <textarea
                id="description"
                rows="4"
                onChange={(e) =>
                  setinsert((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                placeholder="Description (optional)"
              ></textarea>
            </div>
          </label>
        </form>
      </div>
    </div>
  );
};

export default VideoUpload;
