import { useEffect, useState } from "react";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";
import Popover from "../Utils/popover";
import SportForm from "./sportform";

function Sports({ fnClose }) {
  const [sports, setSports] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [editSport, setEditSport] = useState();
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() => {
    try {
      const getSports = async () => {
        setRefreshData(null);
        setLoading(true);
        const model = {};
        const result = await baseClient.APIPost({
          model: model,
          api: "sports",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting sports. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        setSports(result.data);
        setTimeout(() => {
          setLoading(false);
        }, toolsClient.LOADING_DELAY);
      };
      if (refreshData) {
        getSports();
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: `Something went wrong loading sports. ${err.message}`,
        title: "Oops",
      });
      console.error(err.message);
    }
  }, [refreshData]);

  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {editSport ? (
        <Popover
          title="Sports"
          iconClass={`fa fa-soccer-ball-o`}
          fnClose={() => {
            setRefreshData(true);
            setTimeout(() => {
              setEditSport(null);
            }, 100);
          }}
        >
          <SportForm
            fnClose={() => {
              setRefreshData(true);
              setTimeout(() => {
                setEditSport(null);
              }, 100);
            }}
            id={editSport}
          />
        </Popover>
      ) : null}
      <div className={`p-1`}>
        <div className={`p-1 align-center b-bottom`}>
          <h2>Sports</h2>
          <div className={`p-top-1`}>
            <button
              type="button"
              className={`anchor-button brand-link-color`}
              onClick={() => {
                setEditSport("new");
              }}
            >
              <i className={`fa-solid fa-plus-circle m-right-05`} />
              Add New Sport
            </button>
          </div>
        </div>
        {sports?.map((item, index) => {
          return item.sport.length > 0 ? (
            <div key={`sport${index}`} className={`p-05 b-bottom`}>
              <button
                type="button"
                className={`anchor-button brand-link-color`}
                onClick={() => {
                  setEditSport(item._id);
                }}
              >
                {item.sport}
              </button>
            </div>
          ) : null;
        })}
        <div className={`p-1 m-top-1 align-center`}>
          <button
            type="button"
            className={`button-close`}
            onClick={() => {
              fnClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
export default Sports;
