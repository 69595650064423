import { useEffect, useRef } from "react";
import { useState } from "react";
import "../../Css/Feed/Discover.css";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import DialogPop from "../Utils/dialogpop";
import Popover from "../Utils/popover";
import Subscribe from "../Subscriptions/subscribe";
import ProfileHeader from "../User/profileheader";
import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";
import { useCallback } from "react";
import FeedTags from "./feedtags";
import Tips from "../Products/tips";
import BottomPop from "../Utils/bottompop";
import badge6 from "../../Graphics/CertBadge6.png";

function Discover({ setDisoverRefresh }) {
  const [discoverData, setDiscoverData] = useState();
  const [showSubscribe, setShowSubscribe] = useState();
  const [comingSoon, setComingSoon] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState();
  const [fadeIn, setFadeIn] = useState();
  const [lastApproved, setLastApproved] = useState();
  const [endOfFeed, setEndOfFeed] = useState();
  const [showTags, setShowTags] = useState();
  const [availableSportTags, setAvailableSportTags] = useState([]);
  const [mySportTags, setMySportTags] = useState();
  const [showAddFilters, setShowAddFilters] = useState();
  const [searchCount, setSearchCount] = useState();
  const [feedWaiting, setFeedWaiting] = useState();
  const [showTip, setShowTip] = useState();
  const [showAllSportsTags, setShowAllSportsTags] = useState();

  const discoverRef = useRef();
  

  const toggleAddFilters = () => {
    if (showAddFilters) {
      setShowAddFilters(null);
    } else {
      const currentFilters = [];
      for (let i = 0; i < mySportTags?.length; i++) {
        currentFilters.push(mySportTags[i]._id);
      }
      setShowAddFilters(true);
    }
  };

  const removeAllTags = () => {
    setDiscoverData([]);
    setMySportTags(null);
    setLastApproved(null);
    setShowTags(null);
    getDiscoverFeed([], true, []);
  };

  const removeTag = (tag) => {
    setMySportTags(null);
    const tempArray = [];
    if (mySportTags) {
      for (let i = 0; i < mySportTags.length; i++) {
        if (tag._id !== mySportTags[i]._id) {
          tempArray.push(mySportTags[i]);
        }
      }
    }
    if (tempArray.length === 0) {
      removeAllTags();
      return true;
    }
    setMySportTags(tempArray);
    setLastApproved(null);
  };

  const addTag = (tag) => {
    let tempArray = [];

    if (mySportTags) {
      for (let i = 0; i < mySportTags.length; i++) {
        tempArray.push(mySportTags[i]);
      }
    }
    tempArray.push(tag);
    tempArray = tempArray.sort((s1, s2) =>
      s1.sport < s2.sport ? -1 : s1.sport > s2.sport ? 1 : 0
    );
    setMySportTags(tempArray);
    setLastApproved(null);
  };

  const getMySportsTags = async () => {
    const tempUser = await JSON.parse(localStorage.getItem("user"));
    setMySportTags(tempUser.sports);
    setDiscoverData([]);
    getDiscoverFeed([], true, tempUser.sports);
  };

  const getDiscoverFeed = useCallback(
    async (newData = [], restart, spTags) => {
      try {
        setFeedWaiting(true);
        if (newData.length === 0) {
          setLoading(true);
        } else {
          setLoading(false);
        }

        // the model is asking for us to page based on last approved date (approvedAt) DESC
        let model = {
          page_size: 50,
          last_approved: !restart ? lastApproved : null,
          sports: spTags || mySportTags,
        };
        const results = await baseClient.APIPost({
          model: model,
          api: "discover",
        });

        setDisoverRefresh(results?.refreshNeeded);

        // set the sport tags for filtering
        setAvailableSportTags(results?.sports);
        setSearchCount(results?.count || 0);

        if (!results || !results.data) {
          // bail out if no data
          setEndOfFeed(true);
          setLoading(false);
          setFadeIn("fade-in");
          setFeedWaiting(null);
          return true;
        }

        let resultsCopy = results?.data;
        if (lastApproved && newData?.length > 0) {
          // Paging. just append data to existing feed data
          for (let i = 0; i < resultsCopy.length; i++) {
            newData.push(resultsCopy[i]);
          }
          setDiscoverData(newData);
        } else {
          setDiscoverData(resultsCopy);
        }
        if (resultsCopy?.length > 0) {
          // for paging. we're sorting by latest approved published channel
          setLastApproved(resultsCopy[resultsCopy.length - 1].approvedAt);
          setEndOfFeed(false);
        } else {
          setEndOfFeed(true);
        }
        setTimeout(() => {
          setLoading(false);
          setFadeIn(true);
        }, toolsClient.LOADING_DELAY);
        setFeedWaiting(null);
      } catch (err) {
        setFeedWaiting(null);
        setLoading(false);
        setErrorMsg({
          message: `Something went wrong fetching discover feed. ${err.message}`,
          title: "Oops",
        });
        console.error(err.message);
      }
    },
    [lastApproved, mySportTags, setDisoverRefresh]
  );

  useEffect(() => {
    try {
      const refreshProducts = async () => {
        try {
          await window.gonative.iap.refresh();
        } catch (err) {
          //baseClient.serverLogger(err.message);
          console.log(err.message);
        }
      };
      refreshProducts();
    } catch (err) {
      baseClient.serverLogger(err.message);
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    setMySportTags([]);
  }, []);

  useEffect(() => {
    try {
      if (!lastApproved && mySportTags) {
        setDiscoverData(null);
        getDiscoverFeed([], true);
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong loading screen.",
        title: "Oops",
      });
      console.error(err.message);
    }
  }, [getDiscoverFeed, lastApproved, mySportTags]);

  useEffect(() => {
    // paging
    let ref = discoverRef.current;
    if (ref) {
      ref.onscroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = ref;
        if (!endOfFeed && scrollTop + clientHeight === scrollHeight) {
          // This will be triggered after hitting the last element.
          if (!feedWaiting) {
            getDiscoverFeed(discoverData);
          }
        }
      };
    }
    /*  return () => {
      homeReference.onscroll = null;
    }; */
  }, [endOfFeed, getDiscoverFeed, discoverData, feedWaiting]);

  return (
    <div ref={discoverRef} className={`discover-feed`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {/* DESCRIPTION */}
      {showTip ? (
        <Tips
          creator={showTip}
          fnClose={() => {
            setShowTip(null);
          }}
        />
      ) : null}
      {comingSoon ? (
        <DialogPop title={"Coming Soon"} fnClose={() => setComingSoon(null)}>
          <div>This feature is coming soon!</div>
          <div className="m-top-1">
            <button
              type="button"
              className={`button button-cancel m-05`}
              onClick={() => {
                setComingSoon(null);
              }}
            >
              Close
            </button>
          </div>
        </DialogPop>
      ) : null}
      {showSubscribe ? (
        <BottomPop
          title={`Unlock Content`}
          fnClose={() => {
            setShowSubscribe(null);
          }}
          /* height={'100vh'} */
          fnLoaded={true}
          height={`100%`}
          /* iconClass={`fa-solid fa-rss`} */
        >
          <Subscribe
            fnClose={() => {
              setShowSubscribe(null);
            }}
            creator={showSubscribe}
            
          />
        </BottomPop>
      ) : null}
      {showAddFilters ? (
        <Popover
          title={`Filter Tags`}
          iconClass={`fa-light fa-filter`}
          fnClose={() => {
            setShowAddFilters(null);
          }}
        >
          <FeedTags
            tagData={availableSportTags}
            myTagIds={mySportTags || []}
            fnRemoveTag={(tag) => {
              removeTag(tag);
            }}
            fnRemoveAll={() => {
              removeAllTags();
            }}
            fnAddTag={(tag) => {
              addTag(tag);
            }}
            fnSetDefaults={() => {
              getMySportsTags();
            }}
            fnClose={() => {
              setShowAddFilters(null);
            }}
            searchCount={searchCount || 0}
          />
        </Popover>
      ) : null}
      <div className={`fade ${fadeIn ? "fade-in" : ""} `}>
        {/* FILTER TAGS */}
        <div className={`tags-wrapper`}>
          <button
            type="button"
            className={`anchor-button tags-filter ${
              showTags ? "bg-brand-background" : ""
            }`}
            onClick={() => {
              //setShowTags((prev) => !prev);
              toggleAddFilters();
            }}
          >
            <div className={`filter-inner`}>
              <i className="fa fa-filter m-right-05" />
              Filter
              <span className={`filter-counter`}>
                {mySportTags?.length || 0}
              </span>
            </div>
          </button>
        </div>
        {/* DISCOVER FEED */}
        <ul>
          {discoverData?.length > 0 ? (
            discoverData.map((item, index) => {
              return (
                <ProfileHeader
                  creator={item}
                  key={`f${index}`}
                  fnSubscribe={(data) => {
                    setShowSubscribe(data);
                  }}
                  fnShowTip={(val) => {
                    setShowTip(val);
                  }}
                  fnComingSoon={() => {
                    setComingSoon(true);
                  }}
                  badge={badge6}
                  showAllSportsTags={showAllSportsTags}
                  setShowAllSportsTags={(val) => {
                    setShowAllSportsTags(val);
                  }}
                />
              );
            })
          ) : !loading && (!discoverData || discoverData?.length === 0) ? (
            <li className={`no-data`}>
              <div className={`p-top-1`}> </div>
              <div
                className={`p-bottom-025 p-top-1 m-top-1 muted-text small-font`}
              >
                No creators found based on filter.
              </div>
            </li>
          ) : null}
        </ul>
        {feedWaiting ? (
          <div className={`loading-more fade fade-in`}>
            <i className={`fa fa-spinner fa-spin m-right-05`} />
            {endOfFeed ? "end of feed" : "loading more"}
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default Discover;
