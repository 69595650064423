import moment from "moment";
import { useState } from "react";
import Profile from "../User/profile";
import Slideover from "../Utils/slideover";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import baseClient from "../../api/Base";
import { useEffect } from "react";
import toolsClient from "../../Javascript/Tools";
import Popover from "../Utils/popover";
import ApproveCreator from "./approvecreator";
import DialogPop from "../Utils/dialogpop";
import ActivityDashboard from "../ChannelActivity/activitydashboard";

function UserDetails({ userRec, userId }) {
  const [profile, setProfile] = useState();
  const [approveConfirm, setApproveConfirm] = useState();
  const [publisheConfirm, setPublishConfirm] = useState();
  const [publishDone, setPublishDone] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [fadeIn, setFadeIn] = useState();
  const [sports, setSports] = useState();
  const [subscriptionData, setSubscriptionData] = useState();
  const [showActivity, setShowActivity] = useState();

  const savePublish = async () => {
    try {
      setLoading(true);
      const model = {
        user_id: userRec._id,
      };
      const updatedUser = await baseClient.APIPost({
        model: model,
        api: "publishcreatorlive",
      });
      if (updatedUser && updatedUser.data) {
        setLoading(false);
        userRec.publish_status = "published";
        setPublishDone(true);
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong publishing channel.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  const changePromotional = async (val) => {
    try {
      setLoading(true);
      const model = {
        user_id: userRec._id,
        promo_val: val,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "updatepromo",
      });
      setTimeout(() => {
        setLoading(false);
      }, toolsClient.LOADING_DELAY);

      if (response.code !== 200) {
        setErrorMsg({
          message: "Something went wrong changing Promotional status.",
          title: "Oops",
        });
      } else {
        userRec.promotional = val;
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong with Promotional status",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  const changeAxcessAdmin = async (val) => {
    try {
      setLoading(true);
      const model = {
        user_id: userRec._id,
        admin_val: val,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "updateadmin",
      });
      setTimeout(() => {
        setLoading(false);
      }, toolsClient.LOADING_DELAY);
      if (response.code !== 200) {
        setErrorMsg({
          message: "Something went wrong changing AxcessAdmin status.",
          title: "Oops",
        });
      } else {
        userRec.axcess_admin = val;
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong with AxcessAdmin status",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  const changeAppTester = async (val) => {
    try {
      setLoading(true);
      const model = {
        user_id: userRec._id,
        tester_val: val,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "updatetester",
      });
      setTimeout(() => {
        setLoading(false);
      }, toolsClient.LOADING_DELAY);
      if (response.code !== 200) {
        setErrorMsg({
          message: "Something went wrong changing App Tester status.",
          title: "Oops",
        });
      } else {
        userRec.isAppTester = val;
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong with AppTester status",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  const validateEmail = async (val) => {
    try {
      setLoading(true);
      const model = {
        user_id: userRec._id,
        valid: val === "true",
      };

      const response = await baseClient.APIPost({
        model: model,
        api: "adminemailvalidate",
      });
      setTimeout(() => {
        setLoading(false);
      }, toolsClient.LOADING_DELAY);
      if (response.code !== 200) {
        setErrorMsg({
          message: "Something went wrong changing Email Validation Status.",
          title: "Oops",
        });
      } else {
        userRec.emailVerifiedAt = val ? new Date() : null;
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong with Email Validation change",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    setSports(userRec.sports);
    const getUserSubscriptions = async () => {
      const model = {
        user_id: userRec._id,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "usersubscriptions",
      });
      setSubscriptionData(response.data);
    };
    getUserSubscriptions();
    setTimeout(() => {
      setFadeIn("fade-in");
    }, 500);
  }, [userRec]);

  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg?.title || "Error"}
          message={errorMsg?.message || "Something went wrong"}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {/* PUBLISH CONFIRM */}
      {publisheConfirm ? (
        <DialogPop
          title={`Publish Creator`}
          headerIcon={`question`}
          fnClose={() => {
            setPublishConfirm(null);
          }}
        >
          <div>
            {publishDone ? (
              <div>
                <div className={`larger-font`}>You're all set!</div>
                <div>
                  {`${userRec.first_name} ${userRec.last_name} has been published`}
                </div>
                <div className={`p-1`}>
                  <button
                    type="button"
                    onClick={() => {
                      setPublishConfirm(null);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className={`larger-font`}>Are you sure?</div>
                <div>{`Confirm publish: ${userRec.first_name} ${userRec.last_name}`}</div>
                <div className={`p-top-1 small-font`}>
                  NOTE: Make sure that the subscription records have been
                  approved and published by the app stores before you proceed.
                </div>
                <div className={`p-1`}>
                  <button
                    type="button"
                    className={`button-cancel m-05`}
                    onClick={() => {
                      setPublishConfirm(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`button-save m-05`}
                    onClick={() => {
                      savePublish();
                    }}
                  >
                    Publish
                  </button>
                </div>
              </div>
            )}
          </div>
        </DialogPop>
      ) : null}
      {/* APPROVE CONFIRM */}
      {approveConfirm ? (
        <Popover
          title={`Approve Creator`}
          iconClass={`fa-light fa-user-gear`}
          fnClose={() => {
            setApproveConfirm(null);
          }}
        >
          <ApproveCreator
            userRec={userRec}
            fnClose={() => {
              setApproveConfirm(null);
            }}
          />
        </Popover>
      ) : null}
      {/* ATHLETE PROFILE POP */}
      {profile ? (
        <Slideover
          fnClose={() => setProfile(null)}
          title={`${profile.first_name} ${profile.last_name}`}
          scrollSnap={true}
          /* iconClass={"fa-light fa-user"} */
        >
          <Profile creatorId={profile._id} allowAdmin={true} />
        </Slideover>
      ) : null}
       {/* ATHLETE Activity POP */}
       {showActivity ? (
        <Slideover
          fnClose={() => setShowActivity(null)}
          title={`${userRec.first_name} ${userRec.last_name}`}
          scrollSnap={true}
          className={`activity-top`}          
        >
          <ActivityDashboard creatorId={userRec._id} />
        </Slideover>
      ) : null}
      <div className={`p-1 fade ${fadeIn}`}>
        <div className={`flex p-05`}>
          <div className={`flex-1`}>
            {userRec.first_name} {userRec.last_name}
          </div>
          <div className={`align-right`}>
            {userRec?._id !== userId ? (
              <>
                <button
                  type="button"
                  className={`anchor-button brand-link-color small-font`}
                  onClick={() => {
                    setProfile(userRec);
                  }}
                >
                  Profile
                </button>
              </>
            ) : null}

            {userRec.publish_status === "requested" ? (
              <>
                <button
                  type="button"
                  className={`anchor-button brand-orange small-font m-left-1`}
                  onClick={() => {
                    setApproveConfirm(true);
                  }}
                >
                  Approve
                </button>
              </>
            ) : null}
            {userRec.publish_status === "approved" ? (
              <>
                <button
                  type="button"
                  className={`anchor-button brand-gold small-font m-left-1`}
                  onClick={() => {
                    setPublishConfirm(true);
                  }}
                >
                  Publish
                </button>
              </>
            ) : null}
            {userRec.publish_status === "published" ? (
              <>
                <button
                  type="button"
                  className={`anchor-button brand-gold small-font m-left-1`}
                  onClick={() => {
                    setShowActivity(true);
                  }}
                >
                  Activity
                </button>
              </>
            ) : null}
          </div>
        </div>
        <div>
          <table className={`user-details`}>
            <tbody>
              <tr>
                <td>Role</td>
                <td>{userRec.app_role}</td>
              </tr>
              <tr>
                <td>Publish Status</td>
                <td
                  className={`${
                    userRec.publish_status === "requested" ? "brand-gold" : ""
                  }`}
                >
                  {userRec.publish_status}
                </td>
              </tr>
              <tr>
                <td>Requested On</td>
                <td>
                  {userRec.publish_requestAt
                    ? moment(userRec.publish_requestAt).format("MMM DD YYYY")
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Approved On</td>
                <td>
                  {userRec.approvedAt
                    ? moment(userRec.approvedAt).format("MMM DD YYYY")
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Approved By</td>
                <td>
                  {userRec.approvedBy?.first_name}{" "}
                  {userRec.approvedBy?.last_name}
                </td>
              </tr>
              <tr>
                <td>Organization</td>
                <td>{userRec.organization}</td>
              </tr>
              <tr>
                <td>Email Verified?</td>
                <td>
                  <select
                    defaultValue={userRec.emailVerifiedAt ? true : false}
                    onChange={(e) => {
                      validateEmail(e.target.value);
                    }}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Sport(s)</td>
                <td>
                  {sports?.map((sport, index) => {
                    return (
                      <span key={`isport${index}`} className={`tag`}>
                        {sport.sport}
                      </span>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td>Position</td>
                <td>{userRec.org_role}</td>
              </tr>
              <tr>
                <td>Joined</td>
                <td>{moment(userRec.createdAt).format("MMM DD YYYY")}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{userRec.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{userRec.phone}</td>
              </tr>
              <tr>
                <td>Subscription Price</td>
                <td>{userRec.subscribe_price?.price}</td>
              </tr>
              <tr>
                <td>Shoutout Price</td>
                <td>{userRec.shoutout_price?.price}</td>
              </tr>
              <tr>
                <td>Axcess Admin?</td>
                <td>
                  {userRec._id === userId ? (
                    <span>{userRec.axcess_admin ? "Yes" : "No"}</span>
                  ) : (
                    <select
                      defaultValue={userRec.axcess_admin}
                      onChange={(e) => {
                        changeAxcessAdmin(e.target.value);
                      }}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                  )}
                </td>
              </tr>
              <tr>
                <td>Promo Account?</td>
                <td>
                  <select
                    defaultValue={userRec.promotional}
                    onChange={(e) => {
                      changePromotional(e.target.value);
                    }}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>App Tester?</td>
                <td>
                  <select
                    defaultValue={userRec.isAppTester}
                    onChange={(e) => {
                      changeAppTester(e.target.value);
                    }}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>User ID</td>
                <td>{userRec._id}</td>
              </tr>
              <tr>
                <td>Feed View</td>
                <td>
                  {userRec.lastFeedLaunchAt
                    ? moment(userRec.lastFeedLaunchAt).format("MMM-D-YYYY")
                    : null}
                </td>
              </tr>
              <tr>
                <td>Discover View</td>
                <td>
                  {userRec.lastDiscoverLaunchAt
                    ? moment(userRec.lastDiscoverLaunchAt).format("MMM-D-YYYY")
                    : null}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className={`bg-brand-background b-1`}>
                  SUBSCRIPTIONS ({subscriptionData?.length || 0})
                </td>
              </tr>
              {subscriptionData?.map((item, key) => {
                return (
                  <tr key={`sub${key}`}>
                    <td className={`bg-brand-background b-1`}>
                      {`${item.creator.first_name} ${item.creator.last_name}`}
                      {item.creator.promotional ? <div>Promotional</div> : null}
                    </td>
                    <td className={`bg-brand-background b-1`}>
                      On: {moment(item.updatedAt).format("MMM-D-YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default UserDetails;
