import { useEffect } from "react";
import { useState } from "react";
import logo from "../../Graphics/AxcessLogo.png";
function SessionExpired() {
  const [fade, setFade] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setFade('fade-in');
    }, 500);
  }, [])
  return (
    <div className={`fade ${fade} p-top-1`}>
      <div className={`public-logo p-top-1 m-top-1`}>
        <img src={logo} alt="Axcess" />
      </div>
      <div className={"m-bottom-1 p-bottom-1"}>The Athletes World</div>
      <div className={`p-1 large-font`}>Session Expired</div>
      <div className={`p-1`}>
        <button
          type="button"
          onClick={() => {
            document.location.replace("/welcome");
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
}
export default SessionExpired;
