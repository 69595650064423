import moment from "moment";

function logout() {
  localStorage.setItem("token", null);
  localStorage.setItem("user", null);
}

const checkRequired = (e, len) => {
  const ref = e.target ? e.target : e;
  //check IsNullOrWhiteSpace
  if (
    ref.required &&
    (ref.value == null ||
      ref.value.match(/^\s*$/) !== null ||
      (len !== null && ref.value.length < len))
  ) {
    ref.classList.add("required");
    return false;
  } else {
    ref.classList.remove("required");
    return true;
  }
};

const getFormModel = (myForm, myModel, setLoading) => {
  let model = myModel;
  for (let i = 0; i < myForm.length; i++) {
    let el = myForm[i];
    let key = el.name;
    let obj = {};
    if (key.length > 0) {
      if (!checkRequired(el)) {
        if (setLoading) {
          setLoading(false);
        }
        return false;
      }
      obj[key] = el.value;
      model = {
        ...model,
        [key]: el.value && el.value.length > 0 ? el.value : null,
      };
    }
  }
  return model;
};

const maskPhone = (e, country = "usa") => {
  if (country === "usa") {
    let x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  }
};

const getPhoneMask = (phoneNbr, country = "usa") => {
  if (country === "usa") {
    let x = phoneNbr.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (
      "+1 " +
      (!x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""))
    );
  }
};

function eachWordToUpper(sentence) {
  try {
    return sentence.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  } catch (err) {
    return sentence;
  }
}
function initialCaps(sentence) {
  try {
    const regEx = new RegExp(',', 'g');
    sentence = sentence.replace(regEx, "");
    let words = sentence.split(" ");
    for (let index = 0; index < words.length; index++) {
      words[index] =
        words[index][0].toUpperCase() + words[index].substr(1).toLowerCase();
    }
    //words.join(" ");
    let newsentence = words.toString();   
    newsentence = newsentence.replace(regEx, " ");
    return newsentence;
  } catch (err) {
    return sentence;
  }
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

async function resizeImage(img, w = 300, h = 200) {
  const myCanvas = document.createElement("canvas");
  const MAX_WIDTH = w;
  const MAX_HEIGHT = h;
  let width = img.width || MAX_WIDTH;
  let height = img.height || MAX_HEIGHT;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  myCanvas.width = width;
  myCanvas.height = height;
  const ctx = myCanvas.getContext("2d");
  ctx.webkitImageSmoothingEnabled = false;
  ctx.mozImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;
  ctx.drawImage(img, 0, 0, width, height);
  //debugger;
  const dataURL = myCanvas.toDataURL("image/png");
  return dataURL;
}

function searchArray(nameKey, prop, myArray, returnRow) {
  let response = null;
  if (myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][prop] === nameKey) {
        response = returnRow ? myArray[i] : myArray[i][prop];
      }
    }
  }
  return response;
}

const isVideoPlaying = (video) =>
  !!(video.currentTime > 0 && !video.ended && video.readyState > 2);

let date = new Date();
const month_to_date = {
  display: `month to date: ${moment(date).format("MMM")} 1 - ${moment(
    date
  ).format("MMM")} ${moment(date).format("D")}`,
  value: `${moment(date).format("MM/1/YYYY")},${moment(date).format(
    "MM/D/YYYY"
  )}`,
};
const year_to_date = {
  display: `year to date: Jan 1 - ${moment(date).format("MMM")} ${moment(
    date
  ).format("D")}`,
  value: `${moment(date).format("1/1/YYYY")},${moment(date).format(
    "MM/D/YYYY"
  )}`,
};
const last_month = {
  display: `last month: ${moment(date)
    .subtract(1, "months")
    .format("MMM-YYYY")}`,
  value: `${moment(date).subtract(1, "months").format("MM/1/YYYY")},${moment(
    date
  )
    .subtract(1, "months")
    .endOf("month")
    .format("MM/D/YYYY")}`,
};
const last_year = {
  display: `last year: ${moment(date).subtract(1, "years").format("YYYY")}`,
  value: `${moment(date).subtract(1, "years").format("1/1/YYYY")},${moment(date)
    .subtract(1, "years")
    .format("12/31/YYYY")}`,
};
const last_6_months = {
  display: `last 6 months: ${moment(date)
    .subtract(6, "months")
    .format("MMM-YYYY")} - ${moment(date)
    .subtract(1, "months")
    .format("MMM-YYYY")}`,
  value: `${moment(date).subtract(6, "months").format("MM/1/YYYY")},${moment(
    date
  )
    .subtract(1, "months")
    .endOf("month")
    .format("MM/D/YYYY")}`,
};
const last_12_months = {
  display: `last 12 months: ${moment(date)
    .subtract(12, "months")
    .format("MMM-YYYY")} - ${moment(date)
    .subtract(1, "months")
    .format("MMM-YYYY")}`,
  value: `${moment(date).subtract(12, "months").format("MM/1/YYYY")}, ${moment(
    date
  )
    .subtract(1, "months")
    .endOf("month")
    .format("MM/D/YYYY")}`,
};

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

function sideScroll(element, direction, speed, distance, step) {
  let scrollAmount = 0;
  let slideTimer = setInterval(function () {
    if (direction === "left") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}

const LOADING_DELAY = 250;

const toolsClient = {
  logout,
  checkRequired,
  getFormModel,
  maskPhone,
  getPhoneMask,
  eachWordToUpper,
  validateEmail,
  resizeImage,
  searchArray,
  month_to_date,
  year_to_date,
  last_month,
  last_year,
  last_6_months,
  last_12_months,
  LOADING_DELAY,
  validateUrl,
  sideScroll,
  isVideoPlaying,
  initialCaps,
};
export default toolsClient;
