import toolsClient from "../../Javascript/Tools";

function FeedTags({
  tagData = [],
  myTagIds = [],
  fnRemoveTag,
  fnAddTag,
  fnRemoveAll,
  fnSetDefaults,
  fnClose,
  searchCount,
}) {
  return (
    <div className={``}>
      <div className={`tags-header`}>
        <button
          type="button"
          className={`anchor-button brand-link-color small-font m-right-1 p-right-1 b-right`}
          onClick={() => {
            fnSetDefaults();
          }}
        >
          <i className={`fa-solid fa-filter m-right-025`} />
          My Sports
        </button>
        <button
          type="button"
          className={`anchor-button brand-link-color small-font`}
          onClick={() => {
            fnRemoveAll();
          }}
        >
          <i className={`fa-solid fa-filter-slash m-right-025`} />
          Clear All
        </button>
        <div className={`flex-1 align-right`}>
          <button
            type="button"   
            className={`anchor-button brand-link-color`}        
            onClick={() => {
              fnClose();
            }}
          >{`View ${searchCount} Result${searchCount !==1 ? "s" : ''}`}</button>
        </div>
      </div>

      <div className={`tags-inner`}>
        <div className={`small-font brand-green`}>
          {myTagIds?.length || "None"} Selected
        </div>
        {tagData?.map((tag, index) => {
          let testId = toolsClient.searchArray(tag._id, "_id", myTagIds);
          return (
            <div
              key={`spkey${index}`}
              className={`tag ${
                myTagIds && testId && testId === tag._id ? "selected" : ""
              }`}
              role="button"
              onClick={(e) => {
                if (e.target.classList.contains("selected")) {
                  fnRemoveTag(tag);
                } else {
                  fnAddTag(tag);
                }
                e.target.classList.toggle("selected");
              }}
            >
              {tag.sport}
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default FeedTags;
