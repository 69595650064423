import { useEffect, useState } from "react";
import "../../Css/Utils/Popover.css";

function Popover({
  children,
  title,
  fnClose,
  iconClass,
  scrollSnap = false,
  className,
  headerClass = "",
  closeIcon,
}) {
  const [showPopover, setShowPopover] = useState();

  useEffect(() => {
    setShowPopover(true);
  }, []);

  return (
    <div className={`popover ${showPopover ? "visible" : ""} ${className ? className : ''}`}>
      <div className={`popover-header ${headerClass}`}>
        {iconClass ? (
          <div className="p-right-1 large-font">
            <i className={iconClass} />
          </div>
        ) : null}

        <div className="flex-1 align-left">{title}</div>
        {fnClose ? (
          <div className={`close-button`}>
            <button
              onClick={() => {
                setShowPopover(false);
                setTimeout(() => {
                  fnClose();
                }, 100);
              }}
              className="anchor-button large-font"
            >
              <i className={`${closeIcon ? closeIcon : 'fas fa-times'} `} />
            </button>
          </div>
        ) : null}
      </div>
      <div className={`popover-content ${scrollSnap ? "scrollSnap" : ""}`}>
        {children}
      </div>
    </div>
  );
}
export default Popover;
