import { useEffect, useState } from "react";
import "../../Css/Utils/DialogPop.css";

function DialogPop({ children, title, fnClose, headerIcon = "info" }) {
  const [slideDown, setSlideDown] = useState();
  const [icon, setIcon] = useState();

  useEffect(() => {
    switch (headerIcon) {
      case "info":
        setIcon("fa-info-circle");
        break;
      case "question":
        setIcon("fa-question-circle");
        break;
      case "alert":
        setIcon("fa-exclamation-circle brand-orange");
        break;
      case "edit":
        setIcon("fa-edit");
        break;
      default:
        setIcon("");
    }
    setTimeout(() => {
      setSlideDown(true);
    }, 100);
  }, [headerIcon]);

  return (
    <div className={`dialog-pop-wrapper`}>
      <div className={`dialog-sheen ${slideDown ? "show" : ""}`}></div>
      <div className={`dialog-pop ${slideDown ? "visible" : ""}`}>
        <div className={`dialog-inner`}>
          <div className={`dialog-header`}>
            <i className={`fa-solid ${icon}`} />
            <div className={`p-left-05 align-left flex-1`}>{title}</div>
            <button
              type="button"
              className="anchor-button dialog-close"
              onClick={() => {
                fnClose();
              }}
            >
              <i className={`fa-light fa-times`} />
            </button>
          </div>
          <div className={`dialog-children`}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DialogPop;
