import { useEffect, useState } from "react";

import "../../Css/Home/MainMenu.css";
import UserSettings from "../User/usersettings";
import Slideover from "../Utils/slideover";
import Popover from "../Utils/popover";
import Profile from "../User/profile";
import ReferFriend from "../User/referfriend";
import Subscriptions from "../Subscriptions/subscriptions";
import ActivityDashboard from "../ChannelActivity/activitydashboard";
//import NotificationSettings from "../User/notificationprefs";
import AlertDot from "../Utils/alertdot";
import AxcessAdmin from "../Admin/axcessadmin";
import logo from "../../Graphics/AxcessIconNew.png";
import baseClient from "../../api/Base";
import DeleteUser from "../User/deleteuser";
import PublishChannel from "../User/publishchannel";
import SupportForm from "../Help/supportticket";

function MainMenu({ fnClose }) {
  /* const [selectedPage, setSelectedPage] = useState("home"); */
  const [showSettingsMenu, setShowSettingsMenu] = useState();
  const [showUserSettings, setShowUserSettings] = useState();
  const [showProfile, setShowProfile] = useState();
  const [showReferFriend, setShowReferFriend] = useState();
  const [showSubscriptions, setShowSubscriptions] = useState();
  const [showActivity, setShowActivity] = useState();
  const [showSupportForm, setShowSupportForm] = useState();
  //const [showNotifications, setShowNotifications] = useState();
  const [showAxcessAdmin, setShowAxcessAdmin] = useState();
  const [showDeleteUser, setShowDeleteUser] = useState();
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const [subscribeCount, setSubscribeCount] = useState();
  const [reloadSubscriptions, setReloadSubscriptions] = useState();
  const [reloadAppOnClose, setReloadAppOnClose] = useState();
  const [isAxcessAdmin, setIsAxcessAdmin] = useState(false);
  const [mode, setMode] = useState("");
  const [isCreator, setIsCreator] = useState();
  const [isApprovedCreator, setIsApprovedCreator] = useState();
  const [showShareChannel, setShowShareChannel] = useState();
  const [fadeIn, setFadeIn] = useState("");
  const [publishStatus, setPublishStatus] = useState();
  const [showPublish, setShowPublish] = useState();
  const [refreshAll, setRefreshAll] = useState();
  const [showRefreshSpinner, setShowRefreshSpinner] = useState();
  const [showPublishAlert, setShowPublishAlert] = useState();

  const refreshDoc = () => {
    try {
      // eslint-disable-next-line no-undef
      window.gonative.webview.clearCache();
    } catch (err) {
      //baseClient.serverLogger(err.message);
      console.log(err.message);
    }

    document.location = "/";
  };

  useEffect(() => {
    const getUser = async () => {
      const tempUser = await JSON.parse(localStorage.getItem("user"));      
      if (!tempUser) {       
        document.location.replace("/welcome");
        return;
      }
      let model = {};
      const myProfile = await baseClient.APIPost({
        model: model,
        api: "myprofile",
      });
      if (myProfile && myProfile.data) {
        localStorage.setItem("user", JSON.stringify(myProfile.data));
        setUser(myProfile.data);
        setUserId(myProfile.data._id);
        setRefreshAll(null);
        setPublishStatus(myProfile.data.publish_status);
        if (myProfile.data.app_role === "creator") {
          setIsCreator(true);
          if (myProfile.data.publish_status === "published") {
            setIsApprovedCreator(true);
          }
        }
      }
      setFadeIn("fade-in");

      // test if new version of app
      await baseClient.isAppRefreshNeeded();
    };
    if (!user || refreshAll) {
      getUser();
      setShowSettingsMenu(true);
    }
  }, [refreshAll, user]);

  useEffect(() => {
    //fetch if is axcess admin
    try {
      const testAxcessAdmin = async () => {
        let model = [];
        const result = await baseClient.APIPost({
          model: model,
          api: "testaxadmin",
        });
        if (result && result.data) {
          setIsAxcessAdmin(result.data);
        }
        if (result && result.mode) {
          setMode(result.mode);
        }
      };
      testAxcessAdmin();
    } catch (err) {
      console.error(err.message);
    }
  }, []);

  useEffect(() => {
    const getSubscriptionCount = async () => {
      try {
        let model = [];
        const count = await baseClient.APIPost({
          model: model,
          api: "countmysubscriptions",
        });

        if (count) {
          setTimeout(() => {
            setSubscribeCount(parseInt(count.data));
          }, 500);
          if (reloadSubscriptions) {
            setReloadSubscriptions(null);
            setReloadAppOnClose(true);
          }
        } else {
          console.error("No data retured from server for subscription count.");
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    getSubscriptionCount();
  }, [reloadSubscriptions]);

  useEffect(() => {
    // check for alerts
    if (user) {
      setShowPublishAlert(null);
      console.log('check for alerts')
      if (user.app_role === "creator") {
        if (user.publish_status === "none") {
          setShowPublishAlert(true);
        }
      }
    }
  }, [user]);

  return (
    <div
      className={`main-menu settings-block ${
        showSettingsMenu ? "visible" : ""
      }`}
    >
      {showAxcessAdmin ? (
        <Slideover
          title="Axcess Admin"
          fnClose={() => {
            setRefreshAll(true);
            setShowAxcessAdmin(null);
          }}
        >
          <AxcessAdmin userId={user?._id} />
        </Slideover>
      ) : null}
      {/* {showNotifications ? (
        <Slideover
          title="Notification Preferences"
          fnClose={() => {
            setShowNotifications(null);
          }}
        >
          <NotificationSettings />
        </Slideover>
      ) : null} */}

      {showActivity ? (
        <Slideover
          title="My Channel Activity"
          className={`activity-top`}
          fnClose={() => {
            setShowActivity(null);
          }}
        >
          <ActivityDashboard />
        </Slideover>
      ) : null}

      {showShareChannel ? (
        <Slideover
          title="Share My Channel"
          fnClose={() => {
            setShowShareChannel(null);
          }}
        >
          <ReferFriend type="channel" creatorId={userId} />
        </Slideover>
      ) : null}

      {showReferFriend ? (
        <Slideover
          title="Refer a Friend"
          fnClose={() => {
            setShowReferFriend(null);
          }}
        >
          <ReferFriend />
        </Slideover>
      ) : null}

      {showSubscriptions ? (
        <Slideover
          title="My Subscriptions"
          fnClose={() => {
            setRefreshAll(true);
            setShowSubscriptions(null);
          }}
        >
          <Subscriptions
            fnRefresh={() => {
              setReloadSubscriptions(true);
            }}
          />
        </Slideover>
      ) : null}

      {showProfile ? (
        <Slideover
          title="My Channel"
          fnClose={() => {
            setRefreshAll(true);
            setShowProfile(null);
          }}
          scrollSnap={true}
        >
          <Profile 
            creatorId={user?._id}            
          />
        </Slideover>
      ) : null}

      {showPublish ? (
        <Popover
          title={`${
            showPublish === "true" ? "Publish Channel" : "Unpublish Channel"
          }`}
          fnClose={() => {
            setRefreshAll(true);
            setShowPublish(null);
          }}
          iconClass={`fa-light fa-rss`}
        >
          <PublishChannel
            publish={"false"}
            userId={userId}
            fnClose={() => {
              setRefreshAll(true);
              setShowPublish(null);
            }}
          />
        </Popover>
      ) : null}

      {showDeleteUser ? (
        <Popover
          title="Delete User"
          fnClose={() => {
            setShowDeleteUser(null);
          }}
        >
          {user.app_role === "creator" && user.publish_status !== "none" ? (
            <div className={`p-1`}>
              <div>Hi {user.first_name}</div>
              <p>
                You are an approved creator. Before you can delete your account,
                you must first UNPUBLISH your channel.
              </p>
              <div className={`p-1`}>
                <button
                  type="button"
                  className={`m-05 button-cancel`}
                  onClick={() => {
                    setShowDeleteUser(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`m-05 bg-brand-red`}
                  onClick={() => {
                    setShowPublish(true);
                    setShowDeleteUser(null);
                  }}
                >
                  Unpublish
                </button>
              </div>
            </div>
          ) : (
            <DeleteUser
              user={user}
              fnClose={() => {
                setShowDeleteUser(null);
              }}
            />
          )}
        </Popover>
      ) : null}

      {showUserSettings ? (
        <UserSettings
          fnClose={async () => {
            const tempUser = await JSON.parse(localStorage.getItem("user"));
            setUser(tempUser);
            setShowUserSettings(false);
          }}
        />
      ) : null}
      {showSupportForm ? (
        <Slideover
          title="Submit a Support Ticket"
          className={`support-slider`}
          fnClose={() => {
            setShowSupportForm(null);
          }}
        >
          <SupportForm
            fnClose={async () => {
              setShowSupportForm(false);
            }}
          />
        </Slideover>
      ) : null}

      <div className="main-menu-header">
        <button
          onClick={() => {
            setShowSettingsMenu(null);
            setTimeout(() => {
              if (reloadAppOnClose) {
                document.location = "/";
              } else {
                fnClose();
              }
            }, 100);
          }}
          className="anchor-button back-button"
        >
          <i className="fa-light fa-arrow-left" />
        </button>

        <span className="flex-1 align-left">
          Main Menu
          {mode !== "production" ? (
            <span className={`app-mode m-left-05`}>{`${mode}`}</span>
          ) : null}
        </span>
        <button
          className={`anchor-button brand-link-color m-right-05`}
          onClick={() => {
            setShowSupportForm(true);
          }}
        >
          Support
          <i className={`fa fa-question-circle m-left-025`} />
        </button>
      </div>
      <div className={`fade ${fadeIn} main-menu-body`}>
        <ul className={`main-menu-section block`}>
          <li
            onClick={() => {
              setShowUserSettings(true);
            }}
            role="button"
          >
            {/* <AlertDot top={5} right={5} color="orange" /> */}
            <div className="m-right-1">
              {user?.avatar ? (
                <img
                  className="header-avatar large-avatar"
                  src={user.avatar_url}
                  alt="av"
                />
              ) : (
                <div className={`header-avatar large-avatar place-holder`}>
                  <i className={`fa-light fa-user`} />
                </div>
              )}
            </div>
            <div className="flex-1">
              {user?.first_name}
              <div className="small-font">@{user?.nickname}</div>
            </div>

            <span className={`m-right-05`}>Edit Profile</span>
            <div className="menu-icon chevron">
              <i className="fa-light fa-chevron-right" />
            </div>
          </li>
        </ul>
        <div className="section-header">features</div>
        <ul className={`main-menu-section block`}>
          <li
            role="button"
            onClick={() => {
              setShowProfile(true);
            }}
          >
            <div className="menu-icon">
              <i className="fa-light fa-user" />
            </div>
            {showPublishAlert ? (
              <AlertDot top={5} left={5} color="orange" />
            ) : null}

            <div className="flex-1">
              My Channel
              {isCreator ? (
                <span
                  className={`small-font m-left-05 ${
                    isApprovedCreator
                      ? "brand-green"
                      : publishStatus === "none"
                      ? "brand-orange"
                      : "muted-text"
                  }`}
                >
                  {publishStatus === "none"
                    ? "NOT PUBLISHED"
                    : publishStatus === "requested"
                    ? "SUBMITTED"
                    : publishStatus === "approved"
                    ? "APPROVED"
                    : "LIVE"}
                </span>
              ) : null}
            </div>
            <div className="menu-icon chevron">
              <i className="fa-light fa-chevron-right" />
            </div>
          </li>
          {isApprovedCreator ? (
            <li
              onClick={() => {
                setShowActivity(true);
              }}
              role="button"
            >
              <div className="menu-icon">
                <i className="fa-light fa-pie-chart" />
              </div>
              <div className="flex-1">My Channel Activity</div>
              <div className="menu-icon chevron">
                <i className="fa-light fa-chevron-right" />
              </div>
            </li>
          ) : null}

          {isApprovedCreator ? (
            <li
              onClick={() => {
                setShowShareChannel(true);
              }}
              role="button"
            >
              <div className="menu-icon">
                <i className="fa-solid fa-up-from-bracket" />
              </div>
              <div className="flex-1">Share My Channel</div>
              <div className="menu-icon chevron">
                <i className="fa-light fa-chevron-right" />
              </div>
            </li>
          ) : null}
          <li
            onClick={() => {
              setShowReferFriend(true);
            }}
            role="button"
          >
            <div className="menu-icon">
              <i className="fa-solid fa-up-from-bracket" />
            </div>
            <div className="flex-1">Refer a Friend to Axcess</div>
            <div className="menu-icon chevron">
              <i className="fa-light fa-chevron-right" />
            </div>
          </li>
        </ul>
        <div className="section-header">settings</div>
        <ul className={`main-menu-section block`}>
          {/*  <li
            onClick={() => {
              setShowNotifications(true);
            }}
            role="button"
          >
            <div className="menu-icon">
              <i className="fa-light fa-bell" />
            </div>
            <div className="flex-1">Notification Preferences</div>
            <div className="menu-icon chevron">
              <i className="fa-light fa-chevron-right" />
            </div>
          </li> */}
          <li
            onClick={() => {
              setShowSubscriptions(true);
            }}
            role="button"
          >
            <div className="menu-icon">
              <i className="fa-light fa-rss" />
            </div>
            <div className="flex-1">
              My Subscriptions{" "}
              {subscribeCount || subscribeCount > -1 ? (
                `(${subscribeCount})`
              ) : (
                <i
                  className="fa fa-spinner fa-spin small-font"
                  style={{ margin: 0 }}
                />
              )}
            </div>
            <div className="menu-icon chevron">
              <i className="fa-light fa-chevron-right" />
            </div>
          </li>
          <li
            onClick={() => {
              setShowRefreshSpinner(true);
              refreshDoc();
            }}
            role="button"
          >
            <div className="menu-icon">
              <i
                className={`fa-light fa-refresh ${
                  showRefreshSpinner ? "fa-spin brand-gold" : ""
                }`}
              />
            </div>
            <div className="flex-1">Refresh App</div>
          </li>
          {isAxcessAdmin ? (
            <li
              onClick={() => {
                setShowAxcessAdmin(true);
              }}
              role="button"
            >
              <div className="menu-icon logo-icon">
                <img src={logo} alt="logo" />
              </div>
              <div className="flex-1">Axcess Admin</div>
              <div className="menu-icon chevron">
                <i className="fa-light fa-chevron-right" />
              </div>
            </li>
          ) : null}
        </ul>

        <ul className="main-menu-section block logout m-bottom-1">
          <li
            onClick={() => {
              document.location.replace("/welcome");
            }}
            role="button"
          >
            <div className="menu-icon">
              <i className="fa-light fa-sign-out" />
            </div>
            <div className="flex-1">Logout</div>
          </li>
        </ul>

        <div className={`align-left m-top-1 p-left-1 p-bottom-1 m-bottom-1`}>
          <button
            className={`anchor-button brand-link-color m-top-1 small-font m-right-05 underline`}
            onClick={() => {
              setShowDeleteUser(true);
            }}
          >
            Delete My Account
          </button>
        </div>
      </div>
    </div>
  );
}
export default MainMenu;
