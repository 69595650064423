import { useState } from "react";
import { useEffect } from "react";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";
import "../../Css/Welcome/SportsPicker.css";
import Loading from "../Utils/loading";

function SportsPicker({ fnDone, className = "", editMode = false }) {
  const [sports, setSports] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [fadeIn, setFadeIn] = useState("");
  const [appRole, setAppRole] = useState("");
  const [mySports, setMySports] = useState([]);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState();

  const toggleMySports = (item) => {
    let mySportIndex = mySports.indexOf(item._id);
    let tempArray = [];
    for (let i = 0; i < mySports.length; i++) {
      tempArray.push(mySports[i]);
    }
    if (mySportIndex > -1) {
      tempArray.splice(mySportIndex, 1);
    } else {
      tempArray.push(item._id);
    }
    setMySports(tempArray);
  };

  const updateUserSports = async () => {
    try {
      const model = {
        user_id: userId,
        sports: mySports,
      };

      const response = await baseClient.APIPost({
        model: model,
        api: "updateuser",
      });
      if (response && response.data) {
        // set local storate with current user data
        localStorage.setItem("user", JSON.stringify(response.data));       
        fnDone(response.data.sports);
      } else {
        setLoading(false);
        setErrorMsg({
          message: "Something went wrong.",
          title: "Oops",
        });
        console.error("No data retured from server for new user.");
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong.",
        title: "Oops",
      });
      console.error("Something went wrong saving new user.");
    }
  };

  useEffect(() => {
    try {
      const getSports = async () => {
        try {
          const myUser = await JSON.parse(localStorage.getItem("user"));
          setAppRole(myUser.app_role);
          setUserId(myUser._id);
          if (editMode) {
            let tempSports = [];
            for (let i = 0; i < myUser.sports.length; i++) {
              tempSports.push(myUser.sports[i]._id);
            }
            setMySports(tempSports);
          }

          let model = [];
          const sportsList = await baseClient.APIPost({
            model: model,
            api: "sports",
          });
          if (sportsList && sportsList.data) {
            setSports(sportsList.data);
            setFadeIn("fade-in");
          } else {
            console.error("No data retured from server for sports list.");
          }
        } catch (err) {
          setErrorMsg({
            message: "Something went wrong gettin sports list.",
            title: "Oops",
          });
          console.error(err.message);
        }
      };
      if (sports.length === 0) {
        getSports();
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [sports, editMode]);

  return (
    <div className={`sports-picker m-top-1 ${className}`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`fade ${fadeIn}`}>
        <div className={`p-left-1 p-right-1`}>
          {appRole === "fan" ? (
            <div>Pick sports you're interested in following.</div>
          ) : (
            <div>Sports you're involved with or represent.</div>
          )}
          <div className={`small-font muted-text m-bottom-025`}>
            You can always change these later.
          </div>
        </div>
        <div className={`p-bottom-05`}>
          <span className={`m-right-05`}>{mySports.length}</span>chosen.
        </div>
        <ul className={`${editMode ? "edit-mode" : ""}`}>
          {sports.map((item, index) => {
            return item.sport.length > 0 ? (
              <li key={`sportitem${index}`}>
                <button
                  type="button"
                  className={mySports.indexOf(item._id) > -1 ? "select" : ""}
                  onClick={() => {
                    toggleMySports(item);
                  }}
                >
                  <span className={``}>{item.sport}</span>
                  {mySports.indexOf(item._id) > -1 ? (
                    <i
                      className={`fa-light fa-square-check flex-1 align-right m-left-1 large-font`}
                    />
                  ) : (
                    <i
                      className={`fa-light fa-square flex-1 align-right m-left-1 large-font`}
                    />
                  )}
                </button>
              </li>
            ) : null;
          })}
        </ul>
        <div className={`m-top-05`}>
          {editMode ? (
            <button
              type="button"
              className={`button-cancel m-right-1`}
              onClick={() => {
                fnDone();
              }}
            >Cancel</button>
          ) : null}
          <button
            type="button"
            className={`button-save`}
            disabled={mySports.length === 0}
            onClick={() => {
              updateUserSports();
            }}
          >
            {editMode ? (
              <span>Save</span>
            ) : (
              <span>
                Next
                <i className={`fa-solid fa-caret-right m-left-05`} />
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
export default SportsPicker;
