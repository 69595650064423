import { useEffect, useRef, useState } from "react";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";

function ReasonForm({ id, fnClose }) {
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();
  const [reasonId, setReasonId] = useState(id && id !== "new" ? id : null);
  const [reason, setReason] = useState();
  const [icon, setIcon] = useState();
  const [active, setActive] = useState(false);
  const [fadeIn, setFadeIn] = useState("");
  const reasonForm = useRef(null);

  const upsertProduct = async () => {
    try {
      setLoading(true);
      let model;
      model = await toolsClient.getFormModel(
        reasonForm.current,
        model,
        setLoading
      );
      if (!model) {
        // bail out
        setLoading(false);
        return;
      }
      model.reasonId = reasonId;
      model.active = active;
      const result = await baseClient.APIPost({
        model: model,
        api: "upsertreason",
      });
      if (!result || !result.code === 200) {
        setLoading(false);
        setErrorMsg({
          message: `Error saving reason. Code: ${result?.code}`,
          title: "Oops",
        });
        return;
      }
      setReasonId(result.data._id);
      setTimeout(() => {
        setLoading(false);
        fnClose();
      }, toolsClient.LOADING_DELAY);
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong saving reason.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    const getReason = async () => {
      try {
        setLoading(true);
        const model = {
          reasonId: id,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "getreasons",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting reason. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        const reasonData = result.data[0];
        setReason(reasonData);
        setType(reasonData.reason_type);
        console.log(reasonData.icon)
        setIcon(reasonData.icon);
        setActive(reasonData.active ? true : false);
        setTimeout(() => {
          setLoading(false);
          setFadeIn("fade-in");
        }, toolsClient.LOADING_DELAY);
      } catch (err) {
        setErrorMsg({
          message: `Something went wrong getting reason. ${err.mesage}`,
          title: "Oops",
        });
        console.error(err.message);
      }
    };
    if (id && id !== "new") {
      getReason();
    } else {
      setFadeIn("fade-in");
    }
  }, [id]);

  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`p-1 fade ${fadeIn}`}>
        <div className={`align-center`}>
          <h2>Reason Form</h2>
          <div>For Tips, Shoutouts and Live Feeds</div>
        </div>
        <form ref={reasonForm} className={`m-top-1`}>
          <label htmlFor="type">
            <div>Reason Type</div>
            <div>
              <select
                id="type"
                name="type"
                value={type || "tip"}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                autoFocus
              >
                <option value="shoutout">Shoutout</option>
                <option value="tip">Tip</option>
              </select>
            </div>
          </label>
          <label htmlFor="reason">
            <div>Reason Name</div>
            <div>
              <input
                id="reason"
                name="reason"
                type="text"
                defaultValue={reason?.reason}
                required
                placeholder="Reason Name"
              />
            </div>
          </label>
          <label htmlFor="icon">
            <div>Icon</div>
            <div>
              <input
                id="icon"
                name="icon"
                type="text"
                defaultValue={icon ? icon : null}                
                placeholder="Icon"
              />
            </div>
          </label>
          <label htmlFor="active">
            <div>Active</div>
            <div className={`align-left`}>
              <input
                id="active"
                name="active"
                checked={active ? true : false}
                type="checkbox"
                style={{ width: "unset" }}
                onChange={() => {
                  setActive((prev) => !prev);
                }}
              />
            </div>
          </label>
        </form>
        <div className={`m-top-1 p-1 align-center`}>
          <button
            type="button"
            className={`button-cancel m-025`}
            onClick={() => {
              fnClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`button-save m-025`}
            onClick={() => {
              upsertProduct();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default ReasonForm;
