import { useEffect, useState } from "react";
import "../../Css/Admin/AxcessAdmin.css";
import AdminFunctions from "./adminfunctions";
import Reported from "./reported";
import UsersTable from "./userstable";
import SupportTickets from "./supporttickets";
import baseClient from "../../api/Base";
function AxcessAdmin({ userId }) {
  const [tab, setTab] = useState("users");
  const [ticketCount, setTicketCount] = useState();
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() => {
    // count support tickets
    const countTickets = async () => {
      try {
        setRefreshData(null);
        const model = {};
        const result = await baseClient.APIPost({
          model: model,
          api: "counttickets",
        });
        setTicketCount(result.data);
      } catch (err) {
        console.error(err.message);
      }
    };
    if (refreshData) {
      countTickets();
    }
  }, [refreshData]);

  return (
    <div className={`axcess-admin`}>
      <div className={`tabs`}>
        <div className={`tab ${tab === "users" ? "active-tab" : ""}`}>
          <button
            type="button"
            className={`anchor-button relative`}
            onClick={() => {
              setTab("users");
            }}
          >
            Users
          </button>
        </div>

        <div className={`tab ${tab === "reported" ? "active-tab" : ""}`}>
          <button
            type="button"
            className={`anchor-button relative`}
            onClick={() => {
              setTab("reported");
            }}
          >
            Reports{/* <span className={`counter`}>4</span> */}
          </button>
        </div>
        <div className={`tab ${tab === "support" ? "active-tab" : ""}`}>
          <button
            type="button"
            className={`anchor-button relative`}
            onClick={() => {
              setTab("support");
            }}
          >
            Support <sup className={`brand-gold`}>{ticketCount}</sup>
          </button>
        </div>
        <div className={`tab ${tab === "admin" ? "active-tab" : ""}`}>
          <button
            type="button"
            className={`anchor-button relative`}
            onClick={() => {
              setTab("admin");
            }}
          >
            <i className={`fa fa-cog m-right-025`} />
            Admin
          </button>
        </div>
      </div>
      <div className={`m-top-1 align-left`}>
        {tab === "users" ? <UsersTable userId={userId} /> : null}
        {tab === "reported" ? <Reported /> : null}
        {tab === "admin" ? <AdminFunctions /> : null}
        {tab === "support" ? (
          <SupportTickets
            fnRefresh={(val) => {
              setRefreshData(val);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
export default AxcessAdmin;
