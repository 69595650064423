import { useEffect, useState } from "react";
import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";

function ApproveCreator({ userRec = [], fnClose }) {
  const [appleGroupId, setAppleGroupId] = useState();
  const [appleSubscriptionId, setAppleSubscriptionId] = useState();
  const [saveStep, setSaveStep] = useState(1);
  const [appleSubscriptionURL, setAppleSubscriptionURL] = useState();
  const [finishDisabled, setFinishDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const savePublish = async () => {
    try {
      setLoading(true);
      const model = {
        user_id: userRec._id,
        step: saveStep,
        subscriptionId: appleSubscriptionId,
        promotional: userRec.promotional || false,
      };
      const updatedUser = await baseClient.APIPost({
        model: model,
        api: "approvepublish",
      });
      if (updatedUser && updatedUser.data) {
        setTimeout(() => {
          setLoading(false);
          userRec.publish_status = "approved";
          setAppleGroupId(updatedUser.appleGroupId);
          setAppleSubscriptionId(updatedUser.appleSubscriptionId);
          setAppleSubscriptionURL(updatedUser.appleURL);
          if (saveStep === 2) {
            fnClose();
          }
          setSaveStep(2);
        }, toolsClient.LOADING_DELAY);
      } else {
        setLoading(false);
        setErrorMsg({
          message: `Something went wrong. ${
            updatedUser.message || "No messages"
          }`,
          title: "Oops",
        });
        console.error("No data retured from server for approved channel.");
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong approving channel.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (userRec.promotional) {
      setFinishDisabled(false);
    }
  }, [userRec]);
  
  return (
    <div className={`align-center m-top-1`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg?.title || "Error"}
          message={errorMsg?.message || "Something went wrong"}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`larger-font`}>Are you sure?</div>
      <p>
        Confirm approval for <br />
        {userRec?.first_name} {userRec?.last_name}.
      </p>
      {!userRec.promotional && appleGroupId ? (
        <div className={`p-1`}>
          <div className={`bold`}>Apple Group Id:</div>
          <div>{appleGroupId}</div>
          <div className={`bold`}>Apple Subscription Id:</div>
          <div>{appleSubscriptionId}</div>
          <div className={`p-top-1`}>NEXT STEP:</div>
          <div>
            You need to go to Apple's App Store Connect and manually create the
            price record in the subscription for the price $
            {userRec.subscribe_price?.price} / month.
          </div>
          <div className={`p-top-1`}>
            <a
              href={appleSubscriptionURL}
              rel="noreferrer"
              target="_blank"
              className={`brand-gold`}
            >
              Click here to open Apple subscription.
            </a>
          </div>
          <div className={`p-top-1`}>
            <label htmlFor="pricecheck">
              <input
                id="pricecheck"
                type="checkbox"
                className={`m-right-05`}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFinishDisabled(false);
                  } else {
                    setFinishDisabled(true);
                  }
                }}
              />
              Price record has been completed.
            </label>
          </div>
        </div>
      ) : null}
      {saveStep === 1 ? (
        <div>
          {loading ? (
            <div className={`p-1`}>
              <i className={`fa fa-spinner fa-spin m-right-05`} />
              This may take a minute.
            </div>
          ) : (
            <div>
              <button
                type="button"
                className={`button-cancel m-05`}
                onClick={() => {
                  fnClose();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`button-save m-05`}
                onClick={() => {
                  savePublish();
                }}
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button
            type="button"
            className={`button-save m-05`}
            onClick={() => {
              savePublish();
            }}
            disabled={finishDisabled}
          >
            Finish
          </button>
        </div>
      )}
    </div>
  );
}
export default ApproveCreator;
