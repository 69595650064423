import { useEffect } from "react";
import { useState } from "react";
import "../../Css/User/ReferFriend.css";
import logo from "../../Graphics/AxcessIconNew.png";
import baseClient from "../../api/Base";

function ReferFriend({ type = "friend", creatorId }) {
  const [copyConfirm, showCopyConfirm] = useState();
  const [shareLink, setShareLink] = useState("https://axcessnetwork.com/");
  const [msgText, setMsgText] = useState("Check out Axcess Athletes World");

  const logShare = async () => {
    const model = {
      user_id: creatorId,
      activity: "shared",
    };
    await baseClient.APIPost({
      model: model,
      api: "useractivity",
    });
  };

  useEffect(() => {
    if (creatorId) {
      setShareLink(`${document.location.href}invite?r=${creatorId}`);
      setMsgText(`Follow me to get exclusive content on my Axcess Channel.`);
    }
  }, [creatorId]);

  return (
    <div className={`refer-friend`}>
      <div className={`center m-bottom-1 m-top-1`}>
        <img className="medium-logo" src={logo} alt="logo" />
      </div>
      <div className={`m-bottom-05 p-top-1`}>
        {type === "friend" ? (
          <span>Tell your friends about Axcess!</span>
        ) : (
          <span>Share your channel with your posse.</span>
        )}
      </div>
      <div className="p-1 m-top-1">
        <button
          type="button"
          onClick={() => {
            navigator.clipboard.writeText(shareLink);
            showCopyConfirm(true);
            setTimeout(() => {
              showCopyConfirm(null);
            }, 1000);
            logShare();
          }}
        >
          <i className={`fa-light fa-link m-right-05`} />
          Copy Link
          <i
            className={`fa-solid fa-check-circle copy-confirm ${
              copyConfirm ? "show-confirm" : ""
            }`}
          />
        </button>
      </div>
      <div>or</div>
      <div className="p-1">
        <button
          type="button"
          onClick={async () => {
            const shareData = {
              url: shareLink,
              text: msgText,
              title: "Axcess",
            };
            try {
              await navigator.share(shareData);
              logShare();
            } catch {
              //do nothing
            }
          }}
        >
          <i className={`fa-solid fa-up-from-bracket m-right-05`} />
          Share
        </button>
      </div>
    </div>
  );
}
export default ReferFriend;
