import "./Css/App.css";
import "./Css/Variables.css";
import "./Css/Tools.css";
//import "plyr/dist/plyr.css";
import { lazy } from "react";
import Home from "./Components/Home/home";
import SessionExpired from "./Components/Welcome/sessionexpired";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const InviteLanding = lazy(() => import("./Components/Welcome/invitelanding"));
const ValidateEmail = lazy(() => import("./Components/Welcome/validateemail"));
const Welcome = lazy(() => import("./Components/Welcome/welcome"));
const SubscribeReceipt = lazy(() =>
  import("./Components/Subscriptions/subscribereceipt")
);
const PurchaseReceipt = lazy(() =>
  import("./Components/Subscriptions/purchasereceipt")
);

function App() {
  const vPath = document.location.pathname;

  if (
    (vPath === "/" || vPath === "" || !vPath) &&
    !JSON.parse(localStorage.getItem("token"))
  ) {
    return (
      <div className={`app`}>
        <div className={`app-inner`}>
          <Welcome />
        </div>
      </div>
    );
  }

  return (
    <div className={`app`}>
      <div className={`app-inner`}>
        <Router>
          <Routes>
            <Route path="/subscribereceipt" element={<SubscribeReceipt />} />
            <Route path="/purchasereceipt" element={<PurchaseReceipt />} />
            <Route path="/sessionexpired" element={<SessionExpired />} />
            <Route path="/invite" element={<InviteLanding />} />
            <Route path="/validateemail" element={<ValidateEmail />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
