import "../../Css/User/ProfileMini.css";

function ProfileMini({ creator, fnSubscribe, fnShowTip, fnLoaded }) {
  return (
    <div className={`profile-mini`}>
      <div className={`hero`}>
        <div className="hero-header">
          {/*  <div
            className={`hero-image`}
            style={{
              backgroundImage: `url(${creator.hero_image_url})`,
            }}
          ></div>
          <div></div> */}
          {creator.avatar_url &&
          creator.avatar_url.indexOf("undefined") === -1 ? (
            <img
              className={`hero-avatar`}
              src={creator.avatar_url}
              alt="avatar"
              onLoad={() => {
                console.log("minageloaded");
                fnLoaded(true);
              }}
            />
          ) : (
            <i className={`fa-solid fa-user hero-avatar avatar-placeholder`} />
          )}
        </div>
        <div className="profile-description">
          <h1>{`${creator.first_name} ${creator.last_name}`}</h1>
          <div>{creator.organization}</div>
          <div className={`tags`}>
            {creator.sports?.map((item, index) => {
              return (
                <span key={`csp${index}`} className={`tag`}>
                  {item.sport}
                </span>
              );
            })}
          </div>
          <div>{creator.org_role}</div>
        </div>
        <div className="profile-actions">
          <button
            type="button"
            className={`button-save m-05`}
            onClick={() => {
              fnSubscribe(creator);
            }}
          >
            <i className={`fa fa-unlock m-right-05`} />
            {creator.promotional ? "FREE" : "Unlock"}
          </button>
          {creator.promotional ? null : (
            <button
              type="button"
              className={`button-save m-05`}
              onClick={() => {
                fnShowTip(creator);
              }}
            >
              <i className={`fa fa-dollar m-right-05`} />
              Tip
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default ProfileMini;
