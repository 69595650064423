import authHeader from "./AuthHeader";

const checkIfPathNeededValidToken = () => {
  const path = document.location.pathname;
  //console.log(`path: ${path}`)
  const exemptRoutes = [
    "/welcome",
    "/invite",
    "/tempcode",
    "/emailvalidate",
    "/validateemail",
    "/productsApple.json",
    "/appleserverpost",
    "/apple-app-site-association",
    "/.well-known/apple-app-site-association",
    "/home",
    "/mode"
  ];
  let tokenValidationNotNeeded = false;
  if (exemptRoutes.indexOf(path) > -1) {
    tokenValidationNotNeeded = true;
  }
  return tokenValidationNotNeeded;
};

const APIPost = async ({ model, api }) => {
  let myHeaders = await authHeader();
  let tokenValidationNotNeeded = false;
  myHeaders = {
    ...myHeaders,
    "Content-Type": "application/json",
  };

  const logitout = () => {
    console.log("Invalid or expired token.");
    document.location.replace("/sessionexpired");
  };

  tokenValidationNotNeeded = checkIfPathNeededValidToken();

  if (
    (myHeaders && myHeaders["x-access-token"] !== undefined) ||
    tokenValidationNotNeeded
  ) {
    const response = await fetch(`/${api}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(model),
    });
    const data = await response.json();
    if (
      data &&
      data.code &&
      data.code !== 200 &&
      data.code !== 201 &&
      data.code !== 202
    ) {
      console.log(`code: ${data.code}: ${data.message}`);
      if (data.code === 401) {
        // unathorized or expired token

        logitout();
        return null;
      } else {
        return data;
      }
    } else {
      return data;
    }
  } else {
    logitout();
    return null;
  }
};

async function serverLogger(message) {
  const model = {
    message: message,
  };
  await APIPost({
    model: model,
    api: "serverlogger",
  });
}

async function isAppRefreshNeeded() {
  const model = {};
  const result = await baseClient.APIPost({
    model: model,
    api: "checkapprefresh",
  });
  if (result && result.code === 200) {
    alert("There's a newer version of Axcess.\nThe app will refresh.");
    try {
      // eslint-disable-next-line no-undef
      window.gonative.webview.clearCache();
    } catch {
      // do nothing
    }
    document.location.reload();
  }
  return false;
}

const baseClient = {
  //loginUser,

  APIPost,
  serverLogger,
  isAppRefreshNeeded,
};
export default baseClient;
