import { useEffect, useCallback } from "react";
import { useState } from "react";
import "../../Css/Feed/Feed.css";
import Popover from "../Utils/popover";
import ReportContent from "./reportcontent";
import DeleteContent from "./deletecontent";
import moment from "moment";
import Profile from "../User/profile";
import ErrorMsg from "../Utils/errormsg";
import DialogPop from "../Utils/dialogpop";
import EditFeed from "./EditFeed";
import BottomPop from "../Utils/bottompop";
import FeedSlides from "./feedslides";
import PublishChannel from "../User/publishchannel";

function Feed({
  likes,
  posts,
  refreshData,
  snapped,
  setSnapped,
  globalMute,
  setGlobalMute,
  filterId,
  setFilterId,
  isScrolling,
  setPageNow,
}) {
  const [contentItem, setContentItem] = useState();
  const [creatorId, setcreatorId] = useState();
  const [athleteName, setAthleteName] = useState();
  const [deleteItemRow, setDeleteItemRow] = useState();
  const [description, setDescription] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [userId, setUserId] = useState();
  const [publishMessage, setPublishMessage] = useState();
  const [showConfirmZone, setShowConfirmZone] = useState(false);
  const [showPublish, setShowPublish] = useState();
  const [publishStatus, setPublishStatus] = useState();

  /** for toggling edit form popup */
  const [showEditForm, setShowEditForm] = useState({
    item: {},
    showForm: false,
  });
  const pullFromDOM = (item) => {
    // pull feed item from DOM after reported content
    refreshData(true);
    /*     let ids = [];
    for (let i = 0; i < posts.length; i++) {
      ids.push(posts[i]._id);
    }
    let index = ids.indexOf(item);
    if (index > -1) {
      posts.splice(index, 1);
      setDescription(null);
    }
    setContentItem(null); */
  };

  const toggleAthlete = (vId, vName) => {
    setcreatorId(vId);
    setAthleteName(vName);
  };

  const reportBadContent = useCallback(
    (item) => {
      if (contentItem) {
        setContentItem(null);
      } else {
        setGlobalMute(true);
        setContentItem(item._id);
      }
    },
    [contentItem, setGlobalMute]
  );

  const deleteItem = useCallback(
    (item) => {
      if (deleteItemRow) {
        setDeleteItemRow(null);
      } else {
        setDeleteItemRow(item);
      }
    },
    [deleteItemRow]
  );

  const toggleDescription = useCallback(
    (obj) => {
      if (description) {
        setDescription(null);
      } else {
        setDescription(obj);
      }
    },
    [description]
  );

  useEffect(() => {
    const setMyUser = async () => {
      const tempUser = await JSON.parse(localStorage.getItem("user"));
      setUserId(tempUser._id);
      setPublishStatus(tempUser.publish_status);
      console.log(tempUser.publish_status);
    };
    setMyUser();
  }, [publishStatus]);

  return (
    <div className="feed">
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {showPublish ? (
        <Popover
          title={
            publishStatus !== "none" ? "Unpublish Channel" : "Publish Channel"
          }
          fnClose={() => {
            refreshData(true);
            setShowPublish(null);
          }}
          iconClass={`fa-light fa-rss`}
        >
          <PublishChannel
            publish={publishStatus !== "none" ? "false" : "true"}
            userId={userId}
            fnClose={() => {
              refreshData(true);
              setShowPublish(null);
            }}
          />
        </Popover>
      ) : null}
      {publishMessage ? (
        <DialogPop
          title={`Channel Info`}
          headerIcon={`info`}
          fnClose={() => {
            setPublishMessage(null);
          }}
        >
          <div>
            Your channel is not yet fully published. This content is only
            visible to you.
            <div className={`p-1`}>
              <button
                type="button"
                className={`button-save m-05`}
                onClick={() => {
                  setShowPublish(true);
                  setPublishMessage(null);
                }}
              >
                {publishStatus !== "none" ? "Unpublish" : "Publish Now"}
              </button>
              <button
                type="button"
                className={`m-05`}
                onClick={() => {
                  setPublishMessage(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </DialogPop>
      ) : null}
      {/* DESCRIPTION */}
      {description ? (
        <BottomPop
          hasFooter={true}
          title={`${description.author.first_name} ${description.author.last_name}`}
          fnClose={() => {
            //setDescription(null);
            toggleDescription(null);
          }}
          fnLoaded={true}
        >
          <div className={`description-pop`}>
            <div className={`bold`}>{description.headline}</div>
            <div className={`small-font`}></div>
            <div className={`description-content`}>
              {moment(new Date(description.createdAt)).fromNow()} -{" "}
              {description.description}
            </div>
            <div className={`description-pop-footer`}>
              <button
                type="button"
                className={`anchor-button p-05`}
                onClick={() => {
                  reportBadContent(description);
                  toggleDescription(null);
                }}
              >
                <i className={`fa-light fa-exclamation-circle larger-font`} />
              </button>
            </div>
          </div>
        </BottomPop>
      ) : null}
      {/** edit form  */}
      {showEditForm.showForm && (
        <BottomPop
          fnClose={() => {
            setShowEditForm({
              item: {},
              showForm: false,
            });
          }}
          title="Edit Post"
          fnLoaded={true}
        >
          <EditFeed
            feed={showEditForm.item}
            setShowEditForm={setShowEditForm}
            setShowConfirmZone={setShowConfirmZone}
          />
        </BottomPop>
      )}
      {/** show confirm zone after update feed */}
      {showConfirmZone ? (
        <BottomPop
          fnClose={() => {
            setShowEditForm({
              item: {},
              showForm: false,
            });
            setShowConfirmZone(true);
            refreshData(true);
          }}
          fnLoaded={true}
        >
          <div style={{ minHeight: "25vh" }}>
            <div className={"larger-font brand-dark-green"}>Hooya!</div>
            <div className={`p-1`}>Your post has been updated.</div>
            <div className={`m-top-05`}>
              <button
                type="button"
                className={`button-save`}
                onClick={() => {
                  setShowConfirmZone(false);
                  refreshData(true);
                }}
              >
                Finish
              </button>
            </div>
          </div>
        </BottomPop>
      ) : null}
      {/* DELETE CONTENT POP */}
      {deleteItemRow?.author._id === userId ? (
        <Popover
          fnClose={() => {
            deleteItem(null);
          }}
          title="Delete Content"
          iconClass={"fa-regular fa-trash-can"}
        >
          <DeleteContent
            contentId={deleteItemRow?._id}
            fnClose={() => {
              deleteItem(null);
              refreshData(true);
            }}
          />
        </Popover>
      ) : null}
      {/* REPORT CONTENT POP */}
      {contentItem ? (
        <BottomPop
          fnClose={() => setContentItem(null)}
          title="Report Content Concerns"
          fnLoaded={true}
        >
          <ReportContent
            item={contentItem}
            fnClose={(remove) => {
              if (remove) {
                pullFromDOM(contentItem);
              }
              setContentItem(null);
            }}
          />
        </BottomPop>
      ) : null}

      {/* ATHLETE PROFILE POP */}
      {creatorId ? (
        <Popover
          fnClose={() => toggleAthlete(null, null)}
          title={athleteName}
          iconClass={"fa-light fa-user"}
          scrollSnap={true}
        >
          <Profile
            creatorId={creatorId}
            fnClose={() => toggleAthlete(null, null)}
            onRefresh={() => {
              if (refreshData) {
                refreshData(true);
              }
            }}
          />
        </Popover>
      ) : null}
      <div>
        <FeedSlides
          myFeed={posts}
          setShowEditForm={setShowEditForm}
          /*  player={player} */
          /* setPlayer={(val) => {
            setPlayer(val);
          }} */
          globalMute={globalMute}
          setPageNow={(val) => {
            setPageNow(val);
          }}
          setGlobalMute={(val) => {
            setGlobalMute(val);
          }}
          setSnapped={(val) => {
            setSnapped(val);
          }}
          showingLikes={likes}
          userId={userId}
          toggleAthlete={(authorId, authorName) => {
            toggleAthlete(authorId, authorName);
          }}
          toggleDescription={(rec) => {
            toggleDescription(rec);
          }}
          toggleReportContent={(val) => {
            reportBadContent(val);
          }}
          deleteItemRow={(val) => {
            deleteItem(val);
          }}
          togglePublishMessage={(val) => {
            setPublishMessage(true);
            setPublishStatus(val);
          }}
          setShowConfirmZone={setShowConfirmZone}
          filterId={filterId}
          setFilterId={(val) => {
            setFilterId(val);
          }}
        ></FeedSlides>
      </div>
    </div>
  );
}
export default Feed;
