import { useEffect } from "react";
import { useState } from "react";
import AddContent from "../Feed/addcontent";
import BottomPop from "../Utils/bottompop";

function FooterMini({ onRefresh, setGlobalMute, testId }) {
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);

  useEffect(() => {
    const testShowAddButton = async () => {
      const tempUser = await JSON.parse(localStorage.getItem("user"));
      console.log(`testId: ${testId} ${tempUser._id}`);
      if (tempUser?.app_role === "creator" && testId === tempUser._id) {
        setShowAddButton(true);
      }
    };
    if (testId) {
      testShowAddButton();
    }
  }, [testId]);

  return (
    <div className={`footer-mini ${showAddMenu ? "repo" : ""}`}>
      {showAddButton ? (
        <button
          className={`anchor-button mini-add`}
          type="button"
          onClick={() => {
            if (setGlobalMute) {
              setGlobalMute(true);
            }
            setShowAddMenu((prev) => !prev);
          }}
        >
          <i className="fa fa-circle-plus" />
        </button>
      ) : null}

      {showAddMenu ? (
        <BottomPop
          fnClose={() => setShowAddMenu(null)}
          title="Post Content"
          fnLoaded={true}
        >
          <AddContent
            fnClose={() => {
              if (onRefresh) {
                onRefresh();
              }
              setShowAddMenu(null);
            }}
          />
        </BottomPop>
      ) : null}
    </div>
  );
}
export default FooterMini;
