import { useRef } from "react";
import { useEffect } from "react";
import "../../Css/Utils/ErrorMsg.css";

function ErrorMsg({
  message = "Yipes! Something is not right here.",
  title = "Error",
  fnClose,
  level = "warn",
  position = "fixed",
}) {
  const wrapper = useRef(null);

  useEffect(() => {
    if (position === "fixed") {
      if (wrapper.current) {
        setTimeout(() => {
          wrapper.current.classList.add("show");
        }, 100);
        
      }
    }
  }, [position]);

  return (
    <div ref={wrapper} className={`error-msg ${level} ${position}`}>
      <div className={`error-header`}>
        <div className={`error-title flex-1 m-left-05`}>{title}</div>
        <button
          type="button"
          className={`anchor-button p-05`}
          onClick={() => {
            fnClose();
          }}
        >
          <i className={`fa-solid fa-times`} />
        </button>
      </div>
      <div className={`error-message`}>{message}</div>
    </div>
  );
}
export default ErrorMsg;
