import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import "../../Css/Home/HintPop.css";

function HintPop({ id = 0, user, fnClose }) {
  const [hintId, setHintId] = useState(id);
  const [className, setClassName] = useState();
  const [caretDir, setCaretDir] = useState();
  const [title, setTitle] = useState();
  const [hint, setHint] = useState();
  const [maxHint, setMaxHint] = useState();

  const wrapper = useRef(null);

  useEffect(() => {
    wrapper.current.classList.remove("visible");
    const delay = 100;
    let hints = [
      {
        id: 0,
        className: "hint-main-menu",
        caretDir: "up",
        title: "Main Menu",
        hint: `Manage your profile, ${
          user.app_role === "creator"
            ? "publish your channel, share your channel"
            : "refer a friend"
        }, contact support and more.`,
      },
      {
        id: 1,
        className: "hint-search",
        caretDir: "up",
        title: "Search",
        hint: "Find who you're looking for. Search by person's name or sport.",
      },
      {
        id: 2,
        className: "hint-tabs",
        caretDir: "up",
        title: "Tabs",
        hint: "Navigate tabs to browse content and discover creators.",
      },
      {
        id: 3,
        className: "hint-report",
        caretDir: "right",
        title: "Report Content",
        hint: "Tap here to report inappropriate content.",
      },
      {
        id: 4,
        className: "hint-likes",
        caretDir: "right",
        title: "Like Posts",
        hint: `Like posts and revisit your favorite content in the "Likes" tab.`,
      },
      {
        id: 5,
        className: "hint-creator",
        caretDir: "down",
        title: "Creator Profile",
        hint: `Tap creator's name to visit their profile and all of their posts.`,
      },
    ];

    if (user.app_role === "creator") {
      hints = [
        ...hints,
        {
          id: 6,
          className: "hint-add-content",
          caretDir: "down",
          title: "Post Content",
          hint: "Tap here to post videos, pictures and more.",
        },
        {
          id: 7,
          className: "hint-main-menu",
          caretDir: "up",
          title: "Tour Complete",
          hint: (
            <div>
              <div className={`p-bottom-1`}>Ok {user.first_name}, ready to make some money?</div>
              <div className={`bold`}>Next steps</div>
              <ol className={`small-font`}>
                <li>Complete your profile.</li>
                <li>Post exclusive content.</li>
                <li>Publish your channel.</li>
                <li>Invite your social media fans.</li>
                <li>Get paid!</li>
              </ol>
              <div className={`m-top-1 small-font`}>Open Main Menu to get started.</div>
            </div>
          ), //"Next steps:*Complete your profile%0D%0A*Post several picturs or videos\n\n*Publish your channel.",
        },
      ];
    }

    if (user.app_role === "fan") {
      hints = [
        ...hints,
        {
          id: 6,
          className: "hint-final",
          caretDir: "none",
          title: "Tour Complete",
          hint: "You're all set. Enjoy discovering and unlocking your favorite creators in the Athletes World.",
        },
      ];
    }

    const changeHint = (data) => {
      const filteredHint = data.filter(function (row) {
        return row.id === parseInt(hintId);
      });
      setClassName(filteredHint[0].className);
      setCaretDir(filteredHint[0].caretDir);
      setTitle(filteredHint[0].title);
      setHint(filteredHint[0].hint);
    };
    setMaxHint(hints.length - 1);
    changeHint(hints);
    setTimeout(() => {
      wrapper.current.classList.add("visible");
    }, delay);
  }, [hintId, user]);

  return (
    <div>
      <div className={`hint-sheen`}></div>
      <div ref={wrapper} className={`hint-wrapper`}>
        <div className={`hint-pop ${className}`}>
          <div className={`hint-inner`}>
            <div className={`hint-children-wrapper`}>
              {/* HINT CARET */}
              <div className={`hint-caret`}>
                <i className={`fa-solid fa-caret-${caretDir}`} />
              </div>
              <div className={`hint-children`}>
                <div className={`hint-title`}>
                  <i className={`fa-solid fa-info-circle larger-font`} />
                  <div className={`m-left-1 flex-1 large-font`}>{title}</div>
                </div>
                <div className={`hint-content`}>{hint}</div>
                <div className={`hint-buttons`}>
                  {maxHint !== hintId ? (
                    <div className={`flex-1 align-left small-font`}>
                      <button
                        type="button"
                        className={`anchor-button small-font`}
                        onClick={() => {
                          fnClose();
                        }}
                      >
                        End Tour
                      </button>
                    </div>
                  ) : (
                    <div className={`flex-1`}></div>
                  )}

                  {maxHint !== hintId ? (
                    <button
                      type="button"
                      className={`anchor-button small-font`}
                      onClick={() => {
                        setHintId((prev) => prev + 1);
                        //fnClose();
                      }}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`anchor-button small-font bold`}
                      onClick={() => {
                        fnClose();
                      }}
                    >
                      Done
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HintPop;
