import { useRef } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import baseClient from "../../api/Base";

function EditPhone({ phoneNbr, setPhoneNbr, fnVerified, fnClose }) {
  const [showValidation, setShowValidation] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [confirm, setConfirm] = useState();

  const codeBox = useRef(null);

  const submitCode = async () => {
    try {
      const tempcode = codeBox.current.value;
      if (tempcode.length === 5) {
        //do api call
        if (phoneNbr) {
          const model = {
            phone: phoneNbr,
            authCode: tempcode,
          };
          const myResponse = await baseClient.APIPost({
            model: model,
            api: "testphonecode",
          });
          if (myResponse && myResponse.code === 200) {
            //good response
            localStorage.setItem("user", JSON.stringify(myResponse.data));
            setConfirm(true);
            fnVerified(true);
          } else {
            if (myResponse && myResponse.data === "duplicate") {
              setErrorMsg("That phone number is already in use.");
            } else {
              setErrorMsg("Something went wrong.");
            }
          }
        }
      }
    } catch (err) {
      setErrorMsg("Something went wrong validating phone.");
    }
  };

  const submitPhone = async () => {
    let model = {};
    let goodPhone = false;
    if (phoneNbr && isPossiblePhoneNumber(phoneNbr)) {
      goodPhone = true;
    }
    if (!goodPhone) {
      setErrorMsg("Doesn't look like a valid international phone number.");
      console.error("Unknown error missing phone number.");
      return;
    }
    fnVerified(false);
    model = {
      phone: phoneNbr,
    };

    const myResponse = await baseClient.APIPost({
      model: model,
      api: "sendcode",
    });

    if (myResponse && myResponse.data) {
      console.log(myResponse.data); // this is safe as it only shows code on localhost
      setShowValidation(true);
    }
  };

  return (
    <div>
      <div className={`fade ${errorMsg ? "fade-in" : ""} p-05 bg-brand-red`}>
        {errorMsg || 'error'}
      </div>
      {confirm ? (
        <div>
          <div className={`large-font`}>You're all set!</div>
          <div className={`p-1`}>New phone number has been saved.</div>
          <div className={`p-1`}>
            <button
              type="button"
              onClick={() => {
                fnClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      ) : showValidation ? (
        <div>
          <div>
            We sent you a text to {phoneNbr}.
            <div className={`p-top-1 p-bottom-025`}>
              Please enter 5-digit code.
            </div>
            <div>
              <input
                ref={codeBox}
                type="number"
                className={`p-025 align-center`}
                autoFocus
                onChange={() => {
                  setErrorMsg(null);
                  submitCode();
                }}
              />
            </div>
            <div className={`m-top-1`}>
              <button
                type="button"
                className={`m-025 small-font`}
                onClick={() => {
                  setShowValidation(null);
                }}
              >
                <i className={`fa-solid fa-caret-left m-right-05`} />
                Back
              </button>
              <button
                type="button"
                className={`m-025 small-font`}
                onClick={() => {
                  submitPhone();
                }}
              >
                Re-send
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <form className={`settings-form pop-form`}>
            <label>
              <div className={`phone-field`}>
                <PhoneInput
                  id="phone"
                  name="phone"
                  placeholder="Mobile Phone"
                  value={phoneNbr}
                  onChange={setPhoneNbr}
                  onKeyDown={() => {
                    setErrorMsg(null);
                  }}
                />
              </div>
            </label>
          </form>
          <div className={`p-1`}>
            <button
              type="button"
              className={`button-cancel m-05`}
              onClick={() => {
                //setEditPhone(null);
                fnClose();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`button-save m-05`}
              disabled={!phoneNbr}
              onClick={() => {
                submitPhone();
              }}
            >
              Validate
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default EditPhone;
