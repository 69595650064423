import { useRef, useState } from "react";
import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";
import DialogPop from "../Utils/dialogpop";
import "../../Css/User/EditEmail.css";
import ErrorMsg from "../Utils/errormsg";

function EditEmail({
  emailVerified,
  setEmailVerified,
  userEmail,
  setUserEmail,
  missing,
}) {
  const [showEmailValidation, setShowEmailValidation] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [emailSent, setEmailSent] = useState();
  const [wait, setWait] = useState();
  const [goodEmail, setGoodEmail] = useState();
  const [editEmail, setEditEmail] = useState();
  const [newEmail, setNewEmail] = useState();

  const email = useRef(null);

  const refreshValidate = async () => {
    try {
      const model = {};
      const response = await baseClient.APIPost({
        model: model,
        api: "refreshvalidation",
      });
      if (response && response.data) {
        // reset validate buttons
        // TODO: [DEV-24] Need to validate phone numbers on user settings when changed
        if (response.data.emailVerifiedAt) {
          setEmailVerified(true);
          setUserEmail(response.data.email);
          setNewEmail(null);
        } else {
          setEmailVerified(false);
        }
      } else {
        console.error("Someting went wrong refreshing validation");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const validateMyEmail = async () => {
    try {
      const model = {
        email: newEmail,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "sendemailvalidation",
      });
      if (response && response.data) {
        setEmailSent(true);
        if(response.data === 'duplicate') {
          setErrorMsg('Email was not saved. Email already in use.')
        }
      } else {
        setShowEmailValidation(null);
        setErrorMsg("Something went wrong during email validation.");
      }
    } catch (err) {
      setShowEmailValidation(null);
      console.error(err.message);
      setErrorMsg("Something went wrong sending validation link.");
    }
  };

  return (
    <span className={`edit-email`}>
      {errorMsg ? (
        <ErrorMsg 
          title="Error"
          message={errorMsg}
          fnClose={() => {
            setErrorMsg(null);
            setEmailSent(null);
          }}
        />
      ) : null}
      {showEmailValidation ? (
        <DialogPop
          title={`Validate Your Email`}
          fnClose={() => {
            setShowEmailValidation(null);
          }}
          headerIcon={`info`}
        >
         
          <div>
            {emailSent && !errorMsg ? (
              <div>
                {newEmail ? (
                  <div className={`small-font muted-text p-bottom-1`}>
                    New email address has been saved.
                  </div>
                ) : null}
                <div>Check your email.</div>
                <div>Click the link in email to validate.</div>

                <div className={`p-1`}>
                  <button
                    type="button"
                    className={`m-05`}
                    onClick={() => {
                      setWait(true);
                      validateMyEmail();
                      setTimeout(() => {
                        setWait(null);
                      }, 1000);
                    }}
                  >
                    Resend
                    {wait ? (
                      <i className={`fa fa-spinner fa-spin m-left-05`} />
                    ) : null}
                  </button>
                  <button
                    type="button"
                    className={`m-05 button-save`}
                    onClick={() => {
                      setShowEmailValidation(null);
                      setNewEmail(null);
                      setEmailSent(null);
                      refreshValidate();
                    }}
                  >
                    Done
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div>We'll email you a validation link.</div>
                <div className={`p-1 m-top-1`}>
                  <button
                    type="button"
                    className={`button-cancel m-right-1`}
                    onClick={() => {
                      setShowEmailValidation(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      validateMyEmail();
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </DialogPop>
      ) : null}
      {editEmail ? (
        <DialogPop
          fnClose={() => {
            setEditEmail(null);
          }}
          title="Edit Email"
          headerIcon={"edit"}
        >
          <span>
            <label>
              <div className={`email-input-wrapper`}>
                <input
                  ref={email}
                  id="email"
                  name="email"
                  type="email"
                  className={`small-font p-05`}
                  placeholder="Email"
                  defaultValue={userEmail}
                  onChange={(e) => {
                    setGoodEmail(toolsClient.validateEmail(e.target.value));
                    setNewEmail(e.target.value);
                    setEmailSent(null);
                  }}
                />
              </div>
            </label>

            <span className={`p-1`}>
              <button
                type="button"
                className={`button-cancel m-05`}
                onClick={() => {
                  setEditEmail(null);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`button-save m-05`}
                disabled={!goodEmail || !email.current?.value}
                onClick={() => {
                  let newEmailVal = email.current.value;
                  //setNewEmail(newEmailVal);
                  setUserEmail(newEmailVal);
                  setEditEmail(null);
                  setShowEmailValidation(true);
                }}
              >
                Validate
              </button>
            </span>
          </span>
        </DialogPop>
      ) : null}

      <span>
        <div>
          <span className={`flex`}>
            <span className={`flex-1 small-font`}>Email</span>
            {userEmail && !emailVerified ? (
              <button
                type="button"
                className={`anchor-button tag validate small-font block`}
                onClick={() => {
                  setEmailSent(false);
                  setShowEmailValidation(true);
                }}
              >
                Validate
              </button>
            ) : userEmail ? (
              <span>
                <i
                  className={`fa-solid fa-check-circle m-right-025 brand-dark-green`}
                />
                <span className={`small-font muted-text`}>Validated</span>
              </span>
            ) : null}
          </span>
        </div>
        <div className={`email-field`}>
          <span
            className={`p-05 flex-1 small-font
            ${
              missing?.filter((m) => m.field === "email").length > 0
                ? "required"
                : ""
            }
          `}
          >
            {newEmail || userEmail}
          </span>
          <button
            type="button"
            className={`anchor-button m-left-05 m-right-05`}
            onClick={() => {
              setEditEmail(true);
            }}
          >
            <i className={`fa-light fa-edit`} />
          </button>
        </div>
      </span>
    </span>
  );
}
export default EditEmail;
