import moment from "moment/moment";
import { useEffect, useState, useRef } from "react";
import baseClient from "../../api/Base";
//import loadingGif from "../../Graphics/loadingVid.gif";

function FeedItem({
  item,
  showingLikes = false,
  userId,
  toggleReportContent,
  toggleAthlete,
  toggleDescription,
  deleteItemRow,
  togglePublishMessage,
  snapped,
  setSnapped,
  setShowEditForm,
  setGlobalMute,
  globalMute,
  player,
  setPlayer,
  recNbr,
  filterId,
  setFilterId,
  isScrolling,
}) {
  const [liked, setLiked] = useState();
  const [approved, setApproved] = useState(true);
  const [vidLoadingId, setVidLoadingId] = useState();
  const [imageLoadingId, setImageLoadingId] = useState();
  const [testRegisterContent, setTestRegisterContent] = useState();
  const [showPause, setShowPause] = useState();
  const itemRef = useRef(null);

  const videoTagRef = useRef(null);

  const toggleMuted = (muteOnly = false) => {
    const mute = muteOnly ? true : !globalMute;
    setGlobalMute(mute);
    const myElement = document.getElementById(`video_${item.id}`);
    if (myElement) {
      myElement.muted = mute;
    }
  };

  const toggleFavorite = async () => {
    try {
      let tempLiked;
      if (liked) {
        tempLiked = "false";
      } else {
        tempLiked = "true";
      }
      const model = {
        id: item._id,
        liked: tempLiked,
      };
      await baseClient.APIPost({
        model: model,
        api: "likepost",
      });

      setLiked((prev) => !prev);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (item.type === "image") {
      setImageLoadingId(item._id);
      const img = new Image();
      img.src = item.contentUrl;
      img.onload = () => {
        setImageLoadingId(null);
      };
    }
  }, [item]);

  useEffect(() => {
    if (!player) return;
    const myElement = document.getElementById(`video_${item.id}`);
    if (myElement) {
      myElement.muted = globalMute;
    }
  }, [player, globalMute, item.id]);

  useEffect(() => {
    setLiked(item.liked);

    if (item.author.publish_status) {
      if (item.author.publish_status === "published") {
        setApproved(true);
      } else {
        setApproved(false);
      }
    } else {
      setApproved(true);
    }
  }, [item]);

  let scrollTimer = useRef(null);
  useEffect(() => {
    try {
      const myElement = itemRef.current;
      const myItem = item;

      if ((myElement && snapped) || recNbr === 0) {
        const bounding = myElement.getBoundingClientRect();
        setSnapped(null);
        clearTimeout(scrollTimer.current);
        setTestRegisterContent(null);

        if (bounding.top < 1 && bounding.top > -1.5) {
          // console.log(bounding.top)
          //baseClient.serverLogger(`{bounding.top: ${bounding.top}}`);
          // pause all videos before playing the visible video
          //serverLogger(`bounding.top: ${bounding.top}`);
          let currentVideo;
          if (myItem.type === "video") {
            //serverLogger(`myItem.type: ${myItem.type}`);

            //serverLogger(`videoTagRef.current: true`);

            try {
              currentVideo = document.getElementById(`video_${item._id}`);

              if (currentVideo instanceof HTMLVideoElement) {
                setShowPause(false);
                currentVideo.play();
              }
            } catch (err) {
              console.log(err.message);
              baseClient.serverLogger(`plyr.play() error: ${err.message}`);
            }
            setPlayer(myElement);
          }
          try {
            const videos =
              Array.from(document.getElementsByClassName("feed-video")) || [];
            videos.forEach((vid) => {
              if (
                vid instanceof HTMLVideoElement &&
                vid.id !== `video_${item._id}`
              ) {
                vid.pause();
              }
            });
          } catch {
            //do nothing
          }

          setTestRegisterContent(item._id);
        }
        /* return () => {
        clearTimeout(scrollTimer.current);
      }; */
      }
    } catch (err) {
      console.log(err.message);
      baseClient.serverLogger(`snapping error: ${err.message}`);
    }
  }, [snapped, setSnapped, item, setPlayer, recNbr]);

  useEffect(() => {
    if (testRegisterContent) {
      clearTimeout(scrollTimer.current);
      if (
        vidLoadingId !== testRegisterContent &&
        imageLoadingId !== testRegisterContent
      ) {
        const registerContentView = async (type) => {
          try {
            const model = {
              post_id: item._id,
              author_id: item.author._id,
              type: type,
            };
            await baseClient.APIPost({
              model: model,
              api: "contentview",
            });
          } catch (error) {
            console.error(error.message);
          }
        };

        scrollTimer.current = setTimeout(() => {
          console.log(`registerView ${item._id}`);
          registerContentView("post");
        }, 3000);
      }
    }
  }, [item, vidLoadingId, imageLoadingId, testRegisterContent]);

  useEffect(() => {
    if (isScrolling) {
      // mute current video when scrolling
      const currentVideo = document.getElementById(`video_${item._id}`);
      if (currentVideo) {
        currentVideo.muted = true;
      }
    } else {
      const currentVideo = document.getElementById(`video_${item._id}`);
      if (currentVideo) {
        setTimeout(() => {
          currentVideo.muted = globalMute;
        }, 100);
      }
    }
  }, [isScrolling, globalMute, item]);

  return showingLikes && !liked ? (
    <li></li>
  ) : (
    <li ref={itemRef} className={`snap ${approved ? "" : "not-published"}`}>
      {item.type === "video" ? (
        <div className={`feed-image feed-video`}>
          {vidLoadingId === item._id ? (
            <>
              {/* <img src={loadingGif} alt="loading..." className={`item-loading`} /> */}
              <i className={`fa fa-loader fa-spin item-loading`} />
            </>
          ) : null}

          <button
            type="button"
            className={`anchor-button video-pause fa fa-pause larger-font text-shadow ${
              !showPause ? "hidden" : ""
            }`}
            onClick={() => {
              setShowPause(null);
              const el = videoTagRef.current;
              el.play();
            }}
          ></button>

          <video
            ref={videoTagRef}
            src={item.contentUrl}
            className={`feed-video ${item.portrait ? "portrait" : ""}`}
            controls={false}
            muted={globalMute}
            autoPlay={true}
            loop={true}
            playsInline={true}
            id={`video_${item._id}`}
            //style={{ "--plyr-color-main": "#ff004e", width: "100%" }}
            //poster={loadingGif}
            onClick={(e) => {
              const myElement = e.target;
              if (!myElement.paused) {
                myElement.pause();
                setShowPause(true);
              } else {
                myElement.play();
                setShowPause(false);
              }
            }}
            onError={(e) => {
              baseClient.serverLogger(`video error: ${e}`);
            }}
            onLoadStart={() => {
              if (recNbr === 0) {
                setVidLoadingId(item._id);
              }
            }}
            onCanPlay={() => {
              setVidLoadingId(null);
            }}
          />
          {!approved ? (
            <button
              type="button"
              className={`anchor-button publish-lock`}
              onClick={(e) => {
                e.stopPropagation();
                togglePublishMessage(true);
              }}
            >
              <span className={`small-font`}>
                <i className={`fa-solid fa-lock larger-font m-right-05`} />
                Not Published
              </span>
            </button>
          ) : null}
        </div>
      ) : (
        <>
          {imageLoadingId === item._id ? (
            <i className={`fa fa-loader fa-spin item-loading`} />
          ) : (
            <div
              id={`image_${item._id}`}
              className={`feed-image ${item.portrait ? "portrait" : ""}`}
              style={{ backgroundImage: `url(${item.contentUrl})` }}
            >
              {!approved ? (
                <button
                  type="button"
                  className={`anchor-button publish-lock`}
                  onClick={() => {
                    togglePublishMessage(true);
                  }}
                >
                  <span className={`small-font`}>
                    <i className={`fa-solid fa-lock larger-font m-right-05`} />
                    Not Published
                  </span>
                </button>
              ) : null}
            </div>
          )}
        </>
      )}
      {/* FOOTER */}
      <div
        role="button"
        className="feed-footer"
        onClick={() => {
          const myElement = videoTagRef.current;
          if (!myElement.paused) {
            myElement.pause();
            setShowPause(true);
          } else {
            myElement.play();
            setShowPause(false);
          }
        }}
      >
        <div className="feed-description">
          <div className={`creator-name`}>
            <button
              type="button"
              className={`anchor-button text-shadow`}
              onClick={(e) => {
                e.stopPropagation();
                toggleMuted(true);
                toggleAthlete(
                  item.author._id,
                  `${item.author.first_name} ${item.author.last_name}`
                );
              }}
            >
              {item.author.first_name} {item.author.last_name}
            </button>
            {!showingLikes ? (
              <button
                type="button"
                className={`anchor-button feed-filter ${
                  filterId && filterId !== "none" ? "selected" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!filterId || filterId === "none") {
                    setFilterId(item.author._id);
                  } else {
                    setFilterId("none");
                  }
                }}
              >
                <i
                  className={`text-shadow  ${
                    filterId && filterId !== "none"
                      ? "fa-solid fa-filter-circle-xmark "
                      : "fa-regular fa-filter"
                  }`}
                />
              </button>
            ) : null}
          </div>
          <div>{item.headline}</div>
          <div className="feed-date">
            {moment(new Date(item.createdAt)).fromNow()}
          </div>
          <div className={`show-more`}>
            {item.description ? (
              <button
                type="button"
                className="anchor-button small-font"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDescription(item);
                }}
              >
                Show more...
              </button>
            ) : null}
          </div>
        </div>

        <div className={`feed-actions`}>
          {["video", "link_video"].includes(item.type) && (
            <>
              <div className="mx-1">
                <button
                  type="button"
                  className="anchor-button mute-toggle-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMuted();
                  }}
                >
                  <i
                    className={`text-shadow m-bottom-1 fas fa-volume${
                      globalMute ? "-mute" : ""
                    }`}
                  ></i>
                </button>
              </div>
            </>
          )}
          <div>
            <button
              type="button"
              className={`anchor-button`}
              onClick={(e) => {
                e.stopPropagation();
                toggleFavorite();
              }}
            >
              <i
                className={`fa-solid fa-heart text-shadow ${
                  liked ? "brand-pink" : ""
                }`}
              />
            </button>
          </div>         
          <div className="m-top-1">
            {item.author._id === userId ? (
              <>
                <button
                  type="button"
                  className={`anchor-button block `}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (player) {
                      toggleMuted(true);
                    }
                    setShowEditForm({
                      item,
                      showForm: true,
                    });
                  }}
                >
                  <i
                    className={`text-shadow fa-light fa-square-pen m-bottom-1`}
                  />
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (player) {
                      toggleMuted(true);
                    }
                    deleteItemRow(item);
                  }}
                  className="anchor-button"
                >
                  <i className={`text-shadow fa-regular fa-trash-can`} />
                </button>
              </>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (player) {
                    toggleMuted(true);
                  }
                  toggleReportContent(item);
                }}
                className="anchor-button"
              >
                <i className={`text-shadow fa-regular fa-circle-exclamation`} />
              </button>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
export default FeedItem;
