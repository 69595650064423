import { useEffect, useRef, useState } from "react";
import baseClient from "../../api/Base";
import "../../Css/Help/Help.css";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";
import moment from "moment";

function SupportForm({ id, fnClose }) {
  const [showConfirm, setShowConfirm] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState("");
  const [ticketType, setTicketType] = useState("question");
  const [ticketStatus, setTicketStatus] = useState();
  const [comment, setComment] = useState();
  const [resolutionMessage, setResolutionMessage] = useState();
  const [internalComment, setInternalComment] = useState();
  const [user, setUser] = useState();
  const [submitDate, setSubmitDate] = useState();

  const commentField = useRef(null);
  const internalCommentField = useRef(null);
  const resolutionMessageField = useRef(null);

  const upsertTicket = async () => {
    try {
      setLoading(true);
      if (!comment) {
        setLoading(false);
        setErrorMsg({
          message: `Please leave an explanation.`,
          title: "Oops",
        });
        commentField.current.focus();
        return;
      }

      if (ticketStatus === "closed" && !resolutionMessageField.current.value) {
        setLoading(false);
        setErrorMsg({
          message: `Please leave an explanation.`,
          title: "Oops",
        });
        resolutionMessageField.current.focus();
        return;
      }

      const model = {
        _id: id === "new" ? null : id,
        type: ticketType,
        comment: comment,
        status: ticketStatus || "submitted",
        resolutionMessage: resolutionMessage,
        internalComment: internalComment,
      };

      const result = await baseClient.APIPost({
        model: model,
        api: "upsertticket",
      });

      if (!result || result.code !== 200) {
        setLoading(false);
        setErrorMsg({
          message: `Error saving ticket. ${result?.message}`,
          title: "Oops",
        });
        return;
      }
      setTimeout(() => {
        setLoading(false);
        setShowConfirm(true);
      }, toolsClient.LOADING_DELAY);
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong saving ticket.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    const getticket = async () => {
      try {
        setLoading(true);
        const model = {
          ticket_id: id,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "tickets",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting ticket. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        const ticketData = result.data[0];
        setUser(ticketData.requestBy);
        setSubmitDate(moment(ticketData.createdAt).format('MMM-DD-YYYY'))
        setComment(ticketData.comment);
        setInternalComment(ticketData.internalComment);
        setResolutionMessage(ticketData.resolutionMessage);
        setTicketType(ticketData.type);
        setTicketStatus(ticketData.status);
        setTimeout(() => {
          setLoading(false);
          setFadeIn("fade-in");
        }, toolsClient.LOADING_DELAY);
      } catch (err) {
        setErrorMsg({
          message: `Something went wrong getting tickets list. ${err.mesage}`,
          title: "Oops",
        });
        console.error(err.message);
      }
    };
    if (id && id !== "new") {
      getticket();
    } else {
      setFadeIn("fade-in");
    }
  }, [id]);

  return (
    <div className={`support-content`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`fade ${fadeIn}`}>
        {!showConfirm ? (
          <div className="report-details">
            {user ? (
              <div className={`small-font bold`}>
                <div>SubmittedBy: {`${user.first_name} ${user.last_name} (${user.app_role})`}</div>
                <div>SubmittedOn: {submitDate}</div>
              </div>
            ) : (
              <div>
              <div className={`larger-font m-bottom-05 m-top-1`}>
                How can we help?
              </div>
              <div>
                Let us know what's on your mind and we'll get back to you ASAP.
              </div>
            </div>
            )}
            
            <ul className="no-bullets m-top-1">
              <li>
                <label htmlFor="question">
                  <input
                    id="question"
                    name="type"
                    type="radio"
                    checked={ticketType === "question"}
                    onChange={() => {
                      setTicketType("question");
                    }}
                  />
                  <div className={`flex-1 m-left-05`}>I have a question. </div>
                </label>
              </li>
              <li>
                <label htmlFor="accounting">
                  <input
                    id="accounting"
                    name="type"
                    type="radio"
                    checked={ticketType === "accounting"}
                    onChange={() => {
                      setTicketType("accounting");
                    }}
                  />
                  <div className={`flex-1 m-left-05`}>
                    I need help recieving payments.{" "}
                  </div>
                </label>
              </li>
              <li>
                <label htmlFor="bug">
                  <input
                    id="bug"
                    name="type"
                    type="radio"
                    checked={ticketType === "bug"}
                    onChange={() => {
                      setTicketType("bug");
                    }}
                  />
                  <div className={`flex-1 m-left-05`}>
                    I have noticed a bug.{" "}
                  </div>
                </label>
              </li>
              <li>
                <label htmlFor="feature">
                  <input
                    id="feature"
                    name="type"
                    type="radio"
                    checked={ticketType === "feature"}
                    onChange={() => {
                      setTicketType("feature");
                    }}
                  />
                  <div className={`flex-1 m-left-05`}>
                    I have a feature suggestion.
                  </div>
                </label>
              </li>
              <li>
                <label htmlFor="other">
                  <input
                    id="other"
                    name="type"
                    type="radio"
                    checked={ticketType === "other"}
                    onChange={() => {
                      setTicketType("other");
                    }}
                  />
                  <div className={`flex-1 m-left-05`}>Other (explain)</div>
                </label>
              </li>
              <li>
                <textarea
                  ref={commentField}
                  rows="10"
                  className=""
                  placeholder="Please Explain"
                  defaultValue={comment}
                  required
                  onChange={(e) => {
                    setErrorMsg(null);
                    const val = e.target.value;
                    setComment(val);
                  }}
                ></textarea>
              </li>
              {id ? (
                <ul className="content-selector">
                  <h3>Ticket Status</h3>
                  <li>
                    <label htmlFor="submitted">
                      <input
                        id="submitted"
                        name="status"
                        type="radio"
                        checked={ticketStatus === "submitted"}
                        onChange={() => {
                          setTicketStatus("submitted");
                        }}
                      />
                      <div className={`flex-1 m-left-05`}>Submitted </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="working">
                      <input
                        id="working"
                        name="status"
                        type="radio"
                        checked={ticketStatus === "working"}
                        onChange={() => {
                          setTicketStatus("working");
                        }}
                      />
                      <div className={`flex-1 m-left-05`}>Working </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="closed">
                      <input
                        id="closed"
                        name="status"
                        type="radio"
                        checked={ticketStatus === "closed"}
                        onChange={() => {
                          setTicketStatus("closed");
                        }}
                      />
                      <div className={`flex-1 m-left-05`}>Closed </div>
                    </label>
                  </li>
                  <h3>Internal Comment</h3>
                  <li>
                    <textarea
                      ref={internalCommentField}
                      rows="5"
                      placeholder="Internal Comment"
                      defaultValue={internalComment}
                      required
                      onChange={(e) => {
                        setErrorMsg(null);
                        const val = e.target.value;
                        setInternalComment(val);
                      }}
                    ></textarea>
                  </li>
                  <h3>Resolution Message</h3>
                  <li>
                    <textarea
                      ref={resolutionMessageField}
                      rows="5"
                      placeholder="This message will be sent to the user once the ticket is closed."
                      defaultValue={resolutionMessage}
                      required
                      onChange={(e) => {
                        setErrorMsg(null);
                        const val = e.target.value;
                        setResolutionMessage(val);
                      }}
                    ></textarea>
                  </li>
                </ul>
              ) : null}
            </ul>
            <div className="align-center m-top-1">
              <button
                className="button-save"
                type="button"
                onClick={() => {
                  upsertTicket();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`align-center m-top-1 fade ${!loading ? "fade-in" : ""}`}
          >
            <div className={`larger-font brand-dark-green p-bottom-1`}>
              You're all set.
            </div>
            <div className="m-bottom-1 p-bottom-1">
              Your Support Ticket has been submitted.
            </div>
            <div className="m-top-1 ">
              <button
                onClick={() => {
                  fnClose(true);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default SupportForm;
