import { useRef, useState } from "react";

import Popover from "../Utils/popover";
import Profile from "../User/profile";
import "../../Css/Home/Searchmain.css";
import baseClient from "../../api/Base";
import { useEffect } from "react";
import toolsClient from "../../Javascript/Tools";

function Searchmain({ show = false, fnClose, setGlobalMute }) {
  const [favorites, setFavorites] = useState(false);
  const [showClear, setShowClear] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [showAthleteId, setShowAthleteId] = useState();
  const [showSubscription, setShowSubscription] = useState();
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState();
  const [searchType, setSearchType] = useState("people");
  const [showAllSportsTags, setShowAllSportsTags] = useState();

  const search = useRef(null);
  const searchmain = useRef(null);
  let timer;

  const execSearch = async () => {
    try {
      setLoading(true);
      let val = search.current.value.toLocaleLowerCase();
      let resultsArray = [];
      if (val.length > 0) {
        const model = {
          filter: val,
        };
        resultsArray = await baseClient.APIPost({
          model: model,
          api: searchType === "people" ? "searchpeople" : "searchsport",
        });       
        setSearchResults(resultsArray.data);
        setTimeout(() => {
          setLoading(false);
        }, toolsClient.LOADING_DELAY);
      } else {
        setLoading(false);
        setSearchResults([]);
        setShowClear(null);
      }
    } catch (err) {
      setLoading(false);
      console.error(err.message);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const tempUser = await JSON.parse(localStorage.getItem("user"));
      setUserId(tempUser._id);
    };
    getUser();
    if (searchmain.current) {
      setTimeout(() => {
        searchmain.current.classList.add("visible");
      }, 100);
    }
  }, []);

  useEffect(() => {
    setGlobalMute(true);
  }, [setGlobalMute]);

  return (
    <div className={`search-main`}>
      {showSubscription ? (
        <Popover
          fnClose={() => {
            setShowSubscription(null);
          }}
          title={showAthleteId[1]}
          iconClass={"fa-light fa-user"}
          scrollSnap={true}
        >
          <Profile
            creatorId={showAthleteId[0]}
            fnClose={() => setShowSubscription(null)}
          />
        </Popover>
      ) : null}
      {showAthleteId ? (
        <Popover
          fnClose={() => {
            setShowAthleteId(null);
          }}
          title={showAthleteId[1]}
          iconClass={"fa-light fa-user"}
          scrollSnap={true}
        >
          <Profile
            creatorId={showAthleteId[0]}
            fnClose={() => setShowAthleteId(null)}
          />
        </Popover>
      ) : null}
      <div className={`relative`}>
        <div
          role={"button"}
          onClick={() => {
            searchmain.current.classList.remove("visible");
            setTimeout(() => {
              fnClose();
            }, 300);
          }}
          className={"sheen"}
        ></div>
        <div ref={searchmain} className={`feed-searchbar`}>
          <div className={`feed-searchbar-inner`}>
            <button
              type="button"
              className={`anchor-button search-tab ${
                searchType === "people" ? "selected" : ""
              }`}
              onClick={() => {
                search.current.value = "";
                setSearchResults([]);
                setShowClear(null);
                setSearchType("people");
                search.current.placeholder = "Search People";
                search.current.focus();
              }}
            >
              People
            </button>
            <button
              type="button"
              className={`anchor-button search-tab ${
                searchType === "sport" ? "selected" : ""
              }`}
              onClick={() => {
                search.current.value = "";
                setSearchResults([]);
                setShowClear(null);
                setSearchType("sport");
                search.current.placeholder = "Search Sport";
                search.current.focus();
              }}
            >
              Sport
            </button>
            <button
              type="button"
              className="anchor-button p-05 flex-1 align-right"
              onClick={() => {
                searchmain.current.classList.remove("visible");
                setTimeout(() => {
                  fnClose();
                }, 300);
              }}
            >
              <i className={`fa-solid fa-times`} />
            </button>
          </div>
          <div className={`search-wrapper ${showClear ? "search-grow" : ""}`}>
            <div className={`searchbar`}>
              <div className="p-right-05">
                <i className="fas fa-search icon" />
              </div>
              <div className="flex-1">
                <input
                  ref={search}
                  type="text"
                  onKeyUp={(e) => {
                    setShowClear(true);
                    if (e.target.value.length > 1) {
                      if (timer) {
                        clearTimeout(timer);
                      }
                      timer = setTimeout(() => {
                        execSearch();
                      }, 750);
                    }
                  }}
                  placeholder={`Search People ${favorites ? "Favorites" : ""}`}
                  autoFocus
                />
              </div>

              <button
                type="button"
                className={`${
                  showClear ? "" : "hidden-opacity"
                } anchor-button search-close`}
                onClick={() => {
                  search.current.value = "";
                  setSearchResults([]);
                  setShowClear(null);
                }}
              >
                <i
                  className={`fa-light fa-refresh ${
                    loading ? "fa-spin brand-green" : ""
                  }`}
                />
              </button>

              {show ? (
                <button
                  onClick={() => {
                    setFavorites((prev) => !prev);
                  }}
                  className={`favorites-filter ${favorites ? "selected" : ""}`}
                >
                  <i className="fas fa-heart" />
                </button>
              ) : null}
            </div>
            <div className={`search-results `}>
              <ul className={`no-bullets`}>
                {searchResults.map((item, key) => {
                  return (
                    <li key={`r-${key}`} className={`align-left`}>
                      <button
                        type="button"
                        className={`anchor-button wide align-left`}
                        onClick={() => {
                          setShowAthleteId([
                            item._id,
                            `${item.first_name} ${item.last_name}`,
                          ]);
                        }}
                      >
                        <div>
                          {item.first_name} {item.last_name}
                          {item.app_role === "fan" ||
                          (item.app_role === "creator" &&
                            item.publish_status !== "published") ? (
                            <span className={`small-font muted-text m-left-05`}>
                              {item._id === userId
                                ? "(This is you)"
                                : searchType === "people"
                                ? "(a fan)"
                                : item.subscribed
                                ? "(subscribed)"
                                : ""}
                            </span>
                          ) : item._id === userId ? (
                            <span className={`small-font muted-text m-left-05`}>
                              (This is you)
                            </span>
                          ) : item.subscribed ? (
                            <span className={`small-font muted-text m-left-05`}>
                              (Subscribed)
                            </span>
                          ) : null}
                        </div>
                        {item.app_role === "creator" ? (
                          <div className={`muted-text small-font`}>
                            {item.organization ? (
                              <span className={`m-right-025`}>
                                {" "}
                                {`${item.organization} - `}
                              </span>
                            ) : null}
                            {item.sports?.map((sport, index) => {
                              return (
                                <span
                                  key={`csp${index}`}
                                  className={`search-sport-item ${
                                    index > 2 && showAllSportsTags !== item._id
                                      ? "hidden"
                                      : ""
                                  }`}
                                >
                                  {sport.sport}
                                </span>
                              );
                            })}
                            {item.sports?.length > 3 && showAllSportsTags !== item._id? (
                              <span
                                role="button"
                                className={`tag`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowAllSportsTags(item._id);
                                }}
                              >
                                more
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Searchmain;
