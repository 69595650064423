import * as _ from "lodash";
import axios from "axios";

const uploadCropImage = async (file, setErrorMsg) => {
  let imageName = "";
  try {
    const url = await UploadFile(file[0]);
    imageName = url.split("/").at("-1");
    console.log(url);
    Promise.resolve(url);
  } catch (error) {
    console.log(error);
    setErrorMsg({
      title: "Image Upload error",
      message:
        "Something went wrong with Upload. Please choose your image again.",
    });
  }
  return imageName;
};

const GetSharedAccessSignature = async (fileName, timestamp) => {
  const { data } = await axios.post(
    "/addfeed/sharedaccess",
    { fileName, timestamp },
    {
      headers: { "x-access-token": JSON.parse(localStorage.getItem("token")) },
    }
  );

  return {
    SharedAccessSignature: data.sasUrl,
    GeneratedFileName: data.fileName,
  };
};

const UploadFile = async (file) => {
  try {
    const fileName = file.name.replace(/\s/g, "_");
    const sharedAccessSignatureResponse = await GetSharedAccessSignature(
      fileName,
      Date.now()
    );
    FileUpload(file, sharedAccessSignatureResponse);
    return await BeginUpload();
  } catch (e) {
    console.log(e);
  }
};

const MAX_BLOCK_SIZE = 64 * 256 * 1024;
const BLOCK_ID_PREFIX = "block-";
const DEBUG = false;

/**
 * IN PROGRESS
 * */

let blockSize;
let currentFilePointer;
//let numberOfBlocks;
//let completed;

let reader;
let blockIds;

let resolver;
//let rejecter;
let totalBytesRemaining;

let file;
let sharedAccessSignature;
//let generatedFileName;

const FileUpload = (fileToUpload, sharedAccessSignatureResponse) => {
  file = fileToUpload;
  sharedAccessSignature = sharedAccessSignatureResponse.SharedAccessSignature;
  //generatedFileName = sharedAccessSignatureResponse.GeneratedFileName;
  initialize();
};

const initialize = () => {
  blockSize = MAX_BLOCK_SIZE;
  currentFilePointer = 0;

  if (_.isNil(file)) {
    alert("no file");
  }

  if (_.isNil(file.size)) {
    alert("no file size");
    return;
  }

  if (file.size < blockSize) {
    blockSize = file.size;
  }

  totalBytesRemaining = file.size;

  /* numberOfBlocks =
    file.size % blockSize === 0
      ? file.size / blockSize
      : file.size / blockSize + 1; */
};

// -----------------------
// File Upload Process
const BeginUpload = async () => {
  return new Promise(async (resolve, reject) => {
    resolver = resolve;
    //rejecter = reject;

    if (_.isNil(sharedAccessSignature)) {
      return reject();
    }

    if (DEBUG) {
      console.log(file.name);
      console.log(sharedAccessSignature);
      alert(file.name);
      alert(sharedAccessSignature);
      return resolve();
    }

    blockIds = []; // new Array();
    reader = new FileReader();

    reader.onloadend = async (evt) => {
      if (reader.readyState !== FileReader.prototype.DONE) {
        // error?
        return;
      }

      const data = new Uint8Array(reader.result);

      if (_.isNil(data) || data.length <= 0) {
        // no data
        return;
      }
      putBlockBlob(data);
    };

    const url = await uploadFileInBlocks();
    return url;
  });
};

const uploadFileInBlocks = async () => {
  if (totalBytesRemaining > 0) {
    const fileContent = file.slice(
      currentFilePointer,
      currentFilePointer + blockSize
    );
    const blockId = BLOCK_ID_PREFIX + _.pad(blockIds.length.toString(), 6);
    blockIds.push(btoa(blockId));

    reader.readAsArrayBuffer(fileContent);

    currentFilePointer += blockSize;
    totalBytesRemaining -= blockSize;

    if (totalBytesRemaining < blockSize) {
      blockSize = totalBytesRemaining;
    }
  } else {
    //const data = await commitBlockList();
    await commitBlockList();
  }
};

/**
 * Puts a block blob to storage
 * Ref: https://docs.microsoft.com/en-us/rest/api/storageservices/put-block
 */
const putBlockBlob = async (requestData) => {
  const uri = `${sharedAccessSignature}&comp=block&blockid=${
    blockIds[blockIds.length - 1]
  }`;
  try {
    await fetch(uri, {
      headers: {
        "x-ms-blob-type": "BlockBlob",
      },
      method: "PUT",
      body: requestData,
    });
  } catch (ex) {
    alert("error");
    console.log(ex);
    return;
  }
  return await uploadFileInBlocks();
};

/**
 * Commits the block blob list to storage
 * Ref: https://docs.microsoft.com/en-us/rest/api/storageservices/put-block-list
 */
const commitBlockList = async () => {
  const uri = `${sharedAccessSignature}&comp=blocklist`;
  const requestBody = `<?xml version="1.0" encoding="utf-8"?><BlockList>${blockIds
    .map((blockId) => `<Latest>${blockId}</Latest>`)
    .join("")}</BlockList>`;
  let url = "";
  try {
    const response = await fetch(uri, {
      headers: {
        "x-ms-blob-content-type": file.type,
      },
      method: "PUT",
      body: requestBody,
    });
    url = response.url.split("?")[0];
  } catch (ex) {
    console.log(ex);
    return;
  }

  //completed = true;

  resolver(url);
};

export default uploadCropImage;
