import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const ImageUpload = ({
  chooser,
  fadeIn,
  fnClose,
  image,
  upload,
  setFadeIn,
  setShowChoose,
  toolsClient,
  setErrorMsg,
  setConfirm,
}) => {
  const { content } = upload;
  const [postingImage] = useState(false);
  
  const [imageData, setimageData] = useState({
    content,
    headline: "",
    description: "",
    type: "image",
    author: JSON.parse(localStorage.getItem("user"))._id,
    portrait: false,
    width: 0,
    height: 0,
  });

  const imageRef = useRef();

  const addImagePost = async () => {
    try {
      const { data } = await axios.post("/addfeed/addimagepost", imageData, {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
        },
      });

      if (data.success) {
        setFadeIn(null);
        setShowChoose(null);
        setTimeout(() => {
          setConfirm(true);
          setFadeIn(true);
        }, toolsClient.LOADING_DELAY);
      } else {
        setErrorMsg({
          message: "Something went wrong saving image.",
          title: "Oops",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function load(src) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", resolve);
      image.addEventListener("error", reject);
      image.src = src;
    });
  }

  useEffect(() => {
    if (imageRef.current) {
      const image = imageRef.current.src;
      //console.log("lodingimnage");
      load(image).then(() => {
        const img = new Image();
        img.src = image;
        imageData.width = img.naturalWidth;
        imageData.height = img.naturalHeight;
        const dim = img.naturalWidth / img.naturalHeight;
        if (dim < 0.8) {
          imageData.portrait = true;
        }
      });
    }
  }, [imageData]);

  return (
    <div ref={chooser} className={`fade ${fadeIn ? "fade-in" : ""}`}>
       <div className={`chooser-button-bar`}>       
        <button
          type="button"
          className={`button-save`}
          onClick={addImagePost}
          disabled={postingImage}
        >
          {postingImage && <i className="fad fa-spinner fa-spin me-2"></i>}
          Post
        </button>
      </div>
      <div className={`image-wrapper`}>
        <img
          ref={imageRef}
          src={image}
          alt="myImage"
          className={`add-content-uploaded-image`}
        />
      </div>
      <div className={`chooser-form`}>
        <form>
          <label htmlFor="headline">
            <div>
              <textarea
                id="headline"
                name="headline"
                rows="2"
                placeholder="Caption (optional)"
                onChange={(e) =>
                  setimageData((prev) => ({
                    ...prev,
                    headline: e.target.value,
                  }))
                }
              />
            </div>
          </label>
          <label htmlFor="description">
            <div>
              <textarea
                id="description"
                name="description"
                rows="4"
                placeholder="Description (optional)"
                onChange={(e) =>
                  setimageData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
          </label>
        </form>
      </div>
     
    </div>
  );
};

export default ImageUpload;
