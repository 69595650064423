import { useState } from "react";
import { useEffect } from "react";

import Cropper from "react-cropper";
import "../../Css/Utils/ImageCropper.css";
import toolsClient from "../../Javascript/Tools";

function ImageCropper({
  defaultSrc,
  fnSave,
  fnCancel,
  square = true,
  azureUploading = false,
}) {
  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [file, setFile] = useState(null);

  /*  const [preview, setPreview] = useState(); */

  /* const previewButton = useRef(null); */

  const onChange = (e) => {
    try {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      setFile(files);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
    } catch {
      //do nothing
      console.log(cropData);
    }
  };

  const getCropData = async (save = false) => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      if (save) {
        const resizedImage = await toolsClient.resizeImage(
          cropper.getCroppedCanvas(),
          square ? 400 : 750,
          square ? 400 : 500
        );
        fnSave(resizedImage, file);
      }
    }
  };

  useEffect(() => {
    if (typeof cropper !== "undefined") {
      try {
        setTimeout(() => {
          try {
            setCropData(cropper.getCroppedCanvas().toDataURL());
            //setPreview(true);
          } catch {
            //do nothing
          }
        }, 500);
      } catch (err) {
        console.log("no image yet");
      }
    }
  }, [cropper]);

  return (
    <div className={`image-cropper`}>
      <div style={{ width: "100%" }}>
        <div className={`button-bar`}>
          <button
            className={`button-cancel`}
            onClick={() => {
              fnCancel();
            }}
          >
            Cancel
          </button>
          <label role="button" htmlFor="upload" className={`button`}>
            Upload
            <input
              id="upload"
              type="file"
              accept="image/*"
              className={`hidden`}
              onChange={onChange}
            />
          </label>
          <button
            type="button"
            className={`button-save`}
            onClick={() => {
              getCropData(true);
            }}
          >
            Save
          </button>
        </div>

        <Cropper
          style={{ height: 300, width: "100%" }}
          zoomTo={1}
          initialAspectRatio={1}
          aspectRatio={square ? 1 : 1.65}
          // preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          /* cropend={() => {
            getCropData(false);
            setPreview(true);
          }}
          zoom={() => {
            getCropData(false);
            setPreview(true);
          }} */
          guides={true}
        />
        {/* <button
          ref={previewButton}
          className={`hidden`}
          onClick={() => {
            getCropData(false);
            setPreview(true);
          }}
        >
          Preview
        </button> */}
      </div>
      {/* <div>
        {preview ? (
          <div className="box">
            <div className={`large-font p-1`}>Preview</div>            
            <div
              className={`img-preview ${square ? "square" : "hero"}`}
              style={{ backgroundImage: `url(${cropData || defaultSrc})` }}
            />
          </div>
        ) : null}
      </div> */}
      <br style={{ clear: "both" }} />
    </div>
  );
}

export default ImageCropper;
