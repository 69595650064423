import { useEffect, useRef, useState } from "react";
import "../../Css/User/PublishChannel.css";
import UserSettings from "./usersettings";
import ConfirmZone from "../Utils/confirmzone";
import baseClient from "../../api/Base";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";

import AddContent from "../Feed/addcontent";
import toolsClient from "../../Javascript/Tools";
import BottomPop from "../Utils/bottompop";

function PublishChannel({ publish = "true", userId, fnClose }) {
  const [stage, setStage] = useState("good");
  const [done, setDone] = useState();
  const [editUser, setEditUser] = useState();
  const [confirm, setConfirm] = useState();
  const [badFields, setBadFields] = useState([]);
  const [confirmUnpubish, setConfirmUnpublish] = useState(false);
  const [athlete, setAthlete] = useState();
  const [dataRefresh, setDataRefresh] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [postsOnly, setPostsOnly] = useState(false);
  const [showAddNew, setShowAddNew] = useState();

  const conf = useRef(null);

  const publishChannel = async (unpublish = false) => {
    try {
      setLoading(true);
      const model = {
        user_id: userId,
      };

      const response = await baseClient.APIPost({
        model: model,
        api: unpublish ? "unpublish" : "publish",
      });
      if (response && response.code === 200) {
        let tempUser = await JSON.parse(localStorage.getItem("user"));
        tempUser.publish_status = response.data?.publish_status || 'none';
        localStorage.setItem("user", JSON.stringify(tempUser));
        setTimeout(() => {
          setLoading(false);
          setConfirm(true);
        }, toolsClient.LOADING_DELAY);
      } else {
        setLoading(false);
        setErrorMsg({
          message: "Something went wrong.",
          title: "Oops",
        });
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong publishing your channel.",
        title: "Oops",
      });
      console.log(err.message);
    }
  };

  useEffect(() => {
    const validatePublish = (valData) => {
      let tempFields = [];      
      if (valData.data.contractAcceptedAt === undefined) {
        tempFields.push({ field: "contract", label: "Creator Contract" });
        setStage("bad");
      }
      if (
        !valData.data.hero_image_url ||
        valData.data.hero_image_url.indexOf("undefined") > -1
      ) {
        tempFields.push({ field: "hero", label: "Hero Image" });
        setStage("bad");
      }

      if (
        !valData.data.avatar_url ||
        valData.data.avatar_url.indexOf("undefined") > -1
      ) {
        tempFields.push({ field: "avatar", label: "Avatar / Head Shot" });
        setStage("bad");
      }

      if (!valData.data.first_name) {
        tempFields.push("First Name");
        setStage("bad");
      }

      if (!valData.data.last_name) {
        tempFields.push({ field: "last_name", label: "Last Name" });
        setStage("bad");
      }

      if (!valData.data.nickname) {
        tempFields.push({ field: "nickname", label: "nickname" });
        setStage("bad");
      }

      if (!valData.data.phone) {
        tempFields.push({ field: "phone", label: "Phone" });
        setStage("bad");
      }

      if (!valData.data.phoneVerifiedAt) {
        tempFields.push({ field: "phone", label: "Email" });
        setStage("bad");
      }

      if (!valData.data.email) {
        tempFields.push({ field: "email", label: "Email" });
        setStage("bad");
      }

      if (!valData.data.emailVerifiedAt) {
        tempFields.push({ field: "email", label: "Email Validation" });
        setStage("bad");
      }

/*       if (!valData.data.bio) {
        tempFields.push({ field: "bio", label: "Bio" });
        setStage("bad");
      } */

      /* if (!valData.data.organization) {
        tempFields.push({ field: "organization", label: "Organization" });
        setStage("bad");
      }

      if (!valData.data.org_role) {
        tempFields.push({ field: "org_role", label: "Role" });
        setStage("bad");
      } */

      if (!valData.data.sports || valData.data.sports.length === 0) {
        tempFields.push({ field: "sport", label: "Primary Sport" });
        setStage("bad");
      }

      if (
        !valData.data.subscribe_price ||
        valData.data.subscribe_price.price === 0
      ) {
        tempFields.push({
          field: "subscribe_price",
          label: "Subscription Price",
        });
        setStage("bad");
      }

      /*  if (!valData.data.routing_nbr) {
        tempFields.push({ field: "routing_nbr", label: "Bank Routing Nbr" });
        setStage("bad");
      }

      if (!valData.data.bank_account_nbr) {
        tempFields.push({
          field: "bank_account_nbr",
          label: "Bank Account Nbr",
        });
        setStage("bad");
      } */

      if (parseInt(valData.postCount) === 0) {
        tempFields.push({ field: "posts", label: "one or more Posts" });
        setStage("bad");
      }
      setTimeout(() => {
        setBadFields(tempFields);
        if (tempFields.length > 0) {
          setStage("bad");
          if (tempFields.length === 1 && tempFields[0].field === "posts") {
            setPostsOnly(true);
          }
        }
        setDone(true);
      }, 2000);
    };

    const getData = async () => {
      try {
        setStage(null);
        let model = {
          userId: userId,
        };
        const myProfile = await baseClient.APIPost({
          model: model,
          api: "myprofile",
        });
        if (myProfile && myProfile.data) {
          setAthlete(myProfile.data);

          if (publish === "true") {
            validatePublish(myProfile);
            setDataRefresh(null);
          }
        } else {
          console.error("No data retured from server for user profile.");
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    if (!athlete || dataRefresh) {
      getData();
    }
  }, [userId, athlete, dataRefresh, publish]);

  return (
    <div className={`publish-channel fade fade-in`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {showAddNew ? (
        <BottomPop
          fnClose={() => setShowAddNew(null)}
          title="Post Content"
          fnLoaded={true}
        >
          <AddContent
            fnClose={() => {
              // fnClose();
              setDataRefresh(true);
              setShowAddNew(null);
            }}
          />
        </BottomPop>
      ) : null}
      {editUser ? (
        <UserSettings
          missing={badFields}
          fnClose={() => {
            setDataRefresh(true);
            setEditUser(null);
          }}
        />
      ) : null}

      {publish === "true" && !confirm ? (
        <div className={`p-1`}>
          <div className={`p-1 small-font`}>
            <div className={`align-left`}>PROCEDURE</div>
            <div className={`align-left`}>
              Once you've fixed reported problems (if any), you'll be able to
              Publish your channel. NOTE: After you publish, your channel will
              be in a "Pending Approval" state until the Axcess Team approves
              and certifies your Channel.
            </div>
          </div>
          {!done ? (
            <div>
              <div className={`m-1`}>Evaluating...</div>
              <div className={`p-1`}>
                <i className={`fa-light fa-gear fa-spin larger-font`} />
              </div>
            </div>
          ) : (
            <div className={`m-1`}>
              {stage === "bad" ? (
                <span className={`brand-red`}>Problems Found</span>
              ) : (
                <div>
                  Ready to publish.
                  <div className={`m-top-1`}>
                    <button
                      type="button"
                      className={`button-save`}
                      onClick={() => {
                        publishChannel();
                      }}
                    >
                      Publish
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {done ? (
            <>
              <ul className={`no-bullets`}>
                {badFields.map((item, index) => {
                  return (
                    <li key={`bad${index}`}>
                      <div className={``}>
                        <i className="fa-regular fa-octagon-exclamation brand-red icon" />
                        <span>Missing {item.label}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>

              <div className={`p-1`}>
                {stage === "bad" ? (
                  <button
                    type="button"
                    onClick={() => {
                      if (postsOnly) {
                        setShowAddNew(true);
                      } else {
                        setEditUser(true);
                      }
                      setDone(null);
                    }}
                  >
                    {postsOnly ? "Post Content" : "Fix Problems"}
                  </button>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {/* UN-PUBLISH SECTION */}
      {!confirm && publish !== "true" ? (
        <div className={`unpublish`}>
          <div className={`m-1`}>Un-Publish Channel</div>
          <h2>Are you sure?</h2>
          <h3 className={`brand-red`}>Danger Zone</h3>
          <ul className={`no-bullets`}>
            <li>
              <i className="fa-regular fa-octagon-exclamation brand-red larger-font icon m-right-1" />
              <div className="flex-1">
                All your subscribers will no longer be subscribed to you.
              </div>
            </li>
            <li>
              <i className="fa-regular fa-octagon-exclamation brand-red larger-font icon m-right-1" />
              <div className="flex-1">You will no longer be earning money.</div>
            </li>
            <li>
              <i className="fa-regular fa-octagon-exclamation brand-red larger-font icon m-right-1" />
              <div className="flex-1">
                You will not be discoverable in the app. No one can subscribe to
                you.
              </div>
            </li>
            <li>
              <i className="fa-regular fa-octagon-exclamation brand-red larger-font icon m-right-1" />
              <div className="flex-1">
                You will have to re-publish to be discoverable again.
              </div>
            </li>
            <li>
              <i className="fa-regular fa-octagon-exclamation brand-red larger-font icon m-right-1" />
              <div className="flex-1">
                If you do re-publish your channel at a later date, you will
                start with zero subscribers and zero revenue.
              </div>
            </li>
          </ul>
          <div>
            <div className={`confirm-unpublish`}>
              <label className={``}>
                <div className={``}>Type "unpublish" to confirm.</div>
                <input
                  type="text"
                  onChange={(e) => {
                    if (e.target.value === "unpublish") {
                      setConfirmUnpublish(true);
                    } else {
                      setConfirmUnpublish(false);
                    }
                  }}
                />
              </label>
              <div className={`m-1 p-1`}>
                <button
                  type="button"
                  className={`button-cancel m-05`}
                  autoFocus
                  onClick={() => {
                    fnClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`button-red m-05`}
                  onClick={() => {
                    publishChannel(true);
                  }}
                  disabled={!confirmUnpubish}
                >
                  UN-PUBLISH
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {confirm && publish ? (
        <div ref={conf} className={`m-1 `}>
          <ConfirmZone>
            <div className={`p-1`}>
              <div className={`larger-font p-1 brand-green`}>Huzzah!</div>
              <div className={`publish-success`}>
                {publish === "true" ? (
                  <div>
                    <div
                      className={`large-font brand-green p-bottom-1 align-center`}
                    >
                      Approval Pending
                    </div>
                    <div>Your channel has been submitted for approval.</div>
                    <div className={`p-top-1`}>
                      The Axcess team will notify you of your successfull
                      publish or of any issues within one business day.
                    </div>
                    <div className={`p-top-1`}>
                      After your channel has been approved, it will be
                      discoverable by other Axcess users -- AND -- you will be
                      able to share it via text message, email, and on your
                      favorite social media platforms.
                    </div>
                  </div>
                ) : (
                  <div>You have successfully unpublished your channel.</div>
                )}
              </div>
              <div className="m-1 p-1">
                <button
                  type="button"
                  className={`m-05`}
                  onClick={() => {
                    fnClose();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </ConfirmZone>
        </div>
      ) : null}
    </div>
  );
}
export default PublishChannel;
