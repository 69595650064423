import { useState } from "react";
import ErrorMsg from "../Utils/errormsg";
import TotalInviteViews from "./TotalInviteViews";
import TotalContentViews from "./TotalContentViews";
import TotalPostReports from "./TotalPostReports";
import Slideover from "../Utils/slideover";
import "../../Css/ChannelActivity/ActivityDashboard.css";


function Reports({ fnClose }) {
  const [errorMsg, setErrorMsg] = useState();
  const [allInviteViews, setAllInviteViews] = useState();
  const [allContentViews, setAllContentViews] = useState();
  const [allPostReports, setAllPostReports] = useState();

  // on click tab === all invite views which is a mapping similar to invite views for a creator
  return (
    <div className={`reports`}>
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {allInviteViews ? (
        <Slideover
          title={"Invite Views Report"}
          fnClose={() => {
            setAllInviteViews(null);
          }}
        >
          <TotalInviteViews />
        </Slideover>
      ) : null}

      {allContentViews ? (
        <Slideover
          title={"Content Views Report"}
          fnClose={() => {
            setAllContentViews(null);
          }}
        >
          <TotalContentViews />
        </Slideover>
      ) : null}

      {allPostReports ? (
        <Slideover
          title={"Post Reports"}
          fnClose={() => {
            setAllPostReports(null);
          }}
        >
          <TotalPostReports />
        </Slideover>
      ) : null}

      <div className={`p-1`}>
        <div className={`p-1 align-center b-bottom`}>
          <h2>Reports</h2>
          <div className={`p-top-1`}></div>
        </div>
        <div>
          <button
            type="button"
            className={`anchor-button brand-link-color p-1`}
            onClick={() => {
              setAllInviteViews(true);
            }}
          >
            Invite Views Reports
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`anchor-button brand-link-color p-1`}
            onClick={() => {
              setAllContentViews(true);
            }}
          >
            Content Views Reports
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`anchor-button brand-link-color p-1`}
            onClick={() => {
              setAllPostReports(true);
            }}
          >
            Post Reports
          </button>
        </div>
        <div className={`p-1 m-top-1 align-center`}>
          <button
            type="button"
            className={`button-close`}
            onClick={() => {
              fnClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
export default Reports;
