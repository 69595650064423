
import { useState } from "react";
import baseClient from "../../api/Base";

import ConfirmZone from "../Utils/confirmzone";

function DeleteContent({ contentId, fnClose }) {
  const [showConfirm, setShowConfirm] = useState();

  const deletePost = async()=> {
    console.log(contentId)
    try { 
      const model = {
        id: contentId,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "deletepost",
      });
      console.log(response);
      setShowConfirm(true);
    } catch (err) {
      console.error(err.message);
    }
  }

  return (
    <div className={`report-content`}>
      {!showConfirm ? (
        <div className="report-details">
          <div className={`larger-font brand-red p-left-1`}>Danger Zone</div>
          <div className={`p-1`}>
            This can not be undone! Are you sure you want to delete this content?
          </div>

          <div className="report-buttons">
            <button
              className="button-cancel"
              type="button"
              onClick={() => {
                fnClose();
              }}
            >
              Cancel
            </button>
            <button
              className="button-red"
              type="button"
              onClick={() => {
                deletePost();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <ConfirmZone>
          <div className="report-confirmation">
            <div className={`larger-font`}>You're all set.</div>
            <div className="m-top-1 m-bottom-1 p-bottom-1 p-top-05">
              Your content has been deleted.
            </div>
            <div className="m-top-1 p-top-1">
              <button
                onClick={() => {
                  fnClose();
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </ConfirmZone>
      )}
    </div>
  );
}
export default DeleteContent;
