import { useEffect, useState } from "react";
import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";

function TotalContentViews() {
  const [errorMsg, setErrorMsg] = useState();
  const [data, setData] = useState();
  const [sortBy, setSortBy] = useState("count");
  const [sortAsc, setSortAsc] = useState(false);
  const [fadeIn, setFadeIn] = useState("");
  const [groupBy, setGroupBy] = useState("creator");
  const [loading, setLoading] = useState(false);
  const [timeRange, setTimeRange] = useState(0);
  const [downloadBlob, setDownloadBlob] = useState();


  useEffect(() => {
    try {
      const getContentViews = async () => {
        setLoading(true);
        const model = {
          sort: sortBy || "count",
          sort_asc: sortAsc || false,
          timeRange: parseInt(timeRange),
          groupBy: groupBy,

          /*           groupBy: groupBy,
          timeRange: parseInt(timeRange), */
          /*        match_filter: matchFilter.length > 0 ? matchFilter.split(",") : [],
           */
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "allcontentviews",
        });
        setData(result.data);

                // make CSV export
                const ourData = result.data || [];
                if (ourData.length > 0) {
                  const refinedData = [];
                  const titleKeys = Object.keys(ourData[0]);
                  refinedData.push(titleKeys);
                  ourData.forEach((item) => {
                    refinedData.push(Object.values(item));
                  });
                  let csvContent = "";
                  refinedData.forEach((row) => {
                    csvContent += row.join(",") + "\n";
                  });
                  const blob = new Blob([csvContent], {
                    type: "text/csv;charset=utf-8,",
                  });
                  const objUrl = URL.createObjectURL(blob);
                  setDownloadBlob(objUrl);
                } else {
                  setDownloadBlob(null);
                }

        setTimeout(() => {
          setLoading(false);
        }, toolsClient.LOADING_DELAY);
        if (result.data.count) {
          result.data
            .map((item) => item.count)
            .reduce((prev, next) => prev + next);
        }
        setFadeIn("fade-in");
      };
      getContentViews();
    } catch (err) {
      setErrorMsg({
        message: `Something went wrong loading content Views. ${err.message}`,
        title: "Oops",
      });
      console.error(err.message);
    }
  }, [sortAsc, sortBy, groupBy, timeRange]);

  return (
    <div className={` axcess-admin-wrapper fade ${fadeIn}`}>
      {loading ? <Loading /> : null}

      <div className="p-right-1 p-left-1 ">
        {errorMsg ? (
          <ErrorMsg
            title={errorMsg.title}
            message={errorMsg.message}
            position={`fixed`}
            fnClose={() => {
              setErrorMsg(null);
            }}
          />
        ) : null}
      </div>
      <div className={`views-scroller`}>
        <div className={`flex p-05`}>
          <div className={`align-right drop-down`}>
            <select
              onChange={(e) => {
                setGroupBy(e.target.value);
              }}
              defaultValue={"creator"}
            >
              <option value={`type`}>Type</option>
              <option value={`creator`}>Creator</option>
            </select>
          </div>
          <div className={`align-left drop-down flex-1 m-left-1`}>
            <select
              onChange={(e) => {
                setTimeRange(e.target.value);
              }}
              defaultValue={"all"}
            >
              <option value={0}>All Time</option>
              <option value={30}>Last 30 Days</option>
              <option value={60}>Last 60 Days</option>
              <option value={90}>Last 90 Days</option>
            </select>
          </div>
          <a
            download={`Users_${groupBy ? groupBy : "all"}.csv`}
            href={downloadBlob}
            className={`p-05 anchor-button download 
               brand-green 
            `}
            disabled={downloadBlob ? false : true}
          >
            <i className="fa fa-file-spreadsheet" />
          </a>
        </div>

        <table className={`content-views`}>
          <thead>
            <tr>
              <th className={`align-center no-wrap`}>
                {groupBy === "creator" ? (
                  <button
                    type="button"
                    className="anchor-button small-font wide"
                    onClick={() => {
                      setSortBy("lastName");
                      setSortAsc((prev) => !prev);
                    }}
                  >
                    {groupBy}

                    {sortBy === "lastName" ? (
                      <span className={`m-left-025`}>
                        {sortAsc ? (
                          <i className={`fa-solid fa-caret-up small-font`} />
                        ) : (
                          <i className={`fa-solid fa-caret-down small-font`} />
                        )}
                      </span>
                    ) : (
                      <span className={`m-left-025`}>
                        <i className={`fa-light fa-sort`} />
                      </span>
                    )}
                  </button>
                ) : null}
                {groupBy === "type" ? (
                  <button
                    type="button"
                    className="anchor-button small-font wide"
                    onClick={() => {
                      setSortBy("type");
                      setSortAsc((prev) => !prev);
                    }}
                  >
                    {groupBy}

                    {sortBy === "type" ? (
                      <span className={`m-left-025`}>
                        {sortAsc ? (
                          <i className={`fa-solid fa-caret-up small-font`} />
                        ) : (
                          <i className={`fa-solid fa-caret-down small-font`} />
                        )}
                      </span>
                    ) : (
                      <span className={`m-left-025`}>
                        <i className={`fa-light fa-sort`} />
                      </span>
                    )}
                  </button>
                ) : null}
              </th>
              <th>
                <button
                  type="button"
                  className="anchor-button small-font wide align-right"
                  onClick={() => {
                    setSortBy("count");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Content Views
                  {sortBy === "count" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {groupBy === "creator"
              ? data?.map((item, key) => {
                  return (
                    <tr key={`v${key}`}>
                      <td className={`align-center no-wrap`}>
                        {`${item.firstName} ${item.lastName}`}
                      </td>
                      <td className={`align-right`}>{item.count}</td>
                    </tr>
                  );
                })
              : null}
            {groupBy === "type"
              ? data?.map((item, key) => {
                  return (
                    <tr key={`v${key}`}>
                      <td className={`align-center no-wrap`}>{item._id}</td>
                      <td className={`align-right`}>{item.count}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default TotalContentViews;
