import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import baseClient from "../../api/Base";
import "../../Css/Admin/Reported.css";
import DialogPop from "../Utils/dialogpop";

import FeedItem from "../Feed/feeditem";
import Popover from "../Utils/popover";
import Profile from "../User/profile";
import Slideover from "../Utils/slideover";

function Reported() {
  const [postItem, setPostItem] = useState();
  const [profile, setProfile] = useState();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [reportDetails, showReportDetails] = useState(false);
  const [filter, setFilter] = useState();
  const [status, setStatus] = useState("open");
  const [allReportIds, setAllReportIds] = useState(1);
  const [itemDetails, setItemDetails] = useState();
  const [singleReport, setSingleReport] = useState();
  const [confirmRemove, setConfirmRemove] = useState();
  const [refreshData, setRefreshData] = useState();

  const approvePost = async (item) => {
    try {
      const model = {
        id: item._id,
      };
      await baseClient.APIPost({
        model: model,
        api: "approvepost",
      });
      setConfirmRemove(null);
      setPostItem(null);
      setRefreshData(true);
    } catch (err) {
      console.error(err.message);
    }
  };
  const deletePost = async (item) => {
    try {
      const model = {
        id: item._id,
      };
      await baseClient.APIPost({
        model: model,
        api: "deletepost",
      });
      setConfirmRemove(null);
      setPostItem(null);
      setRefreshData(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getPost = async (item) => {
    try {
      const model = {
        id: item.reportPost._id,
      };
      const response = await baseClient.APIPost({
        model: model,
        api: "getpost",
      });
      setPostItem(response.data);

      let tempItems = allData?.filter(
        (x) => x.reportPost._id === item.reportPost._id
      );

      setItemDetails(tempItems);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    try {
      const getReports = async () => {
        let model = {
          filter: filter || "all",
          status: status || "open",
        };
        const response = await baseClient.APIPost({
          model: model,
          api: "postreports",
        });

        if (status !== "closed") {
          // create distinct dataset so we only show
          // on row per postId and a count of total
          // reports for that postId
          setAllData(response.data);
          let distinctIds = [];
          let tempData = [];
          for (let i = 0; i < response.data.length; i++) {
            tempData.push(response.data[i]);
          }
          let allIds = [];
          for (let i = 0; i < tempData.length; i++) {
            if (tempData[i].reportPost) {
              allIds.push(tempData[i].reportPost._id);

              if (distinctIds.indexOf(tempData[i].reportPost._id) === -1) {
                distinctIds.push(tempData[i].reportPost._id);
              } else {
                tempData.splice(i, 1);
                i -= 1;
              }
            }
          }
          setAllReportIds(allIds);
          setData(tempData);
        } else {
          setData(response.data);
        }

        setRefreshData(null);
      };
      getReports();
    } catch (err) {
      console.error(err.message);
    }
  }, [filter, status, refreshData]);

  return (
    <div className={`reported`}>
      {confirmRemove ? (
        <DialogPop
          title={`Remove Post`}
          headerIcon={`alert`}
          fnClose={() => {
            setConfirmRemove(null);
          }}
        >
          <div>
            <div className={`large-font`}>Are you sure?</div>
            <div>Perminently remove post for all users.</div>
            <div className={`p-1`}>
              <button
                type="button"
                className={`button-cancel m-05`}
                onClick={() => {
                  setConfirmRemove(null);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`button-red m-05`}
                onClick={() => {
                  deletePost(confirmRemove);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </DialogPop>
      ) : null}
      {postItem ? (
        <Popover
          title={`Post Detail`}
          iconClass={`fa-light fa-rss`}
          fnClose={() => {
            setPostItem(null);
          }}
        >
          <div className={`feed`}>
            <div className={`remove-actions small-font`}>
              {postItem.resolution !== "deleted" ? (
                <>
                  <button
                    type="button"
                    className={`anchor-button brand-red small-font`}
                    onClick={() => {
                      setConfirmRemove(postItem);
                    }}
                  >
                    Remove Post
                  </button>
                  <button
                    type="button"
                    className={`anchor-button brand-green small-font`}
                    onClick={() => {
                      approvePost(postItem);
                    }}
                  >
                    Accept Post
                  </button>
                </>
              ) : null}

              <div>
                {reportDetails ? (
                  <div>
                    {itemDetails?.length > 1 ? (
                      <select
                        className={`report-picker`}
                        onChange={(e) => {
                          let tempAarray = itemDetails?.find(
                            (x) => x._id === e.target.value
                          );
                          setSingleReport(tempAarray);
                        }}
                      >
                        {itemDetails?.map((d, index) => {
                          return (
                            <option
                              key={`d${index}`}
                              value={d._id}
                            >{`${d.reportBy?.first_name} ${d.reportBy?.last_name}`}</option>
                          );
                        })}
                      </select>
                    ) : null}

                    <div>
                      <div className={`brand-gold`}>Violation Type</div>
                      <div>{singleReport?.type}</div>
                      <div className={`brand-gold m-top-05`}>Reported By</div>
                      <div>{`${singleReport?.reportBy?.first_name} ${singleReport?.reportBy?.last_name}`}</div>
                      <div className={`brand-gold m-top-05`}>Reported Date</div>
                      <div>
                        {moment(singleReport?.createdAt).format("MM-DD-YYYY")}
                      </div>
                      <div className={`brand-gold m-top-05`}>Comment</div>
                      <div>{singleReport?.comment}</div>
                    </div>
                  </div>
                ) : null}
                <button
                  className={`anchor-button small-font`}
                  onClick={() => {
                    setSingleReport(itemDetails[0] || itemDetails);
                    showReportDetails((prev) => !prev);
                  }}
                >
                  {reportDetails ? "Hide Details" : "Show Details"}
                  <i
                    className={`fa fa-caret-${
                      reportDetails ? "up" : "down"
                    } m-left-05`}
                  />
                </button>
              </div>
            </div>
            <ul>
              {postItem.resolution !== "deleted" ? (
                <FeedItem item={postItem} index={0} />
              ) : null}
            </ul>
          </div>
        </Popover>
      ) : null}
      {/* ATHLETE PROFILE POP */}
      {profile ? (
        <Slideover
          fnClose={() => setProfile(null)}
          title={profile.name}
          scrollSnap={true}
          /* iconClass={"fa-light fa-user"} */
        >
          <Profile creatorId={profile.id} fnClose={() => setProfile(null)} />
        </Slideover>
      ) : null}
      <div className={`flex p-05`}>
        <div className={`flex-1`}>
          Reported <span className={`small-font`}>({data?.length})</span>
        </div>
        <div className={`align-right drop-down m-right-05`}>
          <select
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            defaultValue={"open"}
          >
            <option value={`open`}>Open</option>
            <option value={`closed`}>Closed</option>
            <option value={`all`}>All</option>
          </select>
        </div>
        <div className={`align-right drop-down`}>
          <select
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            defaultValue={"all"}
          >
            <option value={`all`}>All</option>
            <option value={`inappropriate`}>Inappropriate</option>
            <option value={`IP violation`}>Violates IP</option>
            <option value={`other`}>Other</option>
          </select>
        </div>
      </div>
      <table className={`small-font reported-table`}>
        <thead>
          <tr>
            <th>Creator</th>
            <th className={`align-center`}>Type</th>
            <th className={`no-wrap align-center`}>
              Reported <i className={`fa fa-caret-down`} />
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const complaintCount = allReportIds?.filter(
              (x) => x === item.reportPost?._id
            ).length;
            return (
              <tr key={`s-${index}`}>
                <td className={`relative`}>
                  <button
                    type={`button`}
                    className={`anchor-button relative`}
                    onClick={() => {
                      if (item.resolution !== "deleted") {
                        getPost(item);
                      } else {
                        setItemDetails(item);
                        setPostItem(item);
                      }
                    }}
                  >
                    {complaintCount > 1 ? (
                      <div className={`total-count`}>
                        <span>{`${complaintCount} complaints`}</span>
                      </div>
                    ) : null}

                    <div className={`brand-gold m-bottom-025`}>
                      {item.reportAuthor?.first_name}{" "}
                      {item.reportAuthor?.last_name}
                    </div>
                    <div>{item.reportPost?.headline}</div>
                  </button>
                </td>
                <td className={`align-center`}>
                  {item.type}
                  <div>{item.resolution ? item.resolution : "open"}</div>
                </td>
                <td className={`align-center`}>
                  {moment(item.reportedOn).format("MMM-DD-YY")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Reported;
