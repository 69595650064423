import { useState } from "react";
import "../../Css/Subscriptions/Subscribe.css";

import baseClient from "../../api/Base";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import { useEffect } from "react";

function Subscribe({ creator, fnClose }) {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  //const [postsCount, setPostsCount] = useState();
  const [nbrDays] = useState(30);
  const [fadeIn, setFadeIn] = useState("");
  const [productId, setProductId] = useState();
  const [waiting, setWaiting] = useState();
  const [serverMode, setServerMode] = useState();
  const [isPromotional, setIsPromotional] = useState();
  const [showAllSportsTags, setShowAllSportsTags] = useState();

  const startSubscription = () => {
    try {
      setFadeIn("");
      setWaiting(true);
      setTimeout(() => {
        setFadeIn("fade-in");
      }, 100);
      if (serverMode === "development" || isPromotional) {
        // skip in-app purchases
        const saveSubscription = async () => {
          const model = {
            mode: serverMode,
            creatorId: creator._id,
            promotional: isPromotional,
          };
          const response = await baseClient.APIPost({
            model: model,
            api: "subscribe",
          });
          if (response.data) {
            document.location.replace("/");
          }
        };
        saveSubscription();
      } else {
        // in-app purchase
        // eslint-disable-next-line no-undef
        window.gonative.purchase({ productID: productId });
        // once this call resolves it redirects automatically to subscribereceipt
      }
    } catch (err) {
      setWaiting(null);
      console.log(err.message);
      alert("You can only subscribe via the App. Not via browser.");
    }
  };

  useEffect(() => {
    setProductId(creator.apple_product_id);
  }, [creator.apple_product_id]);

  useEffect(() => {
    const logView = async () => {
      const model = {
        author_id: creator._id,
        type: "profile",
      };
      await baseClient.APIPost({
        model: model,
        api: "profileview",
      });
    };
    if (creator.promotional) {
      setIsPromotional(true);
    }
    logView();
  }, [creator]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      window.gonative.webview.clearCache();
      const getPurchaseData = async () => {
        try {
          await window.gonative.iap.refresh();
          // eslint-disable-next-line no-undef
          await window.gonative.iap.info();
          /* alert(purchaseData.inAppPurchases.platform); */
          //await baseClient.serverLogger(JSON.stringify(purchaseData));
        } catch (err) {
          baseClient.serverLogger(err.message);
          console.log(err.message);
        }
      };
      getPurchaseData();
    } catch (err) {
      baseClient.serverLogger(err.message);
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    try {
      const getPostsCount = async () => {
        const model = {
          creatorId: creator._id,
          nbrDays: nbrDays,
        };
        const response = await baseClient.APIPost({
          model: model,
          api: "postscount",
        });
        // setPostsCount(response.data);
        setServerMode(response.mode);
        setFadeIn("fade-in");
      };
      getPostsCount();
    } catch (err) {
      setLoading(false);
      console.error(
        `Something went wrong fetching posts count: ${err.message}`
      );
    }
  }, [creator._id, nbrDays]);

  return (
    <div className={`subscribe`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {waiting ? (
        /*  <Popover title="App Store Interface" iconClass="fa fa-info"> */
        <div className={`subscribe-processing fade ${fadeIn}`}>
          <div className={`larger-font p-1`}>
            <i className={`fa fa-spinner fa-spin`} />
            <span className={`m-left-1`}>Processing...</span>
          </div>
          Waiting on app store confirmation.
          <div className={`p-top-1`}>
            You will be automatically redirected to a success confirmation page.
          </div>
          {/*  <div className={`p-1 m-top-1`}>
            <button
              type="button"
              className={`m-top-1`}
              onClick={() => setWaiting(null)}
            >
              Cancel
            </button>
          </div> */}
        </div>
      ) : (
        /*  </Popover> */
        <div className={`subscribe-wrapper fade ${fadeIn}`}>
          <div className="hero">
            <div
              className="subscribe-hero-header"
              /* style={{ backgroundImage: `url(${creator.hero_image_url})` }} */
            >
              {/*  <div className={`hero-mask`}></div> */}
              <div className={`hero-inner`}>
                <div
                  className={`hero-avatar`}
                  style={{ backgroundImage: `url(${creator.avatar_url})` }}
                ></div>
                <div className={`subscribed-athlete`}>
                  <div className={`bold m-bottom-025`}>
                    Monthly Subscription To
                  </div>
                  <div
                    className={`large-font`}
                  >{`${creator.first_name} ${creator.last_name}`}</div>
                  <div>{creator.organization}</div>
                  <div>
                    {creator.sports?.map((item, index) => {
                      return (
                        <span
                          key={`csp${index}`}
                          className={`tag ${
                            index > 2 && !showAllSportsTags ? "hidden" : ""
                          }`}
                        >
                          {item.sport}
                        </span>
                      );
                    })}
                    {creator.sports?.length > 2 && !showAllSportsTags ? (
                      <span
                        role="button"
                        className={`tag`}
                        onClick={() => {
                          setShowAllSportsTags(true);
                        }}
                      >
                        <i className="fa-solid fa-ellipsis"></i>
                      </span>
                    ) : null}
                    <div>{creator.org_role}</div>
                  </div>
                  {/*  <div className={`small-font m-top-05`}>
                    {`${postsCount} ${
                      postsCount === 1 ? "post" : "posts"
                    } in past ${nbrDays} days.`}
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={`unlock-content`}>
              <div>
                <div className={`large-font m-top-025`}>
                  {isPromotional
                    ? "FREE"
                    : `$${creator?.subscribe_price?.price}/month`}
                </div>
                {/* <div className={`small-font`}>(Cancel anytime)</div> */}
              </div>
              {/* <div className={`unlock-price`}>
              <div className={"unlock-logo m-bottom-05"}>
                <img src={logo} alt={"logo"} />
              </div>
              <div className={`bold`}>Unlock Content</div>

              <div className={`large-font`}>
                {isPromotional
                  ? "FREE"
                  : `$${creator?.subscribe_price?.price}/month`}
              </div>
            </div> */}

              <div className={"unlock-features"}>
                <div className={`bold`}>Included in subscription:</div>
                <div className={`unlock-feature`}>
                  <i className={`fa-light fa-camera m-right-05`} />
                  Exclusive content.
                </div>

                <div className={`unlock-feature`}>
                  <i className={`fa-light fa-message m-right-05`} />
                  Discussions and DMs
                </div>

                <div className={`unlock-feature`}>
                  <i className={`fa-light fa-rss m-right-05`} />
                  Scheduled live streams.
                </div>
              </div>
              <div className={`unlock-total-price`}>
                <div className={`flex-1`}>
                  Total <span className={`small-font`}>(cancel any time)</span>:
                </div>
                <div className={`align-right`}>
                  {isPromotional
                    ? "FREE"
                    : `$${creator?.subscribe_price?.price}/month`}
                </div>
              </div>
              <div className={`m-bottom-1`}>
                {/*  <button
                type="button"
                className="bg-gainsboro m-right-1"
                onClick={() => {
                  fnClose();
                }}
              >
                Cancel
              </button> */}
                <button
                  type="button"
                  className={`button-save`}
                  onClick={() => {
                    startSubscription();
                  }}
                >
                  Subscribe {isPromotional ? "for Free" : null}
                </button>
              </div>
              <div className={`m-bottom-1 small-font`}>
                By tapping Subscribe you are agreeing to the
                <a
                  href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                  rel="noreferrer"
                  target="_blank"
                  className={`underline m-right-05 m-left-05 small-font brand-pink no-wrap`}
                >
                  Terms of Use
                </a>
                and
                <a
                  href="https://axcessnetwork.com/privacy-policy/"
                  rel="noreferrer"
                  target="_blank"
                  className={`underline small-font brand-pink m-left-05 no-wrap`}
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Subscribe;
