import moment from "moment";
import baseClient from "../../api/Base";

function CreatorContract({ userId, fnAccepted, fnClose }) {
  const updateContract = async () => {
    let model = {
      user_id: userId,
      contractAcceptedAt: new Date(),
    };
    await baseClient.APIPost({
      model: model,
      api: "updatemyprofile",
    });
    fnAccepted(new Date());
    fnClose();
  };
  return (
    <div className={`p-1 align-left`}>
      <p>
        This Creator Contract (the "Contract") is entered into by and between
        Axcess Network (the "Platform") and the Creator ("You" or the "Creator")
        as of {moment(new Date()).format("dddd MMMM Do YYYY")} (the "Effective
        Date"), upon acceptance of the terms outlined herein.
      </p>
      <p>
        <b className={`large-font`}>Scope of Services</b>
        <br />
        1.1 The Creator agrees to join and utilize the Axcess Network platform
        to provide content, engage with fans, and monetize their personal brand.{" "}
      </p>
      <p>
        1.2 The Creator shall have access to the features and tools provided by
        the Platform to create and share exclusive content, interact with fans,
        and monetize their influence.
      </p>
      <p>
        <b className={`large-font`}>Revenue Sharing</b> <br />
        2.1 The Creator shall be entitled to a net revenue split of 80%,
        calculated as gross revenue minus applicable taxes and fees, including
        but not limited to App Store fees, if any.{" "}
      </p>
      <p>
        2.2 The Platform shall handle the collection, processing, and distribution of all
        revenue generated through the Creator's activities on the platform.{" "}
      </p>
      <p>
        2.3 The Creator acknowledges and understands that the Platform may
        deduct applicable App Store fees, taxes or other third-party fees before
        calculating the net revenue share.
      </p>
      <p>
        <b className={`large-font`}>Intellectual Property Rights</b> <br />
        3.1 The Creator represents and warrants that they have the necessary
        rights, licenses, and permissions to create and distribute the content
        on the platform.{" "}
      </p>
      <p>
        3.2 The Creator grants the Platform a non-exclusive, worldwide,
        royalty-free license to use, reproduce, distribute, modify, and publicly
        display the content created by the Creator solely for the purposes of
        operating and promoting the Platform.{" "}
      </p>
      <p>
        3.3 The Creator shall not create or upload any lewd, offensive, or
        inappropriate content that violates the terms of service of the Platform
        or any applicable laws and regulations.
      </p>
      <p>
        <b className={`large-font`}>Content Removal</b> <br />
        4.1 The Platform reserves the right to remove any content created or
        uploaded by the Creator that is found to be offensive, lewd, or violates
        the intellectual property rights of others.{" "}
      </p>
      <p>
        4.2 The Creator agrees to promptly comply with any takedown notices
        received by the Platform in relation to their content, and to cooperate
        with the Platform in resolving any disputes or claims arising from such
        takedown notices.
      </p>
      <p>
        <b className={`large-font`}>Term and Termination</b> <br />
        5.1 This Contract shall commence on the Effective Date and continue
        until terminated by either party.{" "}
      </p>
      <p>
        5.2 Either party may terminate this Contract with a written notice to
        the other party, providing a reasonable notice period.{" "}
      </p>
      <p>
        5.3 Upon termination, the Creator shall be entitled to receive the net
        revenue share for any outstanding revenue generated prior to the
        termination date.
      </p>
      <p>
        <b className={`large-font`}>Confidentiality</b> <br />
        6.1 The Creator shall maintain the confidentiality of any non-public
        information disclosed by the Platform during the course of this
        Contract.{" "}
      </p>
      <p>
        6.2 The Creator shall not disclose any confidential information to third
        parties without the prior written consent of the Platform, except as
        required by law.
      </p>
      <p>
        <b className={`large-font`}>Governing Law and Dispute Resolution</b>{" "}
        <br />
        7.1 This Contract shall be governed by and construed in accordance with
        the laws of the State of Maryland.{" "}
      </p>
      <p>
        7.2 Any disputes arising out of or in connection with this Contract
        shall be resolved through arbitration, in accordance with the rules of
        the American Arbitration Association, by a single arbitrator appointed in
        accordance with such rules.
      </p>
      <p>
        <b className={`large-font`}>Entire Agreement</b> <br />
        8.1 This Contract constitutes the entire agreement between the parties
        concerning the subject matter hereof and supersedes all prior
        negotiations, discussions, or agreements, whether written or oral.
      </p>
      <p>
        Please review the terms of this Creator Contract carefully. By clicking
        the "I agree" button below and using the Axcess Network platform, you
        acknowledge and agree to be bound by the terms and conditions outlined
        herein.
      </p>
      <div className={`m-top-1 m-bottom-1 align-center`}>
        <button
          type="button"
          className={`button-save`}
          onClick={() => {
            updateContract();
          }}
        >
          I Agree
        </button>
      </div>
    </div>
  );
}
export default CreatorContract;
