import { useEffect, useRef, useState } from "react";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";

function ProductForm({ id, fnClose }) {
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState();
  const [price, setPrice] = useState();
  const [type, setType] = useState();
  const [axcessProdId, setAxcessProdId] = useState(
    id && id !== "new" ? id : null
  );
  const [product, setProduct] = useState();
  const [approved, setApproved] = useState(false);
  const [fadeIn, setFadeIn] = useState("");
  const productForm = useRef(null);

  const upsertProduct = async () => {
    try {
      setLoading(true);
      let model;
      model = await toolsClient.getFormModel(
        productForm.current,
        model,
        setLoading
      );
      if (!model) {
        setLoading(false);
        return;
      }
      model.price = price;
      model.axcessProdId = axcessProdId;
      model.approvedAt = approved ? new Date() : null;
      const result = await baseClient.APIPost({
        model: model,
        api: "upsertproduct",
      });
      if (!result || !result.code === 200) {
        setLoading(false);
        setErrorMsg({
          message: `Error saving product. Code: ${result?.code}`,
          title: "Oops",
        });
        return;
      }
      setAxcessProdId(result.data._id);
      setTimeout(() => {
        setLoading(false);
        fnClose();
      }, toolsClient.LOADING_DELAY);
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong saving product.",
        title: "Oops",
      });
      console.error(err.message);
    }
  };

  useEffect(() => {
    const getPrices = async () => {
      try {
        let model = [];
        const priceList = await baseClient.APIPost({
          model: model,
          api: "prices",
        });
        if (priceList && priceList.data) {
          setPrices(priceList.data);
        } else {
          console.error("No data retured from server for price list.");
        }
      } catch (err) {
        setErrorMsg({
          message: "Something went wrong getting price list.",
          title: "Oops",
        });
        console.error(err.message);
      }
    };
    getPrices();
  }, []);

  useEffect(() => {
    const getProduct = async () => {
      try {
        setLoading(true);
        const model = {
          axcessProdId: id,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "getproducts",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting product. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        const productData = result.data[0];
        setProduct(productData);
        setPrice(productData.price);
        setType(productData.type);
        setApproved(productData.approvedAt ? true : false);
        setTimeout(() => {
          setLoading(false);
          setFadeIn("fade-in");
        }, toolsClient.LOADING_DELAY);
      } catch (err) {
        setErrorMsg({
          message: `Something went wrong getting product. ${err.mesage}`,
          title: "Oops",
        });
        console.error(err.message);
      }
    };
    if (id && id !== "new") {
      getProduct();
    } else {
      setFadeIn("fade-in");
    }
  }, [id]);

  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`p-1 fade ${fadeIn}`}>
        <div className={`align-center`}>
          <h2>Product Form</h2>
          <div>For Tips, Shoutouts and Live Feeds</div>
        </div>
        <form ref={productForm} className={`m-top-1`}>
          <label htmlFor="type">
            <div>Product Type</div>
            <div>
              <select
                id="type"
                name="type"
                value={type || "tip"}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                autoFocus
              >
                <option value="livefeed">Live Feed</option>
                <option value="shoutout">Shoutout</option>
                <option value="tip">Tip</option>
              </select>
            </div>
          </label>
          <label htmlFor="name">
            <div>Product Name</div>
            <div>
              <input
                id="name"
                name="name"
                type="text"
                defaultValue={product?.name}
                required
                placeholder="Product Name"
              />
            </div>
          </label>
          <label htmlFor="appleId">
            <div>AppleID</div>
            <div>
              <input
                id="appleId"
                name="appleId"
                type="number"
                defaultValue={product?.appleId}
                placeholder="appleID (number)"
                required
              />
            </div>
          </label>
          <label htmlFor="appleProductId">
            <div>Apple ProductId</div>
            <div>
              <input
                id="appleProductId"
                name="appleProductId"
                type="text"
                defaultValue={product?.appleProductId}
                placeholder="apple productId"
                required
              />
            </div>
          </label>
          <label htmlFor="googleProductId">
            <div>Google ProductId</div>
            <div>
              <input
                id="googleProductId"
                name="googleProductId"
                defaultValue={product?.googleProductId}
                placeholder="google productId"
                type="text"
              />
            </div>
          </label>
          <label htmlFor="price">
            <div>Price</div>
            <div>
              <select
                id="price"
                name="price"
                value={price || 0}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              >
                {prices?.map((data, index) => {
                  return (
                    <option key={`sub${index}`} value={data._id}>
                      {data?.price}
                    </option>
                  );
                })}
              </select>
            </div>
          </label>
          <label htmlFor="approved">
            <div>Approved</div>
            <div className={`align-left`}>
              <input
                id="approved"
                name="apporved"
                checked={approved ? true : false}
                type="checkbox"
                style={{ width: "unset" }}
                onChange={() => {
                  setApproved((prev) => !prev);
                }}
              />
            </div>
          </label>
        </form>
        <div className={`m-top-1 p-1 align-center`}>
          <button
            type="button"
            className={`button-cancel m-025`}
            onClick={() => {
              fnClose();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`button-save m-025`}
            onClick={() => {
              upsertProduct();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default ProductForm;
