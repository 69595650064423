import { useState } from "react";
import "../../Css/ChannelActivity/ActivityDashboard.css";
import Subscribers from "./subscribers";
import ContentViews from "./contentviews";
import InviteViews from "./inviteviews";
import InviteClicks from "./invclicks";
import PostedContent from "./postedcontent";
import ProfileViews from "./profileviews";

//import Revenu from "./revenu";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";
import { useRef } from "react";
import { useEffect } from "react";
import baseClient from "../../api/Base";
import DialogPop from "../Utils/dialogpop";
import Slideover from "../Utils/slideover";

function ActivityDashboard({ creatorId }) {
  const [tab, setTab] = useState();
  const [dateFilter, setDateFilter] = useState(toolsClient.year_to_date.value);
  /* const [dateFilterText, setDateFilterText] = useState(
    toolsClient.year_to_date.display
  ); */
  const [dashData, setDashData] = useState([]);
  const [fadeIn, setFadeIn] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSalesHelp, setShowSalesHelp] = useState();

  const datepicker = useRef(null);

  useEffect(() => {
    //const mySelector = datepicker.current;
    /* if (mySelector) {
      setDateFilterText(
        mySelector.options[mySelector.options.selectedIndex].text
      );
    } */
  }, []);

  useEffect(() => {
    const getDashboardData = async () => {
      setLoading(true);
      const model = {
        days: 30,
        creatorId: creatorId,
        /* date_filter: dateFilter.length > 0 ? dateFilter.split(",") : [], */
      };
      const results = await baseClient.APIPost({
        model: model,
        api: creatorId ? "dashboardadmin" : "dashboard",
      });
      setDashData(results);
      setLoading(null);
      setFadeIn("fade-in");
    };
    getDashboardData();
  }, [creatorId]);

  return (
    <div className={`activity-dashboard`}>
      {loading ? <Loading className={`dark-loading`} /> : null}
      {tab === "subscribers" ? (
        <Slideover
          title={"Subscribers"}
          fnClose={() => {
            setTab(null);
          }}
        >
          <Subscribers
            dateFilter={dateFilter}
            creatorId={creatorId}
            dateFilterText={new Date().toLocaleString()}
            totalSubscribers={parseInt(
              dashData.subscriptionTotal
            ).toLocaleString()}
          />
        </Slideover>
      ) : null}
      {tab === "inviteViews" ? (
        <Slideover
          title={"Invite Views"}
          fnClose={() => {
            setTab(null);
          }}
        >
          <InviteViews
            dateFilter={dateFilter}
            dateFilterText={new Date().toLocaleString()}
            totalSubscribers={parseInt(
              dashData.subscriptionTotal
            ).toLocaleString()}
          />
        </Slideover>
      ) : null}
      {tab === "contentViews" ? (
        <Slideover
          title={"Content Views"}
          fnClose={() => {
            setTab(null);
          }}
        >
          <ContentViews
            dateFilter={dateFilter}
            creatorId={creatorId}
            dateFilterText={new Date().toLocaleString()}
          />
        </Slideover>
      ) : null}
      {tab === "inviteClicks" ? (
        <Slideover
          title={"Invite Clicks"}
          fnClose={() => {
            setTab(null);
          }}
        >
          <InviteClicks
            dateFilter={dateFilter}
            creatorId={creatorId}
            dateFilterText={new Date().toLocaleString()}
          />
        </Slideover>
      ) : null}
      {tab === "profileViews" ? (
        <Slideover
          title={"Profile Views"}
          fnClose={() => {
            setTab(null);
          }}
        >
          <ProfileViews
            dateFilter={dateFilter}
            dateFilterText={new Date().toLocaleString()}
            creatorId={creatorId}
            totalSubscribers={parseInt(
              dashData.subscriptionTotal
            ).toLocaleString()}
          />
        </Slideover>
      ) : null}
      {tab === "postedContent" ? (
        <Slideover
          title={"Posted Content"}
          fnClose={() => {
            setTab(null);
          }}
        >
          <PostedContent
            dateFilter={dateFilter}
            creatorId={creatorId}
            dateFilterText={new Date().toLocaleString()}
            totalSubscribers={parseInt(
              dashData.subscriptionTotal
            ).toLocaleString()}
          />
        </Slideover>
      ) : null}
      {showSalesHelp ? (
        <DialogPop
          title={`Earnings Estimate`}
          fnClose={() => {
            setShowSalesHelp(null);
          }}
        >
          <div className={`p-1`}>
            Amounts shown are gross amounts. Your collectable portion is based
            on your Creator Contract with Axcess Network.
            <div className={`p-1`}>
              <button
                type="button"
                onClick={() => {
                  setShowSalesHelp(null);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </DialogPop>
      ) : null}
      <div className={`fade ${fadeIn}`}>
        <div className={`fixed-header`}>
          {/* TABS */}
          {/* <div className={`tabs `}>
            <div className={`tab ${tab === 1 ? "active-tab" : ""}`}>
              <button
                type="button"
                className={`anchor-button small-font`}
                onClick={() => {
                  setTab(1);
                }}
              >
                <div>Views</div>
              </button>
            </div>

            <div className={`tab ${tab === 2 ? "active-tab" : ""} hidden`}>
              <button
                type="button"
                className={`anchor-button small-font`}
                onClick={() => {
                  setTab(2);
                }}
              >
                <div>Revenue</div>
              </button>
            </div>
            <div className={`tab ${tab === 3 ? "active-tab" : ""}`}>
              <button
                type="button"
                className={`anchor-button small-font`}
                onClick={() => {
                  setTab(3);
                }}
              >
                <div>Subscribers</div>
              </button>
            </div>
          </div> */}
          <div className={`date-select hidden`}>
            <label>
              <select
                ref={datepicker}
                defaultValue={dateFilter}
                onChange={(e) => {
                  setDateFilter(e.target.value);
                  /* setDateFilterText(
                    e.target.options[e.target.options.selectedIndex].text
                  ); */
                }}
              >
                <option value={[]}>all time</option>
                <option value={toolsClient.month_to_date.value}>
                  {toolsClient.month_to_date.display}
                </option>
                <option value={toolsClient.year_to_date.value}>
                  {toolsClient.year_to_date.display}
                </option>
                <option value={toolsClient.last_month.value}>
                  {toolsClient.last_month.display}
                </option>
                <option value={toolsClient.last_year.value}>
                  {toolsClient.last_year.display}
                </option>
                <option value={toolsClient.last_6_months.value}>
                  {toolsClient.last_6_months.display}
                </option>
                <option value={toolsClient.last_12_months.value}>
                  {toolsClient.last_12_months.display}
                </option>
              </select>
            </label>
          </div>
        </div>

        <div className={`summary-area`}>
          <div className={`item wide`}>
            <div className={`item-header relative`}>
              <button
                type="button"
                className={`anchor-button item-helper`}
                onClick={() => {
                  setShowSalesHelp(true);
                }}
              >
                <i className={`fa-light fa-question-circle large-font`} />
              </button>

              <div className="flex-1">
                Gross Earnings
                <div className={`small-font`}>(estimated)</div>
              </div>
            </div>
            <div className={`item-content`}>
              <div>${parseInt(dashData.totalSales).toLocaleString()}</div>
              <div className={`sub-content`}>
                <span className={`m-right-025`}>
                  Tips: ${parseInt(dashData.tipSales).toLocaleString()}
                </span>
                <span>|</span>
                <span className={`m-left-025`}>
                  Subscriptions: $
                  {parseInt(dashData.subscriptionSales).toLocaleString()}
                </span>
              </div>
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.totalChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>
          <div
            onClick={() => {
              if (dashData.subscriptionTotal) {
                setTab("subscribers");
              }
            }}
            className={`item`}
          >
            <div className={`item-header`}>
              <div className="flex-1">Subscribers</div>
            </div>
            <div className={`item-content`}>
              {parseInt(dashData.subscriptionTotal).toLocaleString()}
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.subscriptionChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>

          <div
            onClick={() => {
              if (dashData.postViewTotal) {
                setTab("contentViews");
              }
            }}
            className={`item`}
          >
            <div className={`item-header`}>
              <div className="flex-1">Content Views</div>
            </div>
            <div className={`item-content`}>
              {parseInt(dashData.postViewTotal).toLocaleString()}
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.postViewChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>
          <div
            onClick={() => {
              if (dashData.profileViewTotal) {
                setTab("profileViews");
              }
            }}
            className={`item`}
          >
            <div className={`item-header`}>
              <div className="flex-1">Profile Views</div>
            </div>
            <div className={`item-content`}>
              {parseInt(dashData.profileViewTotal).toLocaleString()}
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.profileViewChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>
          <div
            onClick={() => {
              if (dashData.postTotal) {
                setTab("postedContent");
              }
            }}
            className={`item`}
          >
            <div className={`item-header`}>
              <div className="flex-1">Posted Content</div>
            </div>
            <div className={`item-content`}>
              {parseInt(dashData.postTotal).toLocaleString()}
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.postChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>
          <div
            onClick={() => {
              if (parseInt(dashData.inviteViewTotal) > 0) {
                setTab("inviteViews");
              }
            }}
            className={`item`}
          >
            <div className={`item-header`}>
              <div className="flex-1">Invite Views</div>
            </div>
            <div className={`item-content`}>
              {parseInt(dashData.inviteViewTotal).toLocaleString()}
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.inviteViewChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>
          <div
            onClick={() => {
              if (parseInt(dashData.inviteClickTotal) > 0) {
                setTab("inviteClicks");
              }
            }}
            className={`item`}
          >
            <div className={`item-header`}>
              <div className="flex-1">Invites Clicked</div>
            </div>
            <div className={`item-content`}>
              {parseInt(dashData.inviteClickTotal).toLocaleString()}
            </div>
            <div className={`item-footer`}>
              <i className={`fa fa-arrow-up`} />
              {dashData.inviteClickChange}
              <div className={`smaller-font muted-text`}>past 30 days</div>
            </div>
          </div>
        </div>

        {/* <div className={`data-area ${tab === 3 ? "short" : ""}`}>
          {tab === 1 ? (
            <ContentViews
              dateFilter={dateFilter}
              dateFilterText={dateFilterText}
            />
          ) : null}

          {tab === 2 ? <Revenu /> : null}
          {tab === 3 ? <Subscribers /> : null}
        </div> */}
      </div>
    </div>
  );
}
export default ActivityDashboard;
