function ProfileHeader({
  creator,
  fnSubscribe,
  fnComingSoon,
  fnShowTip,
  badge,
  showAllSportsTags,
  setShowAllSportsTags,
}) {
  return (
    <li className={`relative`}>     
      <div className={`hero`}>        
        <div className="hero-header">
          <div
            className={`hero-image hero-move`}
            style={{
              backgroundImage: `url(${creator.hero_image_url})`,
            }}
          ></div>
          {creator.avatar_url &&
          creator.avatar_url.indexOf("undefined") === -1 ? (
            <div className={`avatar-wrapper`}>
              <div
                className={`hero-avatar relative`}
                style={{ backgroundImage: `url(${creator.avatar_url})` }}
              >
                <img src={badge} alt="" className={`profile-badge`} />
              </div>
              <div className={`spacer`}></div>
              <div className={`avatar-name relative`}>
                {/* <div className={`subscribe-tag`}>SUBSCRIBE TO</div> */}
                <div>
                  {`${creator.first_name.toUpperCase()} ${creator.last_name.toUpperCase()}`}
                </div>
                <div className={`org-info`}>
                  {creator.org_role}
                  <div>{creator.organization}</div>
                </div>
                {/* <div className={`subscribe-price`}>
                  {!creator.promotional
                    ? `$${creator.subscribe_price?.price} PER MONTH`
                    : `FREE`}
                </div> */}
                {/* <div className={`price-buttons`}>
                  <button className={`anchor-button flex-1 align-left`}>Unlock</button>
                  <button className={'anchor-button align-right'}>Tip</button>
                </div> */}
              </div>
              <div className="profile-description">
                <div className={`tags`}>
                  {creator.sports?.map((item, index) => {
                    return (
                      <span
                        key={`csp${index}`}
                        className={`tag ${
                          index > 2 && !showAllSportsTags ? "hidden" : ""
                        }`}
                      >
                        {item.sport}
                      </span>
                    );
                  })}
                  {creator.sports?.length > 2 && !showAllSportsTags ? (
                    <span
                      role="button"
                      className={`tag`}
                      onClick={() => {
                        setShowAllSportsTags(true);
                      }}
                    >
                      show more <i className="fa-solid fa-ellipsis"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <i className={`fa-solid fa-user hero-avatar avatar-placeholder`} />
          )}
        </div>
      </div>
       <div className="profile-action">
        <button
          type="button"
          className={`m-05`}
          onClick={() => {
            fnSubscribe(creator);
          }}
        >
          <i className={`fa fa-unlock m-right-05`} />
          {creator.promotional ? "FREE" : "Unlock"}
        </button>
        {creator.promotional ? null : (
          <button
            type="button"
            className={`m-05`}
            onClick={() => {
              fnShowTip(creator);
            }}
          >
            <i className={`fa fa-dollar m-right-05`} />
            Tip
          </button>
        )}
      </div>
    </li>
  );
}
export default ProfileHeader;
