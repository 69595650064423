import { useEffect, useState } from "react";
import baseClient from "../../api/Base";
import ErrorMsg from "../Utils/errormsg";
import Loading from "../Utils/loading";
import toolsClient from "../../Javascript/Tools";
import Popover from "../Utils/popover";
import ReasonForm from "./reasonform";

function PayReasons({ fnClose }) {
  const [reasons, setReasons] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [editReason, setEditReason] = useState();
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() => {
    try {
      const getReasons = async () => {
        setRefreshData(null);
        setLoading(true);
        const model = {};
        const result = await baseClient.APIPost({
          model: model,
          api: "getreasons",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting reasons. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        setReasons(result.data);
        setTimeout(() => {
          setLoading(false);
        }, toolsClient.LOADING_DELAY);
      };
      if (refreshData) {
        getReasons();
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: `Something went wrong loading products. ${err.message}`,
        title: "Oops",
      });
      console.error(err.message);
    }
  }, [refreshData]);

  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {editReason ? (
        <Popover
          title="Products"
          iconClass={`fa fa-money-check-dollar`}
          fnClose={() => {
            setRefreshData(true);
            setTimeout(() => {
              setEditReason(null);
            }, 100);
          }}
        >
          <ReasonForm
            fnClose={() => {
              setRefreshData(true);
              setTimeout(() => {
                setEditReason(null);
              }, 100);
            }}
            id={editReason}
          />
        </Popover>
      ) : null}
      <div className={`p-1`}>
        <div className={`p-1 align-center b-bottom`}>
          <h2>Reasons</h2>
          <div>Tips, Shoutouts and Live Feeds</div>
          <div className={`p-top-1`}>
            <button
              type="button"
              className={`anchor-button brand-link-color`}
              onClick={() => {
                setEditReason("new");
              }}
            >
              <i className={`fa-solid fa-plus-circle m-right-05`} />
              Add New Reason
            </button>
          </div>
        </div>
        {reasons.map((item, index) => {
          return (
            <div
              key={`reas${index}`}
              className={`p-05 b-bottom align-left flex`}
            >
              <button
                type="button"
                className={`anchor-button brand-link-color flex-1 align-left`}
                onClick={() => {
                  setEditReason(item._id);
                }}
              >
                {item.reason}
              </button>
              <div className={`align-right`}>
                <div>{`Type: ${item.reason_type}`}</div>
                <div>{`Active: ${item.active}`}</div>
              </div>
            </div>
          );
        })}
        <div className={`p-1 m-top-1 align-center`}>
          <button
            type="button"
            className={`button-close`}
            onClick={() => {
              fnClose();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
export default PayReasons;
