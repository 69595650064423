
import "../../Css/Help/Help.css";
import BottomPop from "../Utils/bottompop";
function PublishHelp({ fnClose }) {
  return (
    <BottomPop
      title={`Channel Status Info`}
      fnLoaded={true}
      fnClose={() => {
        if (fnClose) {
          fnClose();
        }
      }}
    >
      <div className={`help`}>
        
        <div className={`help-body`}>
          <div className={`help-topic`}>
            <div className={`help-topic-header`}>DRAFT</div>
            <p>
              This is the default or initial state of your channel. This is the
              time for your to complete and polish up your profile and add your
              initial batch of compelling videos and images to your channel.
              Your channel will stay in draft status until you request publish.
            </p>
          </div>
          <div className={`help-topic`}>
            <div className={`help-topic-header`}>SUBMITTED</div>
            <p>
              Once you’ve requested to publish, the Axcess team will be alerted
              that it’s time to review your channel including your profile and
              posts.
            </p>
          </div>
          <div className={`help-topic`}>
            <div className={`help-topic-header`}>APPROVED</div>
            <p>
              The Axcess team has tagged your channel as approved for
              publication and submitted your channel to the app stores for final
              approval. You will be alerted via email that your channel has been
              approved.
            </p>
          </div>
          <div className={`help-topic`}>
            <div className={`help-topic-header`}>LIVE</div>
            <p>
              Your channel has been approved and published by the app stores.
              Please note that it can take the app stores up to two weeks to
              approve. You will be notified via email when your channel has gone
              live.
            </p>
          </div>
        </div>
        {/* <div className={`help-footer`}>
          <button
            type="button"
            onClick={() => {
              fnClose();
            }}
          >
            Close
          </button>
        </div> */}
      </div>
    </BottomPop>
  );
}
export default PublishHelp;
