import "../../Css/Home/WelcomeHome.css";
import CenterPop from "../Utils/centerpop";

function WelcomeHome({user, fnClose, fnTour}) {
  return (
    <CenterPop
      title={`Welcome to Axcess`}
      fnClose={() => {
        fnClose();
      }}
    >
      <div className={`welcome-home`}>
        <div className={`larger-font`}>Hi {user.first_name}</div>
        <div>We're gald you're here!</div>
        <div className={`m-top-1`}>You've joined the premier network for exlusive sports content.</div>
        <div className={`m-top-1 p-top-1`}>
          <button
            type="button"
            className={`button-save`}            
            onClick={() => {
              fnTour();
            }}
          >Take the Tour</button>         
        </div>
      </div>
    </CenterPop>
  );
}
export default WelcomeHome;
