import { useEffect, useState } from "react";
import "../../Css/Utils/CenterPop.css";
import logo from "../../Graphics/AxcessIconNew.png";

function CenterPop({ children, title, fnClose, className, hasFooter }) {
  const [fadeIn, setFadeIn] = useState("");

  useEffect(() => {
    let fadeTimer;
    fadeTimer = setTimeout(() => {
      setFadeIn("fade-in");
    }, 100);
    return () => {
      clearTimeout(fadeTimer);
    }
  }, []);

  return (
    <div className={`center-pop fade ${fadeIn}`}>
      <div
        className={`sheen`}
      ></div>
      <div
        className={`center-pop-inner  ${
          className ? "className" : ""
        }`}
      >
        <div className={`center-pop-children`}>
          <div className={`pop-header`}>
            <div className={`p-right-025`}>
              <img className={`header-logo`} src={logo} alt="" />
            </div>
            <div className={`flex-1 align-left`}>{title}</div>
            <div>
              <button
                type="button"
                className={`anchor-button`}
                onClick={() => {
                  fnClose();
                }}
              >
                <i className={`fa-solid fa-times`} />
              </button>
            </div>
          </div>
          <div
            className={`center-pop-content ${hasFooter ? "footer-space" : ""}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CenterPop;
