import "../../Css/User/Profile.css";
import { useEffect, useState } from "react";
import UserSettings from "./usersettings";
import PublishChannel from "./publishchannel";
import Popover from "../Utils/popover";
import baseClient from "../../api/Base";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import toolsClient from "../../Javascript/Tools";
import ReferFriend from "./referfriend";
import heroDefault from "../../Graphics/BackgroundDefault.png";
import avatarDefault from "../../Graphics/AvatarGeneric2.png";
import FooterMini from "../Home/footermini";
import DialogPop from "../Utils/dialogpop";
import PublishHelp from "../Help/publishhelp";
import Subscribe from "../Subscriptions/subscribe";
import Tips from "../Products/tips";
import BottomPop from "../Utils/bottompop";
import AlertDot from "../Utils/alertdot";

function Profile({ creatorId, allowAdmin = false, onRefresh }) {
  const [creator, setCreator] = useState([]);
  const [heroImage, setHeroImage] = useState();
  const [avatar, setAvatar] = useState();
  const [showUserSettings, setShowUserSettings] = useState();
  const [showPublish, setShowPublish] = useState();
  /* const [showAddMenu, setShowAddMenu] = useState(); */
  const [showBio, setShowBio] = useState();
  //const [success, setSuccess] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [userId, setUserId] = useState();
  const [bio, setBio] = useState();
  const [refreshData, setRefreshData] = useState();
  const [publishStatus, setPublishStatus] = useState();
  const [showShare, setShowShare] = useState();

  const [showSubscribe, setShowSubscribe] = useState();
  const [appRole, setAppRole] = useState();
  const [comingSoon, setComingSoon] = useState();
  const [showPublishHelp, setShowPublishHelp] = useState();
  const [subscribed, setSubscribed] = useState();
  const [showTip, setShowTip] = useState();


  const handleRefresh = async () => {
    setFadeIn("");
    await onRefresh(true);
    setTimeout(() => {
      setFadeIn("fade-in");
    }, 2000);
  };

  useEffect(() => {});

  useEffect(() => {
    try {
      const logView = async () => {
        const model = {
          author_id: creatorId,
          type: "profile",
        };
        await baseClient.APIPost({
          model: model,
          api: "profileview",
        });
      };

      const getData = async () => {
        try {
          setLoading(true);                    
          let model = {
            userId: creatorId,//' || user_id,
          };
          const myProfile = await baseClient.APIPost({
            model: model,
            api: "profile",
          });
          if (myProfile && myProfile.data) {
            setUserId(myProfile.testId);
            if (myProfile.testId === creatorId) {
              localStorage.setItem("user", JSON.stringify(myProfile.data));
              setIsAdmin(true);
            }
            setCreator(myProfile.data);
            if (
              myProfile.data.avatar_url &&
              myProfile.data.avatar_url.indexOf("undefined") > -1
            ) {
              setAvatar(avatarDefault);
            } else {
              setAvatar(myProfile.data.avatar_url);
            }
            if (
              myProfile.data.hero_image_url &&
              myProfile.data.hero_image_url.indexOf("undefined") > -1
            ) {
              setHeroImage(heroDefault);
            } else {
              setHeroImage(myProfile.data.hero_image_url);
            }
            //setSubscribed(myProfile.subscribed);

            setAppRole(myProfile.data.app_role || "fan");
            setPublishStatus(myProfile.data.publish_status || "none");
            setSubscribed(myProfile.subscribed);
            if (myProfile.data.bio && myProfile.data.bio.length > 0) {
              setBio(myProfile.data.bio);
            }

            if (refreshData) {
              setRefreshData(null);
            }
            logView();
            setTimeout(() => {
              setLoading(false);
              setFadeIn(true);
            }, toolsClient.LOADING_DELAY);
          } else {
            setLoading(false);
            console.error("No data retured from server for user profile.");
          }
        } catch (err) {
          setLoading(false);
          setErrorMsg({
            message: "Something went wrong fetching user profile.",
            title: "Oops",
          });
          console.error(`error: ${err.message}`);
        }
      };
      if (creator.length === 0 || refreshData) {
        getData();
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong loading screen.",
        title: "Oops",
      });
      console.error(err.message);
    }
  }, [creatorId, refreshData, creator, allowAdmin]);

  return (
    <div className={`profile`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {showTip ? (
        <Tips
          creator={creator}
          fnClose={() => {
            setShowTip(null);
          }}
        />
      ) : null}
      {showPublishHelp ? (
        <PublishHelp
          fnClose={() => {
            setShowPublishHelp(null);
          }}
        />
      ) : null}
      <div className={`fade ${fadeIn ? "fade-in" : ""}`}>
        {showShare ? (
          <Popover
            title="Share My Channel"
            iconClass={`fa-solid fa-up-from-bracket`}
            fnClose={() => {
              setShowShare(null);
            }}
          >
            <ReferFriend type="channel" creatorId={userId} />
          </Popover>
        ) : null}
        {/* {subscribeConfirm ? (
          <DialogPop
            title={`Unsubscribe`}
            headerIcon={`question`}
            fnClose={() => {
              setSubscribeConfirm(null);
            }}
          >
            <div>
              {success ? (
                <div>
                  <div className={`large-font`}>Success!</div>
                  <div className={`p-1`}>
                    You have unsubscribed from {creator.name}
                  </div>
                  <div>
                    <a
                      className={`m-1 link-button bg-brand-dark-green`}
                      onClick={() => {
                        setSubscribeConfirm(null);
                        //document.location = "/";
                      }}
                      href="/"
                    >
                      Close
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    Are you sure you want to unsubscribe from {creator.name}?
                  </div>
                  <div className={`m-top-1`}>
                    <button
                      type="button"
                      className="m-05 button-cancel"
                      onClick={() => {
                        setSubscribeConfirm(null);
                      }}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="m-05 button-save"
                      onClick={() => {
                        unsubscribe();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              )}
            </div>
          </DialogPop>
        ) : null} */}
        {comingSoon ? (
          <DialogPop title={"Coming Soon"} fnClose={() => setComingSoon(null)}>
            <div>This feature is coming soon!</div>
            <div className="m-top-1">
              <button
                type="button"
                className={`button button-cancel m-05`}
                onClick={() => {
                  setComingSoon(null);
                }}
              >
                Close
              </button>
            </div>
          </DialogPop>
        ) : null}
        {/* {showAddMenu ? (
          <Popover
            fnClose={() => setShowAddMenu(null)}
            title="Post Content"
            iconClass={"fa-light fa-camera"}
          >
            <AddContent
              fnClose={() => {
                setRefreshData(true);
                setShowAddMenu(null);
              }}
            />
          </Popover>
        ) : null} */}
        {showSubscribe ? (
          <div className={`subscribe discover-feed align-center`}>
            <BottomPop
              title={`Unlock Content`}
              fnClose={() => {
                setShowSubscribe(null);
              }}
              fnLoaded={true}
            >
              <Subscribe
                fnClose={() => {
                  setShowSubscribe(null);
                }}
                creator={creator}
              />
            </BottomPop>
          </div>
        ) : null}
        {showPublish ? (
          <Popover
            title={`${
              showPublish === "true" ? "Publish Channel" : "Unpublish Channel"
            }`}
            fnClose={() => setShowPublish(null)}
            iconClass={`fa-light fa-rss`}
          >
            <PublishChannel
              publish={showPublish}
              userId={userId}
              fnClose={() => {
                setRefreshData(true);
                setShowPublish(null);
              }}
            />
          </Popover>
        ) : null}

        {showUserSettings ? (
          <UserSettings
            fnClose={() => {
              setShowUserSettings(false);
              setRefreshData(true);
            }}
          />
        ) : null}
        {appRole === "creator" && isAdmin ? (
          <div className={`status-wrapper`}>
            <div className={`p-025 m-025 m-top-1 b-bottom`}>
              Channel Status
              <button
                type="button"
                className={`anchor-button p-025`}
                onClick={() => {
                  setShowPublishHelp(true);
                }}
              >
                <i
                  className={`fa fa-question-circle m-left-025 brand-link-color large-font`}
                />
              </button>
            </div>
            <div className={`publish-status`}>
              <div
                className={`status ${
                  publishStatus === "none" ? "selected" : ""
                }`}
              >
                DRAFT
                <i className={`fa fa-caret-up caret`} />
              </div>
              <div className={`status`}>
                <i className={`fa fa-chevron-right`} />
              </div>
              <div
                className={`status ${
                  publishStatus === "requested" ? "selected" : ""
                }`}
              >
                SUBMITTED
                <i className={`fa fa-caret-up caret`} />
              </div>
              <div className={`status`}>
                <i className={`fa fa-chevron-right`} />
              </div>
              <div
                className={`status ${
                  publishStatus === "approved" ? "selected" : ""
                }`}
              >
                APPROVED
                <i className={`fa fa-caret-up caret`} />
              </div>
              <div className={`status`}>
                <i className={`fa fa-chevron-right`} />
              </div>
              <div
                className={`status ${
                  publishStatus === "published" ? "selected" : ""
                }`}
              >
                LIVE
                <i className={`fa fa-caret-up caret`} />
              </div>
            </div>
            <div className="admin-buttons">
              <button
                type="button"
                className={`anchor-button brand-link-color small-font`}
                onClick={() => {
                  setShowUserSettings(true);
                }}
              >
                <i className={`fa-solid fa-edit m-right-025`} />
                Edit Channel
              </button>
              {publishStatus === "published" ? (
                <button
                  className="anchor-button brand-link-color small-font"
                  onClick={() => {
                    setShowShare(true);
                  }}
                >
                  <i className={`fa-solid fa-up-from-bracket m-right-025`} />
                  Share
                </button>
              ) : null}
              <button
                type="button"
                className={`${publishStatus} anchor-button small-font brand-link-color`}
                onClick={() => {
                  if (publishStatus === "none") {
                    setShowPublish("true");
                  } else {
                    setShowPublish("false");
                  }
                }}
              >
                <i
                  className={`fa-solid ${
                    publishStatus === "published"
                      ? "fa-triangle-exclamation"
                      : "fa-rss"
                  } m-right-025`}
                />
                {publishStatus === "none" ? (
                  <span className={`relative inline-block`}>
                    Publish Now
                    <AlertDot top={-3} right={-13} color="orange" />
                  </span>
                ) : (
                  "Unpublish"
                )}
              </button>
            </div>
          </div>
        ) : null}
        <div className={`hero`}>
          <div
            className="hero-image relative"
            style={{ backgroundImage: `url(${heroImage || heroDefault})` }}
          >
            {subscribed ? (
              <div className="ribbon ribbon-top-left">
                <span>subscribed</span>
              </div>
            ) : null}
          </div>
          <div className={`align-center avatar-wrapper`}>
            <div
              className={`hero-avatar`}
              style={{ backgroundImage: `url(${avatar || avatarDefault})` }}
            ></div>
          </div>
          <div className="hero-header">
            <div className="profile-description">
              <h1>
                {`${creator?.first_name} ${creator?.last_name}`}
                {/* <span className={`small-font m-left-05`}>
                  {`@${creator?.nickname}`}
                </span> */}
              </h1>
              <div className="profile-bio">
                <div>{creator?.organization}</div>
                <div className={`tags`}>
                  {creator.sports?.map((item, index) => {
                    return (
                      <span key={`csp${index}`} className={`tag`}>
                        {item.sport}
                      </span>
                    );
                  })}
                </div>
                <div>{creator?.org_role ? `${creator.org_role} ` : null}</div>
                <div className={`m-top-1`}>
                  {bio ? (
                    <button
                      type="button"
                      className={`anchor-button brand-link-color ${
                        showBio ? "hidden" : ""
                      }`}
                      onClick={() => {
                        setShowBio(true);
                      }}
                    >
                      Show Bio
                    </button>
                  ) : null}
                </div>
                {showBio ? (
                  <div className={`bio`}>
                    <div>{bio}</div>
                    <div>
                      <button
                        type="button"
                        className={`anchor-button brand-link-color m-top-025`}
                        onClick={() => {
                          setShowBio(false);
                        }}
                      >
                        Hide Bio
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="profile-actions">
              {!isAdmin &&
              appRole === "creator" &&
              publishStatus === "published" ? (
                <div>
                  <div className={`action-buttons`}>
                    <button
                      type="button"
                      className="profile-action button-save"
                      onClick={() => {
                        setShowTip(true);
                      }}
                    >
                      <i className="fa-light fa-dollar-sign m-bottom-1" />
                      <div>Tip</div>
                    </button>
                    {/*                     <button
                      type="button"
                      className="profile-action"
                      onClick={() => {
                        setComingSoon(true);
                      }}
                    >
                      <i className="fa-light fa-bullhorn m-bottom-1" />
                      <div>Shoutout</div>
                    </button>
                    <button
                      type="button"
                      className="profile-action"
                      onClick={() => {
                        setComingSoon(true);
                      }}
                    >
                      <i className="fa-light fa-message-dots m-bottom-1" />
                      <div>Message</div>
                    </button> */}
                    {!subscribed ? (
                      <button
                        type="button"
                        className="profile-action button-save"
                        onClick={() => {
                          setShowSubscribe(true);
                        }}
                      >
                        <i className="fa-light fa-unlock m-bottom-1" />
                        <div>Subscribe</div>
                      </button>
                    ) : null}
                  </div>
                  <div>
                    {subscribed ? (
                      <a
                        href={`/home?cid=${creator?._id}`}
                        className="brand-link-color inline-block p-1"
                      >
                        View Posts
                      </a>
                    ) : null}
                    {creator?.merch_link ? (
                      <button
                        type="button"
                        className={`anchor-button brand-link-color inline-block p-1`}
                        onClick={() => {
                          window.open(creator?.merch_link, "_blank");
                        }}
                      >
                        Merch Site
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {isAdmin &&
              appRole === "creator" &&
              publishStatus === "published" ? (
                <div>
                  <a
                    href={`/home?cid=${creator?._id}`}
                    className="brand-link-color inline-block p-1"
                  >
                    View Posts
                  </a>
                  {creator?.merch_link ? (
                    <button
                      type="button"
                      className={`anchor-button brand-link-color inline-block p-1`}
                      onClick={() => {
                        window.open(creator?.merch_link, "_blank");
                      }}
                    >
                      Merch Site
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>

          {/* {!isAdmin && !allowAdmin && appRole === "creator" && subscribed ? (
            <div className="align-center">
              <button
                type="button"
                className="anchor-button brand-red"
                onClick={() => {
                  setSubscribeConfirm(true);
                }}
              >
                UNSUBSCRIBE
              </button>
            </div>
          ) : null} */}
        </div>
        {allowAdmin ? (
          <div className={`p-1 align-center`}>
            <button
              type="button"
              className={`bg-brand-gold small-font m-025`}
              onClick={() => {
                window.open(`/home?cid=${creatorId}`, "_blank");
              }}
            >
              Preview Feed
            </button>
            <button
              type="button"
              className={`bg-brand-gold small-font m-025`}
              onClick={() => {
                setShowSubscribe(true);
              }}
            >
              Preview Subscribe
            </button>
          </div>
        ) : null}
      </div>
      {appRole === "creator" && isAdmin ? (
        <FooterMini
          onRefresh={() => {
            if (onRefresh) {
              handleRefresh();
            }
          }}
          testId={userId}
        />
      ) : null}
    </div>
  );
}
export default Profile;
