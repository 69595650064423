import { useState } from "react";
import baseClient from "../../api/Base";
import DialogPop from "../Utils/dialogpop";
import ErrorMsg from "../Utils/errormsg";
import Popover from "../Utils/popover";
import Products from "./products";
import PayReason from "./payreasons";
import Sports from "./sports";
import Reports from "./managementreports";

function AdminFunctions() {
  const [refreshConfirm, setRefreshConfirm] = useState();
  const [refreshDone, setRefreshDone] = useState();
  const [errMsg, setErrMsg] = useState();
  const [openProducts, setOpenProducts] = useState();
  const [openReasons, setOpenReasons] = useState();
  const [openSports, setOpenSports] = useState();
  const [openReports, setOpenReports] = useState();

  const alertNewAppVersion = async () => {
    try {
      const model = {};
      const result = await baseClient.APIPost({
        model: model,
        api: "schedulenewversion",
      });
      if (result && result.code === 200) {
        setRefreshDone(true);
      } else {
        setErrMsg({
          message: "Something went wrong.",
          title: "Oh no",
        });
      }
    } catch (err) {
      setErrMsg({
        message: `error setRefresApp: ${err.message}`,
        title: "Error",
      });
      console.log(`error setRefresApp: ${err.message}`);
    }
  };

  return (
    <div>
      {errMsg ? (
        <ErrorMsg
          title={errMsg.title}
          message={errMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrMsg(null);
          }}
        />
      ) : null}
      {openReasons ? (
        <Popover
          title="Pay Reasons"
          iconClass={`fa fa-face-tongue-money`}
          fnClose={() => {
            setOpenReasons(null);
          }}
        >
          <PayReason
            fnClose={() => {
              setOpenReasons(null);
            }}
          />
        </Popover>
      ) : null}
      {openProducts ? (
        <Popover
          title="Products"
          iconClass={`fa fa-money-check-dollar`}
          fnClose={() => {
            setOpenProducts(null);
          }}
        >
          <Products
            fnClose={() => {
              setOpenProducts(null);
            }}
          />
        </Popover>
      ) : null}
      {openSports ? (
        <Popover
          title="Sports"
          iconClass={`fa fa-soccer-ball-o`}
          fnClose={() => {
            setOpenSports(null);
          }}
        >
          <Sports
            fnClose={() => {
              setOpenSports(null);
            }}
          />
        </Popover>
      ) : null}
      {openReports ? (
        <Popover
          title="Reports"
          iconClass={`fa fa-print`}
          fnClose={() => {
            setOpenReports(null);
          }}
        >
          <Reports
            fnClose={() => {
              setOpenReports(null);
            }}
          />
        </Popover>
      ) : null}
      {refreshConfirm ? (
        <DialogPop
          headerIcon="question"
          title="Refresh App"
          fnClose={() => {
            setRefreshConfirm(null);
          }}
        >
          <div>
            {refreshDone ? (
              <div>
                <div className={`large-font`}>You're all set.</div>
                <p>The app has been set for user refresh.</p>
                <div className={`p-top-1`}>
                  <button
                    type="button"
                    onClick={() => {
                      setRefreshConfirm(null);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className={`large-font`}>Are you sure?</div>
                <p>
                  This will alert each user that there is a new version and
                  force a refresh the next time they are in the app.
                </p>
                <div className={`p-top-1`}>
                  <button
                    type="button"
                    className={`button-cancel m-05`}
                    onClick={() => {
                      setRefreshConfirm(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`button-save m-05`}
                    onClick={() => {
                      alertNewAppVersion();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            )}
          </div>
        </DialogPop>
      ) : null}
      <div className={`p-1`}>
        <div className={`p-bottom-025 b-bottom`}>Administrative Functions</div>
        <div className={`m-1 p-top-1`}>
          <button
            type="button"
            className={`anchor-button brand-link-color`}
            onClick={() => {
              setRefreshConfirm(true);
            }}
          >
            <i className={`fa fa-refresh m-right-05`} />
            Refresh App Version for All Users
          </button>
        </div>
        <div className={`m-1 p-top-1`}>
          <button
            type="button"
            className={`anchor-button brand-link-color align-left`}
            onClick={() => {
              setOpenReasons(true);
            }}
          >
            <i className={`fa fa-face-tongue-money m-right-05`} />
            Manage Pay Reasons (Tipping, etc.)
          </button>
        </div>
        <div className={`m-1 p-top-1`}>
          <button
            type="button"
            className={`anchor-button brand-link-color`}
            onClick={() => {
              setOpenProducts(true);
            }}
          >
            <i className={`fa fa-money-check-dollar m-right-05`} />
            Manage Products
          </button>
        </div>
        <div className={`m-1 p-top-1`}>
          <button
            type="button"
            className={`anchor-button brand-link-color`}
            onClick={() => {
              setOpenSports(true);
            }}
          >
            <i className={`fa fa-soccer-ball-o m-right-05`} />
            Manage Sports
          </button>
        </div>
        <div className={`m-1 p-top-1`}>
          <button
            type="button"
            className={`anchor-button brand-link-color`}
            onClick={() => {
              setOpenReports(true);
            }}
          >
            <i className={`fa fa-print m-right-05`} />
            Management Reports
          </button>
        </div>
      </div>
    </div>
  );
}
export default AdminFunctions;
