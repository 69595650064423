import { useRef, useState, useEffect, useCallback } from "react";
import NoSleep from "nosleep.js";
import "../../Css/Feed/AddContent.css";
import ErrorMsg from "../Utils/errormsg";
import toolsClient from "../../Javascript/Tools";
import ConfirmZone from "../Utils/confirmzone";
import ImageUpload from "./ImageUpload";
import VideoUpload from "./VideoUpload";
import useUpload from "../../hooks/useUpload";
import axios from "axios";
import baseClient from "../../api/Base";

const chunkSize = 2 * 256 * 1024;

function AddContent({ fnClose, serverMode }) {
  const [showButtons, setShowButtons] = useState(true);
  const [showChoose, setShowChoose] = useState();
  const [showCapture, setShowCapture] = useState();
  const [fadeIn, setFadeIn] = useState();
  const [confirm, setConfirm] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [progress, setProgress] = useState();
  const [isCompressed, setIsCompressed] = useState();
  const [processing, setProcessing] = useState();
  const [noSleep, setNoSleep] = useState(new NoSleep());

  const chooser = useRef(null);
  const inputDom = useRef(null);
  const imageHolder = useRef(null);

  const upload = useUpload();
  const { uploadUrl } = upload;

  const enableNoSleep = () => {
    try {
      noSleep.enable();
    } catch (err) {
      console.log(`noSleep error: ${err.name} ${err.message}`);
      baseClient.serverLogger(`noSleep error: ${err.name} ${err.message}`);
    }
  };

  useEffect(() => {   
    return () => {
      try {     
      noSleep.disable();      
      setNoSleep(null);
    } catch (err) {
      console.log(`disable noSleep error: ${err.name} ${err.message}`);
      baseClient.serverLogger(`disable noSleep error: ${err.name} ${err.message}`);
    }
    };
  }, [noSleep]);

  useEffect(() => {
    if (uploadUrl !== "") {
      setImage(uploadUrl);
      setShowButtons(null);
      setShowChoose(true);
      setTimeout(() => {
        setFadeIn(true);
      }, 100);
    }
  }, [uploadUrl]);

  const uploadChunk = useCallback(
    (readerEvent) => {
      try {
        const file = inputDom.current.files[0];
        const data = readerEvent.target.result;
        const params = new URLSearchParams();
        params.set("name", file.name);
        params.set("size", file.size);
        params.set("landscape", false);
        params.set("currentChunkIndex", currentChunkIndex);
        params.set("totalChunks", Math.ceil(file.size / chunkSize));
        params.set("type", "image");
        const headers = { "Content-Type": "application/octet-stream" };
        const urlBase = "https://axcesscron.herokuapp.com/";
        /* const urlBase =
        serverMode === "development"
          ? "http://localhost:3002/"
          : "https://axcesscron.herokuapp.com/"; */
        const url = urlBase + "upload?" + params.toString();
        axios
          .post(url, data, { headers })
          .then((response) => {
            const filesize = file.size;
            const chunks = Math.ceil(filesize / chunkSize) - 1;
            const isLastChunk = currentChunkIndex === chunks;
            if (isLastChunk) {
              //file.finalFilename = response.data.finalFilename;
              setCurrentChunkIndex(null);
              setProgress(100);
              setIsCompressed(true);
              setLoading(true);
              // console.log(response.data);

              const toDataUrl = (url, callback) => {
                var xhr = new XMLHttpRequest();
                xhr.onload = function () {
                  callback(xhr.response);
                };
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
              };

              let newImage;
              toDataUrl(`${urlBase}temp/${response.data}`, function (x) {
                newImage = x;
                const myFile = new File([newImage], response.data, {
                  type: "image/*",
                });

                // Now let's create a FileList
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(myFile);
                inputDom.current.files = dataTransfer.files;
                //console.log(inputDom.current.files[0]);
                upload.upload(myFile);
                const deleteParams = new URLSearchParams();
                deleteParams.set("name", response.data);
                const deleteHeaders = {
                  "Content-Type": "application/json",
                };
                axios
                  .post(
                    `${urlBase}delete?${deleteParams.toString()}`,
                    {},
                    { deleteHeaders }
                  )
                  .then((response) => {
                    console.log(`deleteResponse: ${response?.data?.message}`);
                  });
              });

              // Help Safari out
              /* if (inputDom.current.files.webkitEntries.length) {
            inputDom.current.files.dataset.file = `${dataTransfer.files[0].name}`;
          } */
            } else {
              setCurrentChunkIndex(currentChunkIndex + 1);
              setProgress(Math.round(((currentChunkIndex + 1) / chunks) * 100));
            }
          })
          .catch((error) => {
            setCurrentChunkIndex(null);
            setErrorMsg({
              message: "Something went wrong uploading chunck.",
              title: "Oops!",
            });
            baseClient.serverLogger(
              `addContent.jsx/uploadChunk: ${error.message}`
            );
          });
      } catch (err) {
        setCurrentChunkIndex(null);
        setErrorMsg({
          message: "Something went wrong uploading chunck.",
          title: "Oops!",
        });
        baseClient.serverLogger(`addContent.jsx/uploadChunk: ${err.message}`);
      }
    },
    [currentChunkIndex, upload]
  );

  const readAndUploadCurrentChunk = useCallback(
    (e) => {
      try {
        const file = inputDom.current.files[0];
        const reader = new FileReader();
        if (!file) {
          return;
        }
        const from = currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(from, to);
        reader.onload = (e) => uploadChunk(e);
        reader.readAsDataURL(blob);
      } catch (err) {
        setCurrentChunkIndex(null);
        setErrorMsg({
          message: "Something went wrong uploading current chunck.",
          title: "Oops!",
        });
        baseClient.serverLogger(
          `addContent.jsx/readAndUploadCurrentChunk: ${err.message}`
        );
      }
    },
    [currentChunkIndex, uploadChunk]
  );

  const startUploadProcess = (e) => {
    try {
      setProcessing(true);
      const currentFile = e.target.files[0];
      const imageElement = imageHolder.current;
      imageElement.src = currentFile;
      setCurrentChunkIndex(0);
      setProgress(0);
    } catch (err) {
      setCurrentChunkIndex(null);
      setErrorMsg({
        message: "Something went wrong initiating upload process.",
        title: "Oops!",
      });
      baseClient.serverLogger(
        `addContent.jsx/startUploadProcess: ${err.message}`
      );
    }
  };

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex, readAndUploadCurrentChunk]);

  return (
    <div className={`add-content`}>
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className={`fade fade-in`}>
        {showButtons ? (
          <div className={`margin-auto chooser-buttons`}>
            <div className="chooser-block">
              <div className={`${processing || loading ? "hidden" : ""}`}>
                <div>
                  <label
                    role="button"
                    htmlFor="upload"
                    className={`button-save button`}
                    onClick={() => {
                      enableNoSleep();
                    }}
                  >
                    <i
                      className={`${
                        upload.loading
                          ? "fad fa-spinner fa-spin"
                          : "fa-light fa-camera"
                      } chooser-icon`}
                    ></i>
                    Picture
                    <input
                      ref={inputDom}
                      id="upload"
                      type="file"
                      className={`hidden`}
                      accept="image/*"
                      onChange={(e) => {
                        startUploadProcess(e);
                        //upload.upload(e.target.files[0]);
                      }}
                    />
                  </label>
                  <img
                    ref={imageHolder}
                    className={`hidden`}
                    src=""
                    alt="holder"
                  />
                </div>
                <div>Upload or snap</div>
              </div>

              {processing ? (
                <div>
                  <div
                    className={`p-1 bg-brand-background m-left-05 m-right-05 small-font`}
                  >
                    <div>PROCESSING...</div>
                    Don't let your device sleep or screen-lock.
                  </div>
                  <div className={`processing`}>
                    {progress > 0 && progress < 100 ? (
                      <div className={`p-05 border-box`}>
                        <div
                          className={`bg-brand-pink border-box round-10 p-025`}
                          style={{ width: `${progress}%` }}
                        >
                          {progress}%
                        </div>
                      </div>
                    ) : progress === 0 ? (
                      <div className={`p-05 border-box`}>
                        <div className={`border-box round-10 p-025`}>
                          Fetching image from device
                          <i className={`fa fa-spinner fa-spin m-left-05`} />
                        </div>
                      </div>
                    ) : null}

                    {progress === 100 && !isCompressed ? (
                      <div className={`p-05 border-box`}>
                        <div
                          className={`bg-brand-pink border-box round-10 p-025`}
                          style={{ width: `${progress}%` }}
                        >
                          Compressing
                          <i className={`fa fa-spinner fa-spin m-left-05`} />
                        </div>
                      </div>
                    ) : null}

                    {isCompressed && loading ? (
                      <div className={`p-05 border-box`}>
                        <div
                          className={`bg-brand-pink border-box round-10 p-025`}
                          style={{ width: `${progress}%` }}
                        >
                          Finishing Up
                          <i className={`fa fa-spinner fa-spin m-left-05`} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>

            <div className={`chooser-block ${processing ? "hidden" : ""}`}>
              <div className={`margin-auto`}>
                <label
                  role="button"
                  className={`button button-save`}
                  onClick={() => {
                    enableNoSleep();
                    setShowButtons(null);
                    setShowCapture(true);
                    setTimeout(() => {
                      setFadeIn(true);
                    }, 100);
                    /* getMedia(); */
                  }}
                >
                  <i className="fa-light fa-video chooser-icon"></i>Video
                </label>
              </div>
              <div>Upload or record</div>
            </div>
          </div>
        ) : null}

        {showChoose ? (
          <ImageUpload
            {...{
              chooser,
              fadeIn,
              fnClose,
              image,
              upload,
              setFadeIn,
              setShowChoose,
              toolsClient,
              setErrorMsg,
              setConfirm,
            }}
          />
        ) : null}

        {showCapture ? (
          <VideoUpload
            {...{
              fadeIn,
              fnClose,
              setFadeIn,
              setShowCapture,
              setConfirm,
              serverMode,
            }}
          />
        ) : null}

        {confirm ? (
          <ConfirmZone>
            <div className={`confirm-wrapper`}>
              <div className={"larger-font brand-dark-green"}>Hooya!</div>
              <div className={`p-1`}>Your content has been posted.</div>
              <div className={`m-top-1`}>
                <button
                  type="button"
                  onClick={() => {
                    fnClose();
                  }}
                  className={`button-save`}
                >
                  Finish
                </button>
              </div>
            </div>
          </ConfirmZone>
        ) : null}
      </div>
    </div>
  );
}
export default AddContent;
