import { useEffect, useState } from "react";
import BottomPop from "../Utils/bottompop";
import Popover from "../Utils/popover";
import PublishChannel from "../User/publishchannel";
import ReferFriend from "../User/referfriend";

function CreatorAlerts({ alert, user, fnClose, setRefreshData }) {
  const [startPublish, setStartPublish] = useState();
  const [startShare, setStartShare] = useState();
  const [showAlert, setShowAlert] = useState(true);
  const [headline, setHeadline] = useState();
  const [message, setMessage] = useState();
  const [clickFn, setClickFn] = useState();

  function Alert() {
    return (
      <BottomPop
        title={`Notice`}
        fnClose={() => {
          fnClose();
        }}
        /* height={'100vh'} */
        fnLoaded={true}
      >
        <div className={`p-bottom-1`}>
          <div className={`pop-window`}>
            <div className={`large-font`}>Hi {user.first_name}.</div>
            <div className={`bold brand-red p-05`}>{headline}</div>
            <div>{message}</div>
          </div>
          <div className={`p-1 m-bottom-1 m-top-1`}>
            <button
              type="button"
              className={`button-save`}
              onClick={() => {
                clickFn();
                setShowAlert(null);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </BottomPop>
    );
  }

  useEffect(() => {
    switch (alert) {
      case "share":
        setHeadline("Share your channel with the world.");
        setMessage(
          "In order to attract fans and maximize earnings, you should share your channel with your social media followers."
        );
        setClickFn(() => (x) => setStartShare(true));
        break;
      default:
        setHeadline("Your channel is not published.");
        setMessage(
          "In order attract fans and start making money on Axcess, you first need to publish your channel."
        );
        setClickFn(() => (x) => setStartPublish("true"));
        break;
    }
  }, [alert]);

  return (
    <div>
      {showAlert ? <Alert /> : null}
      {startShare ? (
        <Popover
          title={`Share Channel`}
          fnClose={() => setStartShare(null)}
          iconClass={`fa-light fa-rss`}
        >
          <ReferFriend type="channel" creatorId={user._id} />
        </Popover>
      ) : null}
      {startPublish ? (
        <Popover
          title={`${
            startPublish === "true" ? "Publish Channel" : "Unpublish Channel"
          }`}
          fnClose={() => setStartPublish(null)}
          iconClass={`fa-light fa-rss`}
        >
          <PublishChannel
            publish={startPublish}
            userId={user._id}
            fnClose={() => {
              setRefreshData(true);
              setStartPublish(null);
              fnClose();
            }}
          />
        </Popover>
      ) : null}
    </div>
  );
}
export default CreatorAlerts;
