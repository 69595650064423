import { useEffect, useState } from "react";
import "../../Css/User/UserSettings.css";
import "cropperjs/dist/cropper.css";
import heroDefault from "../../Graphics/BackgroundDefault.png";
import avatarDefault from "../../Graphics/AvatarGeneric2.png";
import DialogPop from "../Utils/dialogpop";
import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import ImageCropper from "../Utils/imgcropper";
import Popover from "../Utils/popover";
import SportsPicker from "../Welcome/sportspicker";
import uploadCropImage from "../../hooks/uploadCropImage";
import EditPhone from "./editphone";
import EditEmail from "./editemail";
import CreatorContract from "./creatorcontract";

function UserSettings({ fnClose, missing }) {
  //TODO: We should require validation via text msg to open this..
  //TODO: We need to validate routing nbr using some API service

  const [slideIn, setSlideIn] = useState();
  const [fadeIn, setFadeIn] = useState();
  const [showNotice, setShowNotice] = useState();
  const [sports, setSports] = useState();
  const [appRole, setAppRole] = useState();
  const [subscribePrice, setSubscribePrice] = useState();
  const [shoutPrice, setShoutPrice] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [phoneNbr, setPhoneNbr] = useState();
  const [showImageCropper, setShowImageCropper] = useState();
  const [avatar, setAvatar] = useState();
  const [heroImage, setHeroImage] = useState();
  const [azureHeroImage, setAzureHeroImage] = useState();
  const [azureAvtarImage, setAzureAvtarHeroImage] = useState();
  const [azureUploading, setAzureUploading] = useState(false);
  const [newHero, setNewHero] = useState();
  const [newAvatar, setNewAvatar] = useState();
  const [editSports, setEditSports] = useState();
  const [emailVerified, setEmailVerified] = useState();
  const [userEmail, setUserEmail] = useState();
  const [editPhone, setEditPhone] = useState();
  const [phoneVerified, setPhoneVerified] = useState();
  const [subscriptionPrices, setSubscriptionPrices] = useState();
  const [shoutoutPrices, setShoutoutPrices] = useState();
  const [publishStatus, setPublishStatus] = useState();
  const [editContract, setEditContract] = useState();
  const [contractAgreed, setContractAgreed] = useState();
  const subsribeBasePrice = 4.99;
  const shoutoutBasePrice = 19.99;

  const userForm = useRef(null);
  const sportCtl = useRef(null);
  const subPrice = useRef(null);

  const updateUser = async () => {
    try {
      setLoading(true);
      let model;
      model = await toolsClient.getFormModel(
        userForm.current,
        model,
        setLoading
      );

      if (!model) {
        setLoading(false);
        return;
      }
      if (model.merch_link?.length > 0) {
        if (!toolsClient.validateUrl(model.merch_link)) {
          // bad url link format. bail out.
          setLoading(false);
          setErrorMsg({
            message:
              "You have not entered a valid URL format for your Merch Link. Make sure you have format: https://...",
            title: "Oops",
          });
          return;
        }
      }
      let goodPhone = false;
      if (phoneNbr && isPossiblePhoneNumber(phoneNbr)) {
        goodPhone = true;
      }
      if (!goodPhone) {
        setLoading(false);
        setErrorMsg({
          message: "Doesn't look like a valid international phone number.",
          title: "Oops",
        });
        console.error("Unknown error missing phone number.");
        return;
      }
      /*       if (contractAgreed) {
        model.CreatorContract
      } */
      if (avatar && newAvatar) {
        model.avatar = azureAvtarImage;
      }
      if (heroImage && newHero) {
        model.hero_image = azureHeroImage;
      }
     
      if (publishStatus !== "none") {
        // don't allow published, approved, or requested creators to change these fields
        delete model["app_role"];
        delete model["subscribe_price"];
        delete model["shoutout_price"];
      }

      //model['app_role'].remove()
      const updatedUser = await baseClient.APIPost({
        model: model,
        api: "updatemyprofile",
      });

      if (updatedUser && updatedUser.data) {
        // set local storate with current user data
        localStorage.setItem("user", JSON.stringify(updatedUser.data));
        setTimeout(() => {
          setLoading(false);
          fnClose();
        }, toolsClient.LOADING_DELAY);
      } else {
        setLoading(false);
        setErrorMsg({
          message: "Something went wrong.",
          title: "Oops",
        });
        console.error("No data retured from server for updated user.");
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong.",
        title: "Oops",
      });
      console.error(`Something went wrong saving updated user: ${err.message}`);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);

      const getPrices = async () => {
        try {
          let model = [];
          const priceList = await baseClient.APIPost({
            model: model,
            api: "prices",
          });
          if (priceList && priceList.data) {
            const tempSubscribePrices = [];
            const tempShoutoutPrices = [];
            priceList.data.map((item, index) => {
              if (item.price === 0 || item.price >= subsribeBasePrice) {
                tempSubscribePrices.push(item);
              }
              if (item.price === 0 || item.price >= shoutoutBasePrice) {
                tempShoutoutPrices.push(item);
              }
              return true;
            });
            setSubscriptionPrices(tempSubscribePrices);
            setShoutoutPrices(tempShoutoutPrices);
          } else {
            console.error("No data retured from server for price list.");
          }
        } catch (err) {
          setErrorMsg({
            message: "Something went wrong getting price list.",
            title: "Oops",
          });
          console.error(err.message);
        }
      };

      const getData = async () => {
        try {
          const tempUser = await JSON.parse(localStorage.getItem("user"));
          
          if (!tempUser) {          
            setErrorMsg({
              message: "You're no longer logged in.",
              title: "Oops",
            });
            console.error("no localstorage user found");
          }
          let model = {};
          const myProfile = await baseClient.APIPost({
            model: model,
            api: "myprofile",
          });
          if (myProfile && myProfile.data) {
            localStorage.setItem("user", JSON.stringify(myProfile.data));
            setUser(myProfile.data);
            setAppRole(myProfile.data.app_role);
            setSports(myProfile.data.sports);
            setSubscribePrice(myProfile.data.subscribe_price?._id);
            setShoutPrice(myProfile.data.shoutout_price);
            setPhoneNbr(myProfile.data.phone);
            setUserEmail(myProfile.data.email);
            setAvatar(myProfile.data.avatar_url);
            setPublishStatus(myProfile.data.publish_status);
            setContractAgreed(myProfile.data.contractAcceptedAt);
            setHeroImage(
              myProfile.data.hero_image ? myProfile.data.hero_image_url : null
            );
            if (myProfile.data.emailVerifiedAt) {
              setEmailVerified(true);
            } else {
              setEmailVerified(false);
            }
            if (myProfile.data.phoneVerifiedAt) {
              setPhoneVerified(true);
            } else {
              setPhoneVerified(false);
            }
            await getPrices();
            setTimeout(() => {
              setLoading(false);
              setFadeIn(true);
              //setShowNotice(true);
            }, toolsClient.LOADING_DELAY);
          } else {
            setLoading(false);
            console.error("No data retured from server for user profile.");
          }
        } catch (err) {
          setLoading(false);
          setErrorMsg({
            message: "Something went wrong fetching user profile.",
            title: "Oops",
          });
          console.error(err.message);
        }
      };

      getData();
      setSlideIn(true);
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong loading screen.",
        title: "Oops",
      });
      console.error(err.message);
    }
  }, []);

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `new_file.${mime.split("/").at("-1")}`, {
      type: mime,
    });
  };

  return (
    <div className={`user-settings ${slideIn ? "visible fade-in" : ""}`}>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      <div className="user-menu-header">
        <button
          onClick={() => {
            setSlideIn(null);
            setTimeout(() => {
              if (fnClose) {
                fnClose();
              } else {
                document.location = "/";
              }
            }, 100);
          }}
          className="button-cancel back-button"
        >
          Cancel
        </button>

        <div className="flex-1">Edit Profile</div>
        <button
          type="button"
          className="button-save"
          onClick={() => {
            updateUser();
          }}
          disabled={azureUploading}
        >
          Save
        </button>
      </div>
      <div className={`user-settings-inner fade ${fadeIn ? "fade-in" : ""}`}>
        {editPhone ? (
          <DialogPop
            title={`${phoneNbr ? "Edit Phone" : "Add Phone"}`}
            fnClose={() => {
              setEditPhone(null);
            }}
            headerIcon={`edit`}
          >
            <EditPhone
              phoneNbr={phoneNbr}
              setPhoneNbr={setPhoneNbr}
              fnVerified={(val) => {
                setPhoneVerified(val);
              }}
              fnClose={() => {
                setEditPhone(null);
              }}
            />
          </DialogPop>
        ) : null}

        {showNotice ? (
          <DialogPop
            title={`Suggestion`}
            fnClose={() => {
              setShowNotice(null);
            }}
            headerIcon={`alert`}
          >
            <div>
              <div>Please complete your profile.</div>
              <div className={`p-1 m-top-1`}>
                <button
                  type="button"
                  onClick={() => {
                    setShowNotice(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </DialogPop>
        ) : null}

        {editContract ? (
          <Popover
            title={`Creator Contract`}
            iconClass={`fa-light fa-edit`}
            fnClose={() => {
              setEditContract(null);
            }}
          >
            <CreatorContract
              userId={user._id}
              fnAccepted={(val) => {
                setContractAgreed(val);
              }}
              fnClose={() => {
                setEditContract(null);
              }}
            />
          </Popover>
        ) : null}
        {editSports ? (
          <Popover
            title={`Edit Sports`}
            iconClass={`fa-light fa-edit`}
            fnClose={() => {
              setEditSports(null);
            }}
          >
            <SportsPicker
              fnDone={(newSports) => {
                if (newSports) {
                  setSports(newSports);
                }
                setEditSports(null);
              }}
              className={`sports-from-settings`}
              editMode={true}
            />
          </Popover>
        ) : null}

        {showImageCropper ? (
          <Popover
            title={`Image Cropper`}
            iconClass={`fa-light fa-camera`}
            fnClose={() => {
              setShowImageCropper(false);
            }}
          >
            <ImageCropper
              azureUploading={azureUploading}
              defaultSrc={showImageCropper === "avatar" ? avatar : heroImage}
              fnSave={(obj, file) => {
                if (showImageCropper === "avatar") {
                  setNewAvatar(true);
                  setAzureUploading(true);
                  uploadCropImage([dataURLtoFile(obj)], setErrorMsg)
                    .then((data) => {
                      setAzureAvtarHeroImage(data);
                      setAzureUploading(false);
                    })
                    .catch((err) => setAzureUploading(true));
                  setAvatar(obj);
                } else {
                  /** uploading files on azure */
                  if (typeof file === "object") {
                    setAzureUploading(true);
                    uploadCropImage([dataURLtoFile(obj)], setErrorMsg)
                      .then((data) => {
                        setAzureHeroImage(data);
                        setAzureUploading(false);
                      })
                      .catch((err) => setAzureUploading(true));
                  }

                  setHeroImage(obj);
                  setNewHero(true);
                }
                setShowImageCropper(false);
              }}
              fnCancel={() => {
                setShowImageCropper(false);
              }}
              square={showImageCropper === "avatar"}
            />
          </Popover>
        ) : null}

        <div className="settings-form">
          <div
            className={`hero-image ${
              missing?.filter((m) => m.field === "hero").length > 0
                ? "required"
                : ""
            }`}
          >
            <button
              type="button"
              className="anchor-button camera-button"
              onClick={() => {
                setShowImageCropper("hero");
              }}
            >
              <i className="fa-solid fa-camera" />
            </button>
            <img alt="_hero" src={`${heroImage ? heroImage : heroDefault}`} />
          </div>
          <div
            className={`m-bottom-1 m-top-1 relative ${
              missing?.filter((m) => m.field === "avatar").length > 0
                ? "required"
                : ""
            }`}
          >
            <button
              type="button"
              className="anchor-button camera-button"
              onClick={() => {
                setShowImageCropper("avatar");
              }}
            >
              <i className="fa-solid fa-camera" />
            </button>

            <img
              className="settings-avatar"
              src={avatar || avatarDefault}
              alt="av"
            />
          </div>
          <div className={"required-instruct"}>
            <i className="fa fa-asterisk" />
            <span className={"flex-1"}>= Required field.</span>
          </div>
          <form ref={userForm}>
            <label htmlFor="app_role">
              <div className={`form-label`}>
                Creator?
                {publishStatus !== "none" ? (
                  <span className="brand-dark-green small-font m-left-05">
                    <i className="fa fa-lock m-right-025" />
                    {publishStatus}
                  </span>
                ) : null}
              </div>
              <div>
                <select
                  id="app_role"
                  name="app_role"
                  disabled={publishStatus === "none" ? false : true}
                  value={appRole}
                  onChange={(e) => {
                    setAppRole(e.target.value);
                  }}
                >
                  <option key={`role1`} value={"fan"}>
                    No
                  </option>
                  <option key={`role2`} value={"creator"}>
                    Yes
                  </option>
                </select>
              </div>
            </label>
            <label htmlFor="first_name">
              <div className={"form-label label-required"}>First Name</div>
              <div>
                <input
                  id="first_name"
                  name="first_name"
                  required
                  className={
                    missing?.filter((m) => m.field === "first_name").length > 0
                      ? "required"
                      : ""
                  }
                  type="text"
                  placeholder="First Name "
                  defaultValue={user?.first_name}
                  onBlur={(e) => {
                    toolsClient.checkRequired(e);
                  }}
                />
              </div>
            </label>
            <label htmlFor="last_name">
              <div className={"form-label label-required"}>Last Name</div>
              <div>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  required
                  className={
                    missing?.filter((m) => m.field === "last_name").length > 0
                      ? "required"
                      : ""
                  }
                  placeholder="Last Name"
                  defaultValue={user?.last_name}
                  onBlur={(e) => {
                    toolsClient.checkRequired(e);
                  }}
                />
              </div>
            </label>
            <label htmlFor="nickname">
              <div className={`form-label label-required`}>User Name</div>
              <div>
                <input
                  id="nickname"
                  name="nickname"
                  type="text"
                  required
                  className={
                    missing?.filter((m) => m.field === "nickname").length > 0
                      ? "required"
                      : ""
                  }
                  placeholder="@username"
                  defaultValue={user?.nickname}
                  onBlur={(e) => {
                    toolsClient.checkRequired(e);
                  }}
                />
              </div>
            </label>
            <div className={`form-label`}>
              <span className={`flex`}>
                <span className={`flex-1 small-font label-required`}>
                  Mobile
                </span>
                {phoneNbr && !phoneVerified ? (
                  <button
                    type="button"
                    className={`anchor-button tag validate small-font block`}
                    onClick={() => {
                      setEditPhone(true);
                    }}
                  >
                    Validate
                  </button>
                ) : phoneNbr ? (
                  <span>
                    <i
                      className={`fa-solid fa-check-circle m-right-025 brand-dark-green`}
                    />
                    <span className={`small-font muted-text`}>Validated</span>
                  </span>
                ) : null}
              </span>
              <div className={`phone-field`}>
                <PhoneInput
                  placeholder="Mobile Phone"
                  value={phoneNbr}
                  onChange={setPhoneNbr}
                  disabled={true}
                  className={
                    missing?.filter((m) => m.field === "phone").length > 0
                      ? "required"
                      : ""
                  }
                />
                <button
                  type="button"
                  className={`anchor-button m-left-05 m-right-05`}
                  onClick={() => {
                    setEditPhone(true);
                  }}
                >
                  <i className={`fa-light fa-edit`} />
                </button>
              </div>
            </div>
            <EditEmail
              emailVerified={emailVerified}
              setEmailVerified={setEmailVerified}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              missing={missing}
            />
            <div
              className={`form-label
                  ${
                    missing?.filter((m) => m.field === "contract").length > 0
                      ? "required"
                      : ""
                  }
                `}
            >
              <span className={`flex`}>
                <span className={`flex-1 small-font `}>Creator Contract</span>
                {!contractAgreed ? (
                  <button
                    type="button"
                    className={`anchor-button tag validate small-font block`}
                    onClick={() => {
                      setEditContract(true);
                    }}
                  >
                    Accept Contract
                  </button>
                ) : (
                  <span>
                    <i
                      className={`fa-solid fa-check-circle m-right-025 brand-dark-green`}
                    />
                    <span className={`small-font muted-text`}>Accepted</span>
                  </span>
                )}
              </span>
              <div className={`phone-field`}>
                {!contractAgreed ? (
                  <span className={`flex-1 p-025`}>Not Accepted</span>
                ) : (
                  <span className={`flex-1 p-025`}>Accepted</span>
                )}
                <button
                  type="button"
                  className={`anchor-button m-left-05 m-right-05`}
                  onClick={() => {
                    setEditContract(true);
                  }}
                >
                  <i
                    className={`fa-light ${
                      contractAgreed ? "fa-eye" : "fa-edit"
                    }`}
                  />
                </button>
              </div>
            </div>
            <label htmlFor="bio">
              <div className={`form-label`}>Bio</div>
              <div>
                <textarea
                  id="bio"
                  name="bio"
                  rows="4"
                  className={
                    missing?.filter((m) => m.field === "bio").length > 0
                      ? "required"
                      : ""
                  }
                  placeholder="Bio"
                  defaultValue={user?.bio}
                ></textarea>
              </div>
            </label>
            <label htmlFor="sports">
              <div className={`form-label`}>Sports</div>
              <div className={`sports-field`}>
                <span
                  ref={sportCtl}
                  className={`flex-1
                    ${
                      missing?.filter((m) => m.field === "sports").length > 0
                        ? "required"
                        : ""
                    }
                  `}
                >
                  {sports?.map((sport, index) => {
                    return (
                      <span key={`sport${index}`} className={`tag`}>
                        {sport.sport}
                      </span>
                    );
                  })}
                </span>
                <button
                  type="button"
                  className={`anchor-button m-left-05 m-right-05`}
                  onClick={() => {
                    setEditSports(true);
                  }}
                >
                  <i className={`fa-light fa-edit`} />
                </button>
              </div>
            </label>
            {appRole === "creator" ? (
              <>
                <label htmlFor="organization">
                  <div className={`form-label`}>Organization</div>
                  <div>
                    <input
                      id="organization"
                      name="organization"
                      type="text"
                      className={
                        missing?.filter((m) => m.field === "organization")
                          .length > 0
                          ? "required"
                          : ""
                      }
                      placeholder="Organization / Team"
                      defaultValue={user?.organization}
                    />
                  </div>
                </label>
                <label htmlFor="org_role">
                  <div className={`form-label`}>Role</div>
                  <div>
                    <input
                      id="org_role"
                      name="org_role"
                      type="text"
                      className={
                        missing?.filter((m) => m.field === "org_role").length >
                        0
                          ? "required"
                          : ""
                      }
                      placeholder="Role / Position"
                      defaultValue={user?.org_role}
                    />
                  </div>
                </label>
                <label htmlFor="subscribe_price">
                  <div className={"form-label"}>
                    Subscription Price/mo. ($USD)
                    {publishStatus !== "none" ? (
                      <span className="brand-dark-green small-font m-left-05">
                        <i className="fa fa-lock m-right-025" />
                        {publishStatus}
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <select
                      ref={subPrice}
                      id="subscribe_price"
                      name="subscribe_price"
                      value={subscribePrice || 0}
                      disabled={publishStatus === "none" ? false : true}
                      className={
                        missing?.filter((m) => m.field === "subscribe_price")
                          .length > 0
                          ? "required"
                          : ""
                      }
                      onChange={(e) => {
                        setSubscribePrice(e.target.value);
                      }}
                    >
                      {subscriptionPrices?.map((data, index) => {
                        return (
                          <option key={`sub${index}`} value={data._id}>
                            {data?.price}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </label>
                <label htmlFor="shoutout_price">
                  <div className={`form-label`}>
                    Shoutout Price ($USD)
                    {publishStatus !== "none" ? (
                      <span className="brand-dark-green small-font m-left-05">
                        <i className="fa fa-lock m-right-025" />
                        {publishStatus}
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <select
                      id="shoutout_price"
                      name="shoutout_price"
                      disabled={publishStatus === "none" ? false : true}
                      value={shoutPrice || 0}
                      onChange={(e) => {
                        setShoutPrice(e.target.value);
                      }}
                    >
                      {shoutoutPrices?.map((data, index) => {
                        return (
                          <option key={`shout${index}`} value={data._id}>
                            {data?.price}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </label>
                <label htmlFor="merch_link">
                  <div className={`form-label`}>Merch Link</div>
                  <div>
                    <input
                      id="merch_link"
                      name="merch_link"
                      type="text"
                      placeholder="Merch Link, ex: https://mymerchlink.com"
                      defaultValue={user?.merch_link}
                    />
                  </div>
                </label>
                {/* <label htmlFor="bank_account_nbr">
                  <div className={`form-label`}>Bank Account Nbr</div>
                  <div>
                    <input
                      id="bank_account_nbr"
                      name="bank_account_nbr"
                      type="number"
                      className={
                        missing?.filter((m) => m.field === "bank_account_nbr")
                          .length > 0
                          ? "required"
                          : ""
                      }
                      placeholder="Bank Account Nbr"
                      defaultValue={user?.bank_account_nbr}
                    />
                  </div>
                </label>
                <label htmlFor="routing_nbr">
                  <div className={`form-label`}>Bank Routing Nbr</div>
                  <div>
                    <input
                      id="routing_nbr"
                      name="routing_nbr"
                      type="number"
                      className={
                        missing?.filter((m) => m.field === "routing_nbr")
                          .length > 0
                          ? "required"
                          : ""
                      }
                      placeholder="Bank Routing Nbr"
                      defaultValue={user?.routing_nbr}
                    />
                  </div>
                </label> */}
              </>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
}
export default UserSettings;
