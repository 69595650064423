import "../../Css/Home/Home.css";
import Feed from "../Feed/feed";
import Discover from "../Feed/discover";
import { useEffect, useRef, useState } from "react";
import HintPop from "./hintpop";
import baseClient from "../../api/Base";
import Loading from "../Utils/loading";
import ErrorMsg from "../Utils/errormsg";
import Header from "./header";
import FooterMini from "./footermini";
import { useCallback } from "react";

import toolsClient from "../../Javascript/Tools";
import AlertDot from "../Utils/alertdot";
import BottomPop from "../Utils/bottompop";
import ProfileMini from "../User/profilemini";
import Subscribe from "../Subscriptions/subscribe";
import Tips from "../Products/tips";
import WelcomeHome from "./welcomehome";
import CreatorAlerts from "./creatoralerts";

function Home() {
  const [tab, setTab] = useState(1);

  const [hint, setHint] = useState(1);
  const [likes, setLikes] = useState();
  const [loading, setLoading] = useState();
  const [feedData, setFeedData] = useState();
  const [refreshData, setRefreshData] = useState();
  const [fadeIn, setFadeIn] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [lastId, setLastId] = useState();
  const [endOfFeed, setEndOfFeed] = useState(false);
  const [snapped, setSnapped] = useState();
  const [feedWaiting, setFeedWaiting] = useState();
  const [globalMute, setGlobalMute] = useState(true);
  const [newPostsAvailable, setNewPostsAvailable] = useState();
  const [discoverRefresh, setDiscoverRefresh] = useState();
  const [filterId, setFilterId] = useState();
  /* const [weScroll, setWeScroll] = useState(); */
  const [showCreator, setShowCreator] = useState();
  const [showSubscribe, setShowSubscribe] = useState();
  const [showTip, setShowTip] = useState();
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [feedTabText, setFeedTabText] = useState("My Feed");
  const [pageNow, setPageNow] = useState();
  const [showWelcome, setShowWelcome] = useState();
  const [tourChecked, setTourChecked] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [fingerprintChecked, setFingerprintChecked] = useState();
  const [tourDone, setTourDone] = useState();
  const [creatorAlert, setCreatorAlert] = useState();
  const [testId, setTestId] = useState(); 
  const homeRef = useRef(null);

  /* useEffect(() => {
    const getSubscriptions = async () => {
      let model = {};
      const response = await baseClient.APIPost({
        model: model,
        api: "usersubscriptionstatus",
      });
      console.log(response);
    };
    getSubscriptions();
  }, []); */

  const changeFeedTabText = (data) => {
    try {
      let name = `${data[0].author.first_name} ${data[0].author.last_name}`;
      name = name.substring(0, 6) + "~";
      setFeedTabText(name);
    } catch {
      // do nothing
    }
  };

  const getMyFeed = useCallback(
    async (refresh = false, newData = [], likes = false) => {
      try {
        setFeedWaiting(true);
        setPageNow(null);
        let model = {
          likes: likes,
          page_size: 50,
          last_id: refresh ? null : lastId,
          id: filterId !== "none" && !likes ? filterId : null,
        };

        const myFeed = await baseClient.APIPost({
          model: model,
          api: "myfeed",
        });
        setTestId(myFeed.testId);
        if (myFeed?.appRefreshNeeded === 200) {
          alert("There's a newer version of Axcess.\nThe app will refresh.");
          try {
            // eslint-disable-next-line no-undef
            window.gonative.webview.clearCache();
          } catch (err) {
            console.error(err.message);
          }
          document.location = "/";
        }

        setNewPostsAvailable(myFeed?.feedRefreshNeeded || false);

        const myLikes = myFeed?.likes;
        let feedCopy = myFeed?.data;

        if (myLikes) {
          for (let i = 0; i < feedCopy.length; i++) {
            if (myLikes.indexOf(feedCopy[i]._id) > -1) {
              feedCopy[i].liked = true;
            } else {
              feedCopy[i].liked = false;
            }
          }
        }
        if (lastId && newData.length > 0) {
          // Paging. just append data to existing feed data
          for (let i = 0; i < feedCopy.length; i++) {
            newData.push(feedCopy[i]);
          }
          setFeedData(newData);
          if (filterId && filterId !== "none") {
            changeFeedTabText(newData);
          } else {
            setFeedTabText("My Feed");
          }
        } else {
          // reset feed data entirely
          setFeedData(feedCopy);
          if (filterId && filterId !== "none") {
            changeFeedTabText(feedCopy);
          } else {
            setFeedTabText("My Feed");
          }
        }
        if (feedCopy?.length > 0) {
          setLastId(feedCopy[feedCopy.length - 1]._id);
          setEndOfFeed(false);
        } else {
          setEndOfFeed(true);
        }
        setRefreshData(null);
        setFeedWaiting(null);
        setTimeout(() => {
          setLoading(false);
          setFadeIn("fade-in");
        }, toolsClient.LOADING_DELAY);
      } catch (err) {
        console.log(err.message);
        baseClient.serverLogger(`Home Feed Error: ${err.message}`);
      }
    },
    [lastId, filterId]
  );

  useEffect(() => {
    /* CREATOR ALERTS */
    const getCreatorAlerts = async () => {
      const model = {
        publishStatus: currentUser.publish_status,
      };
      const result = await baseClient.APIPost({
        model: model,
        api: "creatoralerts",
      });
      if (result && result.code === 200 && result.alertsNeeded?.length > 0) {
        setCreatorAlert(result.alertsNeeded);
      }
    };

    const date = new Date();
    date.setDate(date.getDate() - 1); // one day ago
    if (
      fingerprintChecked &&
      currentUser.app_role === "creator" &&
      new Date(currentUser.createdAt) < date
    ) {
      getCreatorAlerts();
    }
  }, [fingerprintChecked, currentUser]);

  useEffect(() => {
    // See if first time. Launch tour.
    let tourTimer;
    const checkTour = async () => {     
      const user = await JSON.parse(localStorage.getItem("user"));
      setCurrentUser(user);

      const model = {};
      const result = await baseClient.APIPost({
        model: model,
        api: "checktour",
      });
      setTourChecked(true);
      if (result.loadTour) {
        tourTimer = setTimeout(() => {
          setShowWelcome(user);
        }, 500);
      } else {
        setTourDone(true);
      }
    };
    if (!tourChecked && !loading) {
      checkTour();
    }
    return () => {
      clearTimeout(tourTimer);
    };
  }, [tourChecked, loading]);

  useEffect(() => {
    if (filterId) {
      setRefreshData(true);
    }
  }, [filterId]);

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    let creator_id = searchParams.get("cid");
    if (creator_id) {
      setFilterId(creator_id);
    }
  }, []);

  useEffect(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const creator_id = searchParams.get("cid");
      if ((!lastId && !creator_id) || refreshData) {
        setFeedData(null);
        setFadeIn(null);
        setLoading(true);

        //if (!feedData || likes) {
        getMyFeed(true, []);
        //}
      }
    } catch (err) {
      setLoading(false);
      setErrorMsg({
        message: "Something went wrong fetuch your feed.",
        title: "Oops",
      });
      console.error(`Something went wrong fetching feed: ${err.message}`);
    }
  }, [getMyFeed, refreshData, lastId]);

  /* useEffect(() => {
    // paging on scroll

    let refHome = homeRef.current;
    let boolLikes = false;
    if (tab === 3) {
      boolLikes = true;
    }

    if (refHome) {
      let isScrolling;

      refHome.onscroll = () => {
        //setGlobalMute(true);
        // Clear our timeout throughout the scroll
        clearTimeout(isScrolling);
        setSnapped(null);
        setWeScroll(true);
        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(function () {
         //this triggers a timer on <feedItem> to 
          //see if user stays on post long enough to register a view in DB 
          setSnapped(true);
          setWeScroll(null);
        }, 500);

        const { scrollTop, scrollHeight, clientHeight } = refHome;

        if (!endOfFeed && scrollTop + clientHeight === scrollHeight) {
          // This will be triggered after hitting the last element.
          if (!feedWaiting) {
            setGlobalMute(true); // because videos won't load on devices if sound is on
            getMyFeed(false, feedData, boolLikes);
          }
        }
      };
    }
    return () => {
      refHome.onscroll = null;
      clearTimeout("isScrolling");
    };
  }, [getMyFeed, endOfFeed, feedData, tab, feedWaiting]); */

  useEffect(() => {
    if (pageNow && !endOfFeed && !feedWaiting) {
      let boolLikes = false;
      if (tab === 3) {
        boolLikes = true;
      }
      getMyFeed(false, feedData, boolLikes);
    }
  }, [pageNow, feedWaiting, feedData, getMyFeed, tab, endOfFeed]);

  useEffect(() => {
    // see if open from invite (poor man's deferred deep link from invite)
    let fingerprint = "";
    const testFingerprint = async () => {
      try {
        const model = {
          fingerprint: fingerprint,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "fingerprint",
        });
        if (result && result.code === 200) {
          const data = result.data;
          const creator = result.creator;
          if (data && creator) {
            // we may have a potential invite to subscribe to
            setTimeout(() => {
              setShowCreator(creator);
            }, 1750);
          } else {
            setFingerprintChecked(true);
          }
        } else {
          setFingerprintChecked(true);
        }
      } catch (err) {
        console.log(err.message);
        setFingerprintChecked(true);
      }
    };
    const getIp = async () => {
      try {
        const model = {};
        const ip = await baseClient.APIPost({
          model: model,
          api: "reqip",
        });
        fingerprint = ip.ip;
        await testFingerprint();
      } catch {
        //do nothing
        setFingerprintChecked(true);
      }
    };
    if (tourDone && !loading) {
      getIp();
    }
  }, [tourDone, loading]);

  /*   useEffect(() => {
    if(fingerprintChecked) {
      alert('check everything else')
    }
  }, [fingerprintChecked])
 */
  return (
    <div>
      {/* <div className={`top-mask`}></div> */}
      <Header
        setGlobalMute={(val) => {
          setGlobalMute(val);
        }}
        refreshData={refreshData}
      />
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {/* CREATOR ALERTS */}
      {creatorAlert ? (
        <CreatorAlerts
          fnClose={() => {
            setCreatorAlert(null);
          }}
          user={currentUser}
          alert={creatorAlert}
          setRefreshData={(val) => {            
            setRefreshData(val);
          }}
        />
      ) : null}
      {/* WELCOME SCREEN */}
      {showWelcome ? (
        <WelcomeHome
          user={showWelcome}
          fnClose={() => {
            setShowWelcome(null);
            setTourDone(true);
          }}
          fnTour={() => {
            setShowWelcome(null);
            setHint(0);
          }}
        />
      ) : null}
      {/* TIPPING */}
      {showTip ? (
        <Tips
          creator={showTip}
          fnClose={() => {
            setShowTip(null);
            setFingerprintChecked(true);
          }}
        />
      ) : null}
      {/* SUBSCRIBE */}
      {showSubscribe ? (
        <BottomPop
          title={`Unlock Content`}
          fnClose={() => {
            setShowSubscribe(null);
            setFingerprintChecked(true);
          }}
          /* height={'100vh'} */
          fnLoaded={true}
          height={`100%`}
          /* iconClass={`fa-solid fa-rss`} */
        >
          <Subscribe
            fnClose={() => {
              setShowSubscribe(null);
              setFingerprintChecked(true);
            }}
            creator={showSubscribe}
          />
        </BottomPop>
      ) : null}
      {/* RECOMMENDATION POP */}
      {showCreator ? (
        <BottomPop
          fnClose={() => {
            setShowCreator(null);
            setFingerprintChecked(true);
          }}
          fnLoaded={avatarLoaded}
          title={`Recommendation`}
        >
          <div>
            <ProfileMini
              creator={showCreator}
              fnShowTip={() => {
                setShowTip(showCreator);
                setShowCreator(null);
              }}
              fnSubscribe={() => {
                setShowSubscribe(showCreator);
                setShowCreator(null);
              }}
              fnLoaded={(val) => {
                setAvatarLoaded(val);
              }}
            ></ProfileMini>
          </div>
        </BottomPop>
      ) : null}
      {/* HINT */}
      {hint === 0 ? (
        <HintPop
          id={0}
          fnClose={() => {
            setTourDone(true);
            setHint(null);
          }}
          user={currentUser}
        >
          <div>This is my helper text. I hope it makes you smarter.</div>
        </HintPop>
      ) : null}
      {/* <Header /> */}
      <div ref={homeRef} id="homeRef" className="home-feed fade fade-in">
        <div className="tabs">
          <div className={`tab ${tab === 1 ? "active-tab" : ""}`}>
            <button
              onClick={() => {
                setFadeIn("");
                setLastId(null);
                setLikes(null);
                setFeedData([]);
                setRefreshData(true);
                setGlobalMute(true);
                setFilterId(null);
                setFeedTabText("My Feed");
                window.history.replaceState({}, document.title, "/");
                setTab(1);
              }}
              className="anchor-button relative"
            >
              {feedTabText}
              {newPostsAvailable ? (
                <AlertDot
                  top={"-3px"}
                  right={"unset"}
                  left={"0px"}
                  color={`gold`}
                />
              ) : null}
              {filterId && filterId !== "none" ? (
                <i className={`text-shadow fa-solid fa-filter top-filter`} />
              ) : null}
            </button>
          </div>
          <div className={`tab ${tab === 2 ? "active-tab" : ""}`}>
            <button
              onClick={async () => {
                setLikes(null);
                setFeedData([]);
                setGlobalMute(true);
                setFilterId(null);
                setFeedTabText("My Feed");
                window.history.replaceState({}, document.title, "/");
                setTab(2);
                // test if new version of app
                await baseClient.isAppRefreshNeeded();
              }}
              className="anchor-button relative"
            >
              Discover
              {discoverRefresh ? (
                <AlertDot
                  top={"-3px"}
                  right={"unset"}
                  left={"0px"}
                  color={`gold`}
                />
              ) : null}
            </button>
          </div>
          <div className={`tab ${tab === 3 ? "active-tab" : ""}`}>
            <button
              onClick={async () => {
                setFilterId(null);
                setFadeIn("");
                setLikes(true);
                setFeedData([]);
                setFeedTabText("My Feed");
                setGlobalMute(true);
                window.history.replaceState({}, document.title, "/");
                getMyFeed(true, [], true);
                setTab(3);
                // test if new version of app
                await baseClient.isAppRefreshNeeded();
              }}
              className="anchor-button"
            >
              Likes
            </button>
          </div>
        </div>

        <div className="feed-list">
          {tab === 1 || tab === 3 ? (
            <div className={`fade ${fadeIn}`}>
              <Feed
                posts={feedData}
                likes={likes}
                snapped={snapped}
                setPageNow={(val) => {
                  setPageNow(val);
                }}
                setSnapped={(val) => {
                  setSnapped(val);
                }}
                refreshData={(val) => {
                  setRefreshData(val);
                }}
                globalMute={globalMute}
                setGlobalMute={(val) => {
                  setGlobalMute(val);
                }}
                loading={loading}
                filterId={filterId}
                setFilterId={(val) => {
                  setFilterId(val);
                  window.history.replaceState({}, document.title, "/");
                }}
                /* isScrolling={weScroll} */
              />
            </div>
          ) : (
            <Discover
              setDisoverRefresh={(val) => {
                setDiscoverRefresh(val);
              }}
            />
          )}
        </div>
      </div>
      {feedWaiting ? (
        <div className={`loading-more fade fade-in`}>
          <i className={`fa fa-spinner fa-spin m-right-05`} />
          {endOfFeed ? "end of feed" : "loading more"}
        </div>
      ) : null}
      <FooterMini
        onRefresh={() => {
          setRefreshData(true);
        }}
        setGlobalMute={(val) => {
          setGlobalMute(val);
        }}
        testId={testId}
      />
    </div>
  );
}
export default Home;
