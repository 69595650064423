import moment from "moment";
import { Line, ResponsiveContainer, LineChart } from "recharts";
import { useCallback, useEffect, useState } from "react";
import baseClient from "../../api/Base.js";

function ContentViews({ dateFilter, dateFilterText, creatorId }) {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState();
  const [sortBy, setSortBy] = useState();
  const [sortAsc, setSortAsc] = useState();
  const [masterData, setMasterData] = useState();
  const [fadeIn, setFadeIn] = useState("");

  const getMyPosts = useCallback(async () => {
    const model = {
      sort: sortBy || "createdAt",
      sort_asc: sortAsc || false,
      creatorId: creatorId,
      //date_filter: dateFilter.length > 0 ? dateFilter.split(",") : [],
    };
    const results = await baseClient.APIPost({
      model: model,
      api: "myposts",
    });
    setMasterData(results);
    setData(results.data);
    if (results.data.viewCount) {
      results.data
        .map((item) => item.viewCount)
        .reduce((prev, next) => prev + next);
    }
    setFadeIn("fade-in");
  }, [sortAsc, sortBy, creatorId]);

  useEffect(() => {
    try {
      getMyPosts();
    } catch (error) {
      console.error(error.message);
    }
  }, [getMyPosts]);

  useEffect(() => {
    // load chart data
    if (masterData) {
      const tempChartData = [];
      let dateString = "";
      masterData.chartData.forEach((item) => {
        dateString = `${item._id.month}/1/${item._id.year}`;
        tempChartData.push({
          time: moment(dateString).format("MMM"),
          views: item.viewCount,
        });
      });
      setChartData(tempChartData);
    }
  }, [masterData]);

  return (
    <div className={`fade ${fadeIn}`}>
      <div className="p-right-1 p-left-1 ">
        <div style={{ width: "100%", height: "100px" }}>
          {chartData ? (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={"100%"}
                height={100}
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 5,
                }}
              >
                <Line
                  type="monotone"
                  dot={false}
                  dataKey="views"
                  stroke="#00753d"
                  strokeWidth={2}
                  animationEasing="ease-out"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : null}
        </div>
      </div>
      {/*       <div className={`align-left p-left-05 small-font`}>{`${count} ${
        count === 1 ? "view" : "views"
      } ${dateFilterText}`}</div> */}
      <div className={`views-scroller`}>
        <table className={`content-views`}>
          <thead>
            <tr>
              <th className={`align-center no-wrap`}>
                <button
                  type="button"
                  className="anchor-button small-font wide"
                  onClick={() => {
                    setSortBy("type");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Type
                  {sortBy === "type" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className="anchor-button small-font wide align-left"
                  onClick={() => {
                    setSortBy("headline");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Headline
                  {sortBy === "headline" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
              <th className={`align-center no-wrap`}>
                <button
                  type="button"
                  className="anchor-button small-font wide"
                  onClick={() => {
                    setSortBy("createdAt");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Posted
                  {sortBy === "createdAt" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
              <th className={`align-right no-wrap`}>
                <button
                  type="button"
                  className="anchor-button small-font wide align-right"
                  onClick={() => {
                    setSortBy("viewCount");
                    setSortAsc((prev) => !prev);
                  }}
                >
                  Views
                  {sortBy === "viewCount" ? (
                    <span className={`m-left-025`}>
                      {sortAsc ? (
                        <i className={`fa-solid fa-caret-up small-font`} />
                      ) : (
                        <i className={`fa-solid fa-caret-down small-font`} />
                      )}
                    </span>
                  ) : (
                    <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                  )}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, key) => {
              return (
                <tr key={`v${key}`}>
                  <td className={`align-center`}>
                    <i
                      className={`fa-solid ${
                        item.type === "image" ? "fa-image" : "fa-video"
                      }`}
                    />
                  </td>
                  <td>{item.headline || "No title"}</td>
                  <td className={`align-center no-wrap`}>
                    {moment(item.createdAt).format("MMM-D-YYYY")}
                  </td>
                  <td className={`align-right`}>{item.viewCount || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ContentViews;
