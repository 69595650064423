function AlertDot({ top = 8, right = 13, color = "orange", left='unset' }) {
  let dotColor = "orange";
  switch (color) {
    case "orange":
      dotColor = "var(--brand-orange)";
      break;
    case "red":
      dotColor = "var(--brand-red)";
      break;
      case "pink":
      dotColor = "var(--brand-pink)";
      break;
    case "green":
      dotColor = "var(--counter-color)";
      break;
    case "lightGreen":
      dotColor = "var(--brand-green)";
      break;
    case "gold":
      dotColor = "var(--brand-gold)";
      break;
    case "purple":
      dotColor = "var(--brand-purple)";
      break;
    default:
      dotColor = "var(--brand-orange)";
      break;
  }
  const alertStyle = {
    top: top,
    right: right,
    left: left,
    color: dotColor,
    position: "absolute",
    fontSize: ".7rem",
    zIndex: 9,
  };

  return <i className={`fa-solid fa-circle alert-dot`} style={alertStyle} />;
}
export default AlertDot;
