import React, { useState } from "react";
//import Plyr from "plyr";
import useUpload from "../../hooks/useUpload";
import baseClient from "../../api/Base";

const EditFeed = ({ feed, setShowEditForm, setShowConfirmZone }) => {
  const { content, headline, type, description, _id } = feed;
  //const videoTag = useRef();

  const upload = useUpload(
    type === "video"
  ); /** checking post type is video for getting video thumbnail */
  const [data] = useState({
    content,
    headline,
    type,
    description,
    author: JSON.parse(localStorage.getItem("user"))._id,
  });
  //const [feedUrl, setFeedUrl] = useState(contentUrl);
  //const [videoInstance, setVideoInstance] = useState(null);

  const updatePost = async () => {
    try {
      const feed = await baseClient.APIPost({
        model: data,
        api: `updatefeed/${_id}`,
      });
      if (feed.success) {
        setShowEditForm({
          item: {},
          showForm: false,
        });
        setShowConfirmZone(true);
      }
    } catch (error) {
      console.error(error.Message);
    }
  };

  /*  useEffect(() => {
    if (type === "video" && videoTag.current)
      setVideoInstance(
        new Plyr(videoTag.current, {
          controls: false,
          resetOnEnd: true,
          muted: true,
        })
      );
  }, [type, videoTag]); */

  /* useEffect(() => {
    if (upload.uploadUrl !== "") {
      setFeedUrl(upload.uploadUrl);
      setData((prev) => ({ ...prev, content: upload.content }));
    }
  }, [upload.uploadUrl, upload.content, videoInstance]); */

  /*   useEffect(() => {
    videoTag.current?.load();
  }, [feedUrl]); */

  return (
    <div className="add-content">
      <div className="fade fade-in">
        <div className="fade fade-in">
          <div className="chooser-button-bar">
            {/* <button
              type="button"
              className="button-cancel m-05"
              onClick={() => {
                setShowEditForm({
                  item: {},
                  showForm: false,
                });
              }}
            >
              Cancel
            </button> */}
            <button
              type="button"
              className="button-save m-05"
              disabled={upload.loading}
              onClick={updatePost}
            >
              {upload.loading && <i className="fad fa-spinner fa-spin"></i>}
              Save
            </button>
          </div>
          {/* <label role="button" htmlFor="upload" className={`edit-replace`}>
            <i className="fa-light fa-square-pen"></i>
            <input
              type="file"
              id="upload"
              accept={`${type}/*`}
              className="hidden"
              onChange={upload.upload}
            />
          </label>
          <div className={`image-wrapper`}>
            {data.type === "image" ? (
              <img
                src={feedUrl}
                alt="myImage"
                className="add-content-uploaded-image"
              />
            ) : (
              <video
                ref={videoTag}
                id="player"
                className="feed-video edit-video manualvideo"
                muted="muted"
                // src={feedUrl}
                playsInline
                loop={false}
                controls
                preload="metadata"
                autoPlay={false}
                // data-poster={feedUrl}
                style={{
                  "--plyr-color-main": "#ff004e",
                }}
              >
                <source src={feedUrl} type="video/mp4" />
              </video>
            )}
          </div> */}
          <div className="chooser-form">
            <form>
              <label htmlFor="headline">
                <div>
                  <textarea
                    id="headline"
                    name="headline"
                    rows={2}
                    placeholder="Caption (optional)"
                    defaultValue={data.headline}
                    onChange={(e) => {
                      data.headline = e.target.value;
                    }}
                  />
                </div>
              </label>
              <label htmlFor="description">
                <div>
                  <textarea
                    id="description"
                    name="description"
                    rows={4}
                    placeholder="Description (optional)"
                    defaultValue={data.description}
                    onChange={(e) => {
                      data.description = e.target.value;
                    }}
                  />
                </div>
              </label>
            </form>
          </div>
          <div className={`p-left-1 p-right-1 small-font align-left`}>
            NOTE: You can't edit or change the {type}. If you need to 
            change the {type}, you should delete the post and add a 
            new post.
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFeed;
