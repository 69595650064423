import { useEffect } from "react";
import { useRef } from "react";
import "../../Css/Utils/Loading.css";
function Loading({top, className}) {
  const loader = useRef(null);
  useEffect(() => {
    let timer;
    if (loader.current) {
      setTimeout(() => {
        loader.current?.classList.add("grow");
        clearTimeout(timer);
      }, 100);
    }
  }, []);
  return (
    <div>
      {/* <div className={`sheen`}></div> */}
      <div 
        ref={loader} 
        className={`loading ${className ? className : ''}`}
        style={top ? {top: top} : {}}
      ></div>
    </div>
  );
}
export default Loading;
