import moment from "moment";
import { useEffect, useState } from "react";
import baseClient from "../../api/Base";
import "../../Css/Admin/Reported.css";
import toolsClient from "../../Javascript/Tools";
import ErrorMsg from "../Utils/errormsg";
import SupportForm from "../Help/supportticket";
import Loading from "../Utils/loading";
import Popover from "../Utils/popover";

function SupportTickets({ fnRefresh }) {
  const [filter, setFilter] = useState();
  const [status, setStatus] = useState("submitted");
  const [editTicket, setEditTicket] = useState();
  const [fadeIn, setFadeIn] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [tickets, setTickets] = useState();
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() => {
    const getTickets = async () => {
      try {
        setLoading(true);
        const model = {
          filter: filter !== "all" ? filter : null,
          status: status !== "all" ? status : null,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "tickets",
        });
        if (!result || !result.code === 200) {
          setLoading(false);
          setErrorMsg({
            message: `Error getting ticket. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        const ticketData = result.data;
        setTickets(ticketData);
        setRefreshData(null);
        if(fnRefresh) {
          fnRefresh(true);
        }
        setTimeout(() => {
          setLoading(false);
          setFadeIn("fade-in");
        }, toolsClient.LOADING_DELAY);
      } catch (err) {
        setErrorMsg({
          message: `Something went wrong getting tickets list. ${err.mesage}`,
          title: "Oops",
        });
        console.error(err.message);
      }
    };
    if (refreshData) {
      getTickets();
    }
  }, [filter, status, refreshData, fnRefresh]);
  return (
    <div>
      {loading ? <Loading /> : null}
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {editTicket ? (
        <Popover
          title="Tickets"
          className={`support-slider`}
          fnClose={() => {
            setRefreshData(true);
            setTimeout(() => {
              setEditTicket(null);
            }, 100);
          }}
        >
          <SupportForm
            fnClose={() => {
              setRefreshData(true);
              setTimeout(() => {
                setEditTicket(null);
              }, 100);
            }}
            id={editTicket}
          />
        </Popover>
      ) : null}
      <div className={`p-1 fade ${fadeIn}`}>
        <div className={`p-top-1 flex m-bottom-05`}>
          <button
            type="button"
            className={`anchor-button brand-link-color m-right-025 flex-1 align-left`}
            onClick={() => {
              setEditTicket("new");
            }}
          >
            New
            <i className={`fa-solid fa-plus-circle m-left-025`} />
          </button>
          <div className={`align-right drop-down m-right-05`}>
            <select
              onChange={(e) => {
                setStatus(e.target.value);
                setRefreshData(true);
              }}
              defaultValue={"submitted"}
            >
              <option value={`all`}>All</option>
              <option value={`closed`}>Closed</option>
              <option value={`submitted`}>Submitted</option>
              <option value={`working`}>Working</option>
            </select>
          </div>
          <div className={`align-right drop-down`}>
            <select
              onChange={(e) => {
                setFilter(e.target.value);
                setRefreshData(true);
              }}
              defaultValue={"all"}
            >
              <option value={`all`}>All</option>
              <option value={`accounting`}>Accounting</option>
              <option value={`bug`}>Bug</option>
              <option value={`feature`}>Feature</option>
              <option value={`other`}>Other</option>
              <option value={`question`}>Question</option>
            </select>
          </div>
        </div>
        <table className={`small-font reported-table`}>
          <thead>
            <tr>
              <th>User</th>
              <th className={`align-center`}>Type</th>
              <th className={`no-wrap align-center`}>
                Date <i className={`fa fa-caret-down`} />
              </th>
            </tr>
          </thead>
          <tbody>
            {tickets?.map((item, index) => {
              return (
                <tr
                  key={`tickets${index}`}
                  className={`p-05 b-bottom`}
                  value={item._id}
                >
                  <td>
                    <button
                      type="button"
                      className={`anchor-button brand-link-color align-left small-font`}
                      onClick={() => {
                        setEditTicket(item._id);
                      }}
                    >
                      {`${item.requestBy.first_name} ${item.requestBy.last_name}`}
                      <div>{item.requestBy.app_role}</div>
                    </button>
                  </td>
                  <td>
                    {item.type}
                    <div
                      className={`${
                        item.status === "submitted"
                          ? "brand-orange"
                          : item.status === "working"
                          ? "brand-gold"
                          : item.status === "closed"
                          ? "brand-dark-green"
                          : ""
                      }`}
                    >
                      {item.status}{" "}
                    </div>
                  </td>
                  <td className={`no-wrap`}>
                    {moment(item.createdAt).format("MMM-Do-YY")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default SupportTickets;
