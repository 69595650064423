import moment from "moment";
import { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import baseClient from "../../api/Base";
import "../../Css/ChannelActivity/Subscribers.css";
import Profile from "../User/profile";
import DialogPop from "../Utils/dialogpop";
import ErrorMsg from "../Utils/errormsg";
import Searchbar from "../Utils/searchbar";
import Slideover from "../Utils/slideover";

function Subscribers({ totalSubscribers, creatorId }) {
  const [showBlockUser, setShowBlockUser] = useState();
  const [subscribers, setSubscribers] = useState([]);
  const [fadeIn, setFadeIn] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortAsc, setSortAsc] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [profile, setProfile] = useState();
  const [endOfFeed, setEndOfFeed] = useState(false);
  const [skip, setSkip] = useState();
  const [filter, setFilter] = useState();

  const wrapper = useRef(null);

  const toggleSubscription = (id) => {
    let tempArray = [];
    for (let i = 0; i < subscribers.length; i++) {
      tempArray.push(subscribers[i]);
      if (id === subscribers[i]._id) {
        tempArray[i].blocked = 1;
      }
    }
    setSubscribers(tempArray);
  };

  const getMySubscribers = useCallback(
    async (newData = [], skipNbr = 0) => {
      try {
        const model = {
          sort: sortBy || "createdAt",
          sort_asc: sortAsc || false,
          filter: filter,
          limit: 50,
          skip: skipNbr,
          creatorId: creatorId,

        };
        const results = await baseClient.APIPost({
          model: model,
          api: "mysubscribers",
        });
        let resultsCopy = results?.data;

        if (newData.length > 0) {
          // Paging. just append data to existing feed data
          for (let i = 0; i < resultsCopy.length; i++) {
            newData.push(resultsCopy[i]);
          }
          setSubscribers(newData);
        } else {
          setSubscribers(resultsCopy);
        }
        if (resultsCopy?.length > 0) {
          // for paging.
          setSkip(skipNbr + results.data.length);
          setEndOfFeed(false);
        } else {
          setEndOfFeed(true);
        }

        setFadeIn("fade-in");
      } catch (err) {
        console.error(err.message);
        setErrorMsg({
          title: "Oh no.",
          message: "Something went wrong fetching Subscribers.",
        });
      }
    },
    [sortBy, sortAsc, filter, creatorId]
  );

  useEffect(() => {
    getMySubscribers();
  }, [getMySubscribers]);

  useEffect(() => {
    // paging

    let wrapRef = wrapper.current;
    if (wrapRef) {
      wrapRef.onscroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = wrapRef;
        //console.log(`${scrollTop}, ${scrollHeight}, ${clientHeight}`);
        if (!endOfFeed && scrollTop + clientHeight === scrollHeight) {
          // This will be triggered after hitting the last element.
          console.log("paging");
          let skipNbr = skip || 0;
          getMySubscribers(subscribers, skipNbr);
        }
      };
    }
    return () => {
      wrapRef.onscroll = null;
    };
  }, [wrapper, endOfFeed, getMySubscribers, subscribers, skip]);

  return (
    <div>
      {errorMsg ? (
        <ErrorMsg
          title={errorMsg.title}
          message={errorMsg.message}
          position={`fixed`}
          fnClose={() => {
            setErrorMsg(null);
          }}
        />
      ) : null}
      {/* SUBSCRIBERS PROFILE POP */}
      {profile ? (
        <Slideover
          fnClose={() => setProfile(null)}
          title={`${profile.first_name} ${profile.last_name}`}
          scrollSnap={true}
          /* iconClass={"fa-light fa-user"} */
        >
          <Profile creatorId={profile.subscriber_id} allowAdmin={false} />
        </Slideover>
      ) : null}
      {showBlockUser ? (
        <DialogPop title={"Block User"} fnClose={() => setShowBlockUser(null)}>
          <div>Are you sure you want to block user from your channel?</div>
          <div className="m-top-1">
            <button
              type="button"
              className={`button button-cancel m-05`}
              onClick={() => {
                setShowBlockUser(null);
              }}
            >
              No
            </button>
            <button
              type="button"
              className={`button button-save m-05`}
              onClick={() => {
                toggleSubscription(showBlockUser);
                setShowBlockUser(null);
              }}
            >
              Yes
            </button>
          </div>
        </DialogPop>
      ) : null}
      <div className={`fade ${fadeIn}`}>
        <div className={`total-summary`}>
          Total Subscribers: {totalSubscribers}
        </div>

        <div className={`search-outer`}>
          <Searchbar
            fnSearch={(filter) => {
              setFilter(filter);
            }}
          />
        </div>

        <div ref={wrapper} className={`views-scroller`}>
          <table className={`content-views`}>
            <thead>
              <tr>
                <th className={`align-left no-wrap`}>
                  <button
                    type="button"
                    className="anchor-button small-font wide align-left"
                    onClick={() => {
                      setSortBy("name");
                      setSortAsc((prev) => !prev);
                    }}
                  >
                    Name
                    {sortBy === "name" ? (
                      <span className={`m-left-025`}>
                        {sortAsc ? (
                          <i className={`fa-solid fa-caret-up small-font`} />
                        ) : (
                          <i className={`fa-solid fa-caret-down small-font`} />
                        )}
                      </span>
                    ) : (
                      <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                    )}
                  </button>
                </th>
                <th className={`align-center no-wrap`}>
                  <button
                    type="button"
                    className="anchor-button small-font wide"
                    onClick={() => {
                      setSortBy("createdAt");
                      setSortAsc((prev) => !prev);
                    }}
                  >
                    Subscribed At
                    {sortBy === "createdAt" ? (
                      <span className={`m-left-025`}>
                        {sortAsc ? (
                          <i className={`fa-solid fa-caret-up small-font`} />
                        ) : (
                          <i className={`fa-solid fa-caret-down small-font`} />
                        )}
                      </span>
                    ) : (
                      <span className={`m-left-025`}>
                      <i className={`fa-light fa-sort`} />
                    </span>
                    )}
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              {subscribers?.map((item, key) => {
                return (
                  <tr key={`v${key}`}>
                    <td className={`align-left`}>
                      {item.last_name}, {item.first_name}
                    </td>
                    <td className={`align-center no-wrap`}>
                      {moment(item.subscribedAt).format("MMM-D-YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default Subscribers;
