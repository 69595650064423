import "../../Css/Home/Header.css";
import MainMenu from "./mainmenu";
import logo from "../../Graphics/AxcessIconNew.png";
import { useEffect, useState } from "react";
import DialogPop from "../Utils/dialogpop";
import Searchmain from "./searchmain";
import AlertDot from "../Utils/alertdot";

function Header({ setGlobalMute, refreshData }) {
  const [showMenu, setShowMenu] = useState();
  const [comingSoon, setComingSoon] = useState();
  const [showSearch, setShowSearch] = useState();
  const [showAlert, setShowAlert] = useState();
  const [refreshAlert, setRefreshAlert] = useState(true);

  useEffect(() => {
    if (refreshAlert || refreshData) {
      let tempUser = JSON.parse(localStorage.getItem("user"));     
      if (tempUser) {
        if (tempUser.app_role === "creator") {
          if (tempUser.publish_status === "none") {
            setShowAlert(true);
          } else {
            setShowAlert(null);
          }
        }
      }
      setRefreshAlert(null);
    }
  }, [refreshAlert, refreshData]);

  return (
    <div className="relative">
      <div className="header">
        {comingSoon ? (
          <DialogPop title={"Coming Soon"} fnClose={() => setComingSoon(null)}>
            <div>This feature is coming soon!</div>
            <div className="m-top-1">
              <button
                type="button"
                className={`button button-cancel m-05`}
                onClick={() => {
                  setComingSoon(null);
                }}
              >
                Close
              </button>
            </div>
          </DialogPop>
        ) : null}
        {showSearch ? (
          <Searchmain
            fnClose={() => {
              setShowSearch(null);
            }}
            setGlobalMute={(val) => {
              setGlobalMute(val);
            }}
          />
        ) : null}
        {showMenu ? (
          <MainMenu
            fnClose={() => {
              setShowMenu(null);
              setRefreshAlert(true);
            }}
          />
        ) : null}
        <div className="header-item">
          {/* <div className="counter">2</div> */}
          <button
            type="button"
            className="anchor-button"
            onClick={() => {
              setGlobalMute(true);
              setShowSearch(true);
            }}
          >
            <i className="fa-light fa-search" />
          </button>
        </div>
        <div className="header-item flex-1">
          <button
            type="button"
            className="anchor-button"
            onClick={() => {
              //do nothing
            }}
          >
            <img className="header-logo" src={logo} alt="logo" />
          </button>
        </div>

        {/* <div id="header-menu" className="header-item flex-1">
          <div
            role="button"
            className={`header-search`}
            onClick={() => {
              setGlobalMute(true);
              setShowSearch(true);
            }}
          >
            <i className={`fa fa-search small-font m-right-025`} />
          </div>
        </div>
 */}
        <div id="header-menu" className="header-item ">
          {showAlert ? <AlertDot top={6} right={11} color="orange" /> : null}

          <button
            onClick={async () => {
              setGlobalMute(true);
              setShowMenu(true);
            }}
            className="anchor-button"
          >
            <i className={`fa-light fa-bars`} />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Header;
