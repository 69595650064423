import coin from "../../Graphics/AxcessCoin.png";
import "../../Css/Products/Tips.css";

import { useEffect, useRef, useState } from "react";
import ErrorMsg from "../Utils/errormsg";

import baseClient from "../../api/Base";
import toolsClient from "../../Javascript/Tools";
//import logo from "../../Graphics/logo-red-no-name.png";
import BottomPop from "../Utils/bottompop";

function Tips({ creator, fnClose }) {
  const [products, setProducts] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const [fadeIn, setFadeIn] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [agreed, setAgreed] = useState(false);
  const [reason, setReason] = useState();
  const [waiting, setWaiting] = useState();
  const [serverMode, setServerMode] = useState();
  const [reasons, setReasons] = useState();
  
  const coins = useRef(null);
  const reasonTypes = useRef(null);
  const tipWrapper = useRef(null);
  const tipTarget = useRef(null);
  /* const tipReasons = [
    "Community involvement",
    "Favorite athlete",
    "Great dedication",
    "Hardest worker",
    "Striving for excellence",
    "Style",
  ]; */

  /* const reasons = [
    { text: "Community Involvement", icon: "fa-handshake-angle" },
    { text: "Dedication", icon: "fa-medal" },
    { text: "Favorite Athlete", icon: "fa-heart" },
    { text: "Hard Work", icon: "fa-face-grin-beam-sweat" },
    { text: "Striving for Excellence", icon: "fa-hand-love" },
    { text: "Style", icon: "fa-face-sunglasses" },
  ]; */

  const startPurchase = () => {
    try {
      setWaiting(true);
      const purchasedProduct = selectedItem.appleProductId;
      const product = selectedItem._id;
      const logPurchaseLedger = async () => {
        const model = {
          product: product,
          product_id: purchasedProduct,
          creator: creator._id,
          amount: selectedItem.price.price,
          reasonId: reason,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "logpurchase",
        });
        localStorage.setItem("lastPurchaseId", result.data);
      };
      if (serverMode === "development") {
        // skip in-app purchases
        const savePurchase = async () => {
          logPurchaseLedger();
          console.log(selectedItem);
        };
        savePurchase();
        const transactionId = Math.floor(Math.random() * 90000) + 10000; // 5-digit random nbr;
        setTimeout(() => {
          document.location = `/purchasereceipt?tid=${transactionId}&pid=${purchasedProduct}`;
        }, 2000);
      } else {
        // in-app purchase
        logPurchaseLedger();
        // eslint-disable-next-line no-undef
        window.gonative.purchase({ productID: purchasedProduct });
        // once this call resolves it redirects automatically to subscribereceipt
      }
    } catch (err) {
      setWaiting(null);
      console.log(err.message);
      toolsClient.serverLogger(`purchase error: ${err.message}`);
      alert("You can only subscribe via the App. Not via browser.");
    }
  };

  

  useEffect(() => {
    try {
      const getProducts = async () => {
        let model = {
          type: "tip",
        };
        let result = await baseClient.APIPost({
          model: model,
          api: "approvedproducts",
        });
        if (!result || !result.code === 200) {
          setErrorMsg({
            message: `Error getting tip options. Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        setProducts(result.data);
        setServerMode(result.serverMode);

        model = {
          type: "tip"
        }
        const reasonResult = await baseClient.APIPost({
          model: model,
          api: "activereasons",
        });
        if (!reasonResult || !reasonResult.code === 200) {
          setErrorMsg({
            message: `Error getting tip options (reasons). Code: ${result?.code}`,
            title: "Oops",
          });
          return;
        }
        setReasons(reasonResult.data);

        setTimeout(() => {
          setFadeIn("fade-in");
          setTimeout(() => {
            if (coins.current) {
              const elem1 = coins.current.children[2];
              if (elem1) {
                elem1.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                });
              }
            }
          }, 800);

          setTimeout(() => {
            if (reasonTypes.current) {
              const elem = reasonTypes.current.children[2];
              if (elem) {
                elem.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "center",
                });
              }
            }
          }, 100);
        }, toolsClient.LOADING_DELAY);
      };
      if (coin) {
        getProducts();
      }
    } catch (err) {
      setErrorMsg({
        message: `Something went wrong loading tip options. ${err.message}`,
        title: "Oops",
      });
      console.error(err.message);
    }
  }, []);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      window.gonative.webview.clearCache();
      const getPurchaseData = async () => {
        try {
          await window.gonative.iap.refresh();
          // eslint-disable-next-line no-undef
          await window.gonative.iap.info();
          /* alert(purchaseData.inAppPurchases.platform); */
          //await baseClient.serverLogger(JSON.stringify(purchaseData));
        } catch (err) {
          baseClient.serverLogger(err.message);
          console.log(err.message);
        }
      };
      getPurchaseData();
    } catch (err) {
      baseClient.serverLogger(err.message);
      console.log(err.message);
    }
  }, []);

  return (
    <BottomPop
      title={`Tip ${creator.first_name} ${creator.last_name}`}
      headerClass={`tips-popover-header`}
      fnClose={() => {
        fnClose();
      }}
      fnLoaded={true}
      height={"100%"}
    >
      <div ref={tipWrapper} className={`tips`}>
        {errorMsg ? (
          <ErrorMsg
            title={errorMsg.title}
            message={errorMsg.message}
            position={`fixed`}
            fnClose={() => {
              setErrorMsg(null);
            }}
          />
        ) : null}
        {waiting ? (
          <div className={`tips-processing`}>
            <div className={`larger-font p-1`}>
              <i className={`fa fa-spinner fa-spin`} />
              <span className={`m-left-1`}>Processing...</span>
            </div>
            Waiting on app store confirmation.
            <div className={`p-top-1`}>
              You will be automatically redirected to a success confirmation
              page.
            </div>
            {/* <div className={`p-1 m-top-1`}>
              <button
                type="button"
                className={`button-cancel m-top-1`}
                onClick={() => setWaiting(null)}
              >
                Cancel
              </button>
            </div> */}
          </div>
        ) : (
          <div className={`fade ${fadeIn} tips-wrapper`}>
            <div
              className={`hero-avatar`}
              style={{ backgroundImage: `url(${creator.avatar_url})` }}
            ></div>
            <div className={`coins-wrapper`}>
              <div className={`small-font flex p-right-05 p-left-05 `}>
                <i
                  role="button"
                  className={`fa fa-arrow-left m-right-05`}
                  onClick={() => {
                    const elem = coins.current;
                    toolsClient.sideScroll(elem, "left", 5, 120, 2);
                  }}
                />
                <div className={`flex-1`}>Tap Tip Amount</div>
                <i
                  roll="button"
                  className={`fa fa-arrow-right m-left-05`}
                  onClick={() => {
                    const elem = coins.current;
                    toolsClient.sideScroll(elem, "right", 5, 120, 2);
                  }}
                />
              </div>
              <ul ref={coins} className={`coins`}>
                {products?.map((item, index) => {
                  return (
                    <li
                      key={`prod${index}`}
                      role="button"
                      className={`coin ${
                        selectedItem?._id === item._id ? "selected" : ""
                      }`}
                      style={{ backgroundImage: `url(${coin})` }}
                      onClick={(e) => {
                        const elem = e.target;
                        elem.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        });
                        setSelectedItem(item);
                        setTimeout(() => {
                          tipTarget.current.focus();
                        }, 500);
                      }}
                    >
                      <div className={`flex-1`}>${item.price.price}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <button
                ref={tipTarget}
                className={`anchor-button larger-font m-top-025 m-bottom-05 tip-target`}
              >
                ${selectedItem?.price.price || 0.0}
              </button>
              <div className={`small-font `}>one-time tip to</div>

              <div className={`large-font m-bottom-05`}>
                {creator?.first_name} {creator?.last_name}
              </div>
              <div className={`small-font `}>for</div>
              <div className={`reason-wrapper p-bottom-05`}>
                <div className={`small-font  m-top-05 flex scroll-hint`}>
                  <i
                    role="button"
                    className={`fa fa-arrow-left m-left-05`}
                    onClick={() => {
                      const elem = reasonTypes.current;
                      toolsClient.sideScroll(elem, "left", 5, 120, 2);
                    }}
                  />
                  <div className={`flex-1`}>Tap a Reason (required)</div>
                  <i
                    role="button"
                    className={`fa fa-arrow-right m-right-05`}
                    onClick={() => {
                      const elem = reasonTypes.current;
                      toolsClient.sideScroll(elem, "right", 5, 150, 2);
                    }}
                  />
                </div>
                <ul ref={reasonTypes} className={`reason-types`}>
                  {reasons?.map((item, index) => {
                    return (
                      <li
                        key={`rea${index}`}
                        role="button"
                        className={`reason-type ${
                          reason === item._id ? "selected" : ""
                        }`}
                        onClick={(e) => {
                          const elem = e.target;
                          elem.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                          });
                          console.log(item._id)
                          setReason(item._id);
                        }}
                      >
                        <i className={`fa ${item.icon} m-right-05`} />
                        {item.reason}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* <div className={`reasons`}>
              <select
                className={`align-center`}
                placeholder="Choose a Reason"
                onChange={(e) => {
                  const val = e.target.value;
                  if (val.length > 0) {
                    setReason(val);
                  } else {
                    setReason(null);
                  }
                }}
              >
                <option key={`rz1`} value="">
                  Choose a Reason (required)
                </option>
                {tipReasons.map((reason, index) => {
                  return (
                    <option key={`rea${index}`} value={reason}>
                      {reason}
                    </option>
                  );
                })}
              </select>
            </div> */}
              <div className={`small-font p-left-1 p-right-1 p-bottom-05 `}>
                This tip is not a reward for performance, nor an attempt to
                influence recruiting.
              </div>
              <label htmlFor="confirm" className={`confirm-box`}>
                <input
                  id="confirm"
                  name="confirm"
                  type="checkbox"
                  className={`m-right-05`}
                  onChange={() => {
                    setAgreed((prev) => !prev);
                  }}
                />
                <div className={`flex-1`}>I acknowlege</div>
              </label>
              <div className={`m-bottom-05 m-top-05`}>
                {/*  <button
                  type="button"
                  className="button-cancel m-right-1"
                  onClick={() => {
                    fnClose();
                  }}
                >
                  Cancel
                </button> */}
                <button
                  type="button"
                  className={`button-save`}
                  disabled={!agreed || !selectedItem || !reason}
                  onClick={() => {
                    if (agreed && selectedItem && reason) {
                      startPurchase();
                    }
                  }}
                >
                  Send Tip
                </button>
              </div>
              <div className={`policy  small-font`}>
                By tapping Send Tip you are agreeing to the{" "}
                <span className={`no-wrap`}>
                  <a
                    href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                    rel="noreferrer"
                    target="_blank"
                    className={`underline small-font brand-pink no-wrap`}
                  >
                    Terms of Use
                  </a>
                  {` and `}
                  <a
                    href="https://axcessnetwork.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                    className={`underline small-font brand-pink no-wrap`}
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </BottomPop>
  );
}
export default Tips;
