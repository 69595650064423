import { useRef, useState } from "react";
import "../../Css/Utils/Searchbar.css";

function Searchbar({fnSearch}) {
  const [showClear, setShowClear] = useState();

  let timer;

  const search = useRef(null);

  return (
    <div className={`search-bar`}>
      <div className={`search-wrapper`}>
        <div className={`searchbar`}>
          <div className="p-right-05">
            <i className="fas fa-search icon" />
          </div>
          <div className="flex-1">
            <input
              ref={search}
              type="text"
              onKeyUp={(e) => {
                setShowClear(true);
                if (e.target.value.length > 0) {
                  if (timer) {
                    clearTimeout(timer);
                  }
                  timer = setTimeout(() => {
                    fnSearch(e.target.value.trim());
                  }, 500);
                } else {
                  fnSearch(null);
                }
              }}
              placeholder={`Search`}              
            />
          </div>
          <button
            type="button"
            className={`${
              showClear ? "" : "hidden-opacity"
            } anchor-button search-close`}
            onClick={() => {
              search.current.value = "";
              setShowClear(null);
              fnSearch(null);
            }}
          >
            <i className="fa-light fa-refresh" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Searchbar;
