import { useState } from "react";
import baseClient from "../../api/Base";

function DeleteUser({ user, fnClose }) {
  const [testText, setTestText] = useState();
  const [confirmZone, showConfirmZone] = useState();
  const deleteMe = async () => {
    try {
      if (
        testText &&
        testText.toLowerCase() === user.first_name.toLowerCase()
      ) {
        const model = {
          userId: user._id,
        };
        const result = await baseClient.APIPost({
          model: model,
          api: "deletemyprofile",
        });
        if (result && result.data?.modifiedCount === 1) {
          // succesfully deleted
          // document.location.replace("/welcome")
          showConfirmZone(true);
        }
      }
    } catch (error) {
      baseClient.serverLogger(`error deleting user: ${error.message}`)
      console.error(error.message);
    }
  };

  return (
    <div className={`p-1 `}>
      {confirmZone ? (
        <div className={`fade fade-in `}>
          <div className={`larger-font brand-green`}>
          You're all set! 
          </div>
          <div className={`large-font p-1`}>
            Your data has been deleted.
          </div>
          <div className={`p-1`}>
            <button
              onClick={() => {
                document.location.replace("/welcome");
              }}
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h1 className={`brand-red bold`}>Danger Zone!</h1>
          <div className={`large-font p-1`}>Are you sure?</div>
          <div className={`p-1 bg-gainsboro round-10`}>
            <div className={``}>Hi {user.first_name}.</div>
            <div className={`p-top-1 `}>
              You are about to delete your account. Your user record and all
              associated data will be permanently and irrevocably removed from
              our databases and servers.
            </div>
          </div>

          <div className={`large-font p-1 m-top-1`}>
            This action CAN NOT be undone!
          </div>
          <div>
            <label htmlFor="verify">
              Please type your First Name to verify.
              <input
                id="verify"
                className={`wide m-top-025 p-05 b-round-5 border-box align-center `}
                type="text"
                placeholder="firstname"
                onChange={(e) => {
                  setTestText(e.target.value);
                }}
              />
            </label>
          </div>
          <div className={`m-top-1 p-top-1`}>
            <button
              className={`button-cancel m-025`}
              onClick={() => {
                if (fnClose) {
                  fnClose();
                }
              }}
            >
              Cancel
            </button>
            <button
              className={`bg-brand-red m-025`}
              disabled={
                testText?.toLowerCase() !== user.first_name.toLowerCase()
              }
              onClick={() => {
                deleteMe();
              }}
            >
              Delete Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default DeleteUser;
